<template>
    <div class="content-page">
      <div class="flex flex-column">
        <div class="flex flex-row align-self-center search-bar">
          <div class="flex align-items-center justify-content-center search-bar-icon">
            <img :src="require('@/assets/icons/search.png')" alt="" />
          </div>
          <div class="flex  align-items-center search-bar-input">
            <input class="custom-input" type="text" :placeholder="$t('match_list.search_match')" @input="onInput" v-model="searchString"/>
          </div>
          <div class="flex align-items-center justify-content-center search-bar-icon">
            <img :src="require('@/assets/icons/settings-sliders.png')" alt="" />
          </div>
        </div>
        <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
          <div class="flex align-self-center align-items-center justify-content-center sub-menu">
            <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item" 
            :class="{'sub-page-active':checkActive('live')}" @click="setActiveSubPage('live')">
              {{ $t("match_list.sub_menu.live") }}
            </div>
            <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item" 
            :class="{'sub-page-active':checkActive('upcoming')}" @click="setActiveSubPage('upcoming')">
              {{ $t("match_list.sub_menu.upcoming") }}
            </div>
            <div class="flex align-items-center justify-content-center text sub-menu-item" 
            :class="{'sub-page-active':checkActive('past')}" @click="setActiveSubPage('past')">
              {{ $t("match_list.sub_menu.past") }}
            </div>
          </div>
      </div>
      <div class="scroll-div">
        <div v-for="(m,index) in this.matches" :key="m.id+index" class="flex flex-column align-items-center justify-content-center">
          <div class="flex align-self-center matches-card" @click="goToMatch(m)">
              <div class="flex align-items-center justify-content-center matches-card-icon">
                <div class="flex flex-column align-items-center justify-content-center"> 
                  <img :src="m.homeTeam.flag"  alt=""/>
                  <span class="team-name"> {{ m.homeTeam['name_'+language] }} </span>
                  <span v-if="m.homeGoals">
                    <div v-for="gh in m.homeGoals" :key="gh.player.id+gh.time.elapsed" class="goals"> 
                        {{ gh.player.name +' '+ gh.time.elapsed }}'
                    </div>
                  </span>
                </div>
              </div>
              <div class="flex flex-column justify-content-center align-items-start matches-card-name">
                <div class="flex flex-column justify-content-center around-score">
                  <div class="score-text">{{ (m.homeScore || 0) + '-' + (m.awayScore || 0) }}</div>
                  <div class="data-text">{{ m.matchStart | dateMonthDay }}</div>
                </div>
              </div>
              <div class="flex align-items-center justify-content-center matches-card-icon">
                <div class="flex flex-column align-items-center justify-content-center"> 
                  <img :src="m.awayTeam.flag"  alt=""/>
                  <span class="team-name">  {{ m.awayTeam['name_'+language] }} </span>
                  <span v-if="m.awayGoals">
                    <div v-for="ga in m.awayGoals" :key="ga.player.id+ga.time.elapsed" class="goals"> 
                        {{ ga.player.name +' '+ ga.time.elapsed }}'
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="m.bets.length > 0" class="flex flex-column align-self-center justify-content-start joined-match-card">
              <span class="joined-match-text">
                <img :src="require('@/assets/icons/checkJoinedMatch.png')" alt="" />
                {{ $t("match_list.joined_match") }}
              </span>
              <div class="total-points-text"> 
                {{ $t("match_list.total_points") }} 
                <span> {{ m.bets.totalPoints }} </span>
              </div>
              <div class="grid" style="margin: 5px;">
                  <div v-for="(b, bi) in m.bets" :key="b.id" class="flex flex-row w-6" :class="{'justify-content-end':bi%2!=0,'justify-content-start':bi%2==0}"> 
                    <div class="flex align-items-center p-1" >
                      <img :src="require('@/assets/icons/'+b.type+'.png')" alt="" width="14px" height="14px" />
                      {{ }}
                    </div>
                    <div v-if="b.answer != 'N'" class="flex align-items-center answer-bet-text" >
                      {{ (b.answer == 'H' ?  (' : '+ m.homeTeam["name_" + language]): (' : ' + m.awayTeam["name_" + language]))  }}
                    </div>
                    <div v-else class="flex align-items-center answer-bet-text">
                      {{ ' : ' + $t("match_list.both") }} 
                    </div>
                    <div v-if="subPageActive == 'past' && b.betWin != null" class="flex align-items-center" ><img :src="require('@/assets/icons/check'+b.betWin+'.png')" alt="" width="21" /></div>
                  </div>
              </div>
            </div>
          </div>
          <div id="scroll-sentinel"></div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import  utils from "../utils";
import service from "../service";

export default {
  name: "MatchListComponent",
  data() {
    return {
      matches: [],
      matchesNotFiltered: [],
      liveMatches: [],
      lastMatches: [],
      scheduledesMatches: [],
      homeGoals: '',
      awayGoals: '',
      searchString: '',
      subPageActive: 'upcoming',
      page: 1,
      pageSize: 10
    }
  },
  methods: {
    setActiveSubPage(page) {
      this.subPageActive = page;
      if(this.subPageActive == 'live') {
        store.layout.headerTitle = this.$t('match_list.title_1');
        this.page = 1
        this.pageSize = 10
        this.liveMatches = []
        this.getLiveMatches()
      }
      else if(this.subPageActive == 'upcoming') {
        clearInterval(this.timerInterval);
        store.layout.headerTitle = this.$t('match_list.title_1');
        this.page = 1
        this.pageSize = 10
        this.scheduledesMatch = []
        this.scheduledesMatchList()
      }
      else if(this.subPageActive == 'past') {
        clearInterval(this.timerInterval);
        store.layout.headerTitle = this.$t('match_list.title_2');
        this.page = 1
        this.pageSize = 10
        this.lastMatches = []
        this.lastMatchesList()
      }      
    },

    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },

    onInput(event){
      let lan = 'name_'+ i18n.locale;
      if(this.searchString != ''){
        this.matches = this.matchesNotFiltered.filter(m => m.homeTeam[lan].toLowerCase().includes(this.searchString) 
          || m.awayTeam[lan].toLowerCase().includes(this.searchString))  
      }
      else {
        this.matches = ''
        this.page = 1
        if(this.subPageActive == 'live')
          this.getLiveMatches()
        else if(this.subPageActive == 'upcoming')
          this.scheduledesMatchList()
        else(this.subPageActive == 'past')
          this.lastMatchesList()
      }
    }, 

    goToMatch(match) {
      let m = {"matchId":match.id, "homeTeamFlag":match.homeTeam.flag, "awayTeamFlag":match.awayTeam.flag};
      utils.methods.goToRouteWithParams('match',m)
    },

    async getLiveMatches(){
      store.ignoreLoading = true
      store.ignoreDefaultError = true
      let response = await service.match.getLiveMatches(this.page, this.pageSize);
      if (response && response.data && response.status == 200) {
        if(response.data.length > 0) {
          if(this.liveMatches.length > 0) {
            this.liveMatches.concat(response.data);
          }
          else
            this.liveMatches = response.data;
        } 
        else
          this.page -= 1;
      }
      this.liveMatches = this.liveMatches.map((m) => {
        return {
          id: m.teams.home.name.trim() + 'captain' +  m.teams.away.name.trim(),
          matchStart: m.date,
          homeTeam: { 
            flag:  m.teams.home.logo,
            ["name_" + i18n.locale]: m.teams.home.name,
          },
          awayTeam: { 
            flag:  m.teams.away.logo,
            ["name_" + i18n.locale]: m.teams.away.name,
          },
          homeScore: m.goals.home,
          awayScore: m.goals.away,
          bets: []
        }
      })
      this.matches = this.liveMatches;
      this.matchesNotFiltered = this.matches
      this.timerInterval = setTimeout(async ()=> {
        this.page = 1
        this.pageSize = 10
        await this.getLiveMatches()
      }, 10000)
    },

    async lastMatchesList() {
      let response = await service.match.getLastMatches(this.page, this.pageSize);
     
      if (response && response.status == 200){
        if(response.data.result.length > 0){
          if(this.lastMatches.length > 0){
            response.data.result.forEach(e => {
              this.lastMatches.push(e);
            });
          }
          else
            this.lastMatches = response.data.result;
        }
        else
          this.page -= 1;
      }

      this.matches = this.lastMatches;
      this.matchesNotFiltered = this.matches

      this.matches.forEach(match => {
        match.homeGoals = match.events.filter(e => e.type.toLowerCase().includes('goal')).filter(g => g.team.name == match.homeTeam['name_'+i18n.locale]);
        match.awayGoals = match.events.filter(e => e.type.toLowerCase().includes('goal')).filter(g => g.team.name == match.awayTeam['name_'+i18n.locale]);
        if(match.bets.length > 0){
          let sumPoints = 0;
          match.bets.forEach(bet => { 
            bet.pointsHistory.forEach(ph => { 
              sumPoints += ph.points
            });
          });
          match.bets.totalPoints = sumPoints;
        }
      });
    },

    async scheduledesMatchList() {
      let response = await service.match.getScheduleMatches(this.page, this.pageSize);
      if (response && response.status == 200){
        if(response.data.result.length > 0) {
          if(this.scheduledesMatches.length > 0)
            this.scheduledesMatches.concat(response.data.result);
          else
            this.scheduledesMatches = response.data.result;
        }
        else
          this.page -= 1;
      }
      this.matches = this.scheduledesMatches;
      this.matchesNotFiltered = this.matches
    },

    setSentinel(){
      const observer = new IntersectionObserver( async (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          if(this.searchString == ''){
            if(this.subPageActive == 'live'){
              this.page += 1
              await this.getLiveMatches()
            }
            else if(this.subPageActive == 'upcoming'){
              this.page += 1
              await this.scheduledesMatchList()
            }
            else if (this.subPageActive == 'past'){
              this.page += 1
              await this.lastMatchesList()
            }
          }
        }
      }
      })
      const scrollSentinel = document.querySelector("#scroll-sentinel");
      observer.observe(scrollSentinel);
    }
    
  },
  computed: {
    language() {
      return i18n.locale;
    }
  },
  async mounted() {   
    await  this.scheduledesMatchList();
    this.setSentinel()      
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.footerItem = 'match-list';
    store.layout.headerTitle = this.subPageActive == 'past' ?  this.$t('match_list.title_2') :  this.$t('match_list.title_1')
  },
  beforeDestroy(){
    clearInterval(this.timerInterval);
  }
};
</script>
<style scoped>
#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}
.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
}
.answer-bet-text{
  padding-right: 5px;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.around-score{
  width: 100%; 
  height: 115px; 
  background: #191B22;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.603922);
  backdrop-filter: blur(15px);
  border-radius: 32px;
}
.data-text{
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #B0B1CA;
}
.team-name{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
}
.score-text{
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: #FFFFFF
}
.goals{
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  color: #FFFFFF;
}
.total-points-text{
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 10px;
}
.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
  border-radius: 7px 7px 7px 7px;
  margin-left: 5px;
}
.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}

.search-bar{
  margin-bottom: 15px;
  margin-top: 10;
  background-color: #282E3D;
  border-radius: 15px;
  width: 95%;
  height: 40px;
}
.search-bar-input{
  width: calc(100% - 80px);
}
.search-bar-icon{
  width: 40px;
}
.custom-input{
  border-width: 0;
  padding: 0px !important;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
  background-color: #282E3D;
  width: 100%;
  height: 100%;
} 
::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

.matches-card{
  height: 100%;
  width: 95%;
  background-color: #282E3D;
  border-radius: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.joined-match-text{
  font-weight: 700;
  font-size: 12px;
  text-transform: capitalize;
  color: #FFCD00;
  padding-top: 5px;
}

.joined-match-card{
  height: 100%;
  width: 95%;
  background: #0A57B7;
  border-radius: 16px;
  margin-bottom: 10px;
  padding-top: 20px;
  margin-top: -30px;
}

.matches-card-icon{
  width: 40%;
}

.matches-card .around-img{
  width: 45px; 
  height: 45px;
  border: 1px solid #FFFFFF;
  border-radius: 16px;
}

.matches-card img{
  width: 45px; 
  height: 45px;
}

.matches-card-name{
  width: 20%;
}




</style>
