import store from '../store'
import service from '../service'

const joinedMatch = {
    sendBetUser: async (matchId, cardType, answers) => {
        try {
            const response = await service.$http.post(`${store.API_URL}bet`, { "matchId": matchId, "cardType": cardType, "answers": answers });
            return response;
        } catch (e) {
            return e.response;
        } 
   },
}

export default joinedMatch