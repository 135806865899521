export default {
  general:{
    currency: "د.ع",
    rank: "الترتيب",
    quiz: "اختبار",
    coming_soon: "قريباً",
    english: "إنجليزي",
    en: "en",
    arabic: "عربي",
    ar: "ar",
    kurdish: "كردي",
    ku: "ku",
    points: "نقاط"
  },
  error:{
    invalid_phone_number: "رقم الهاتف غير صحيح",
    invalid_pin_code: "الرمز السري غير صالح",
    error: "خطأ",
    select_answer: "اختر واحدة!",
    connexion_error: "حدث خطأ في الاتصال!"
  },
  input:{
    phone_number_label: "رقم الهاتف",
    phone_number_prefix: "+964"
  },
  login: {
    login: "تسجیل الدخول",
    lets_get_started: "لنبدأ",
    welcome: "مرحبًا بك في كابتن كورك!",
    first_day: "اليوم الاول مجاني ثم 300 د.ع يومياً",
    unsubscribe: "لإلغاء الاشتراك، أرسل 0 إلى 3230",
    free_charge: "(مجانًا)",
    to_continue: "للاستمرار",
    successfully_entered: "تم إدخال  بنجاح",
    your_number: "رقمك",
    subscribe: "اشتراك",
    continue: "استمرار",
    re_send_pin: "إعادة إرسال الرمز"
  },
  header:{
    title: "بطاقة كابتن"
  },
  home: {
    title:"الصفحة الرئيسية",
    total_points: "إجمالي النقاط",
    ranking: "الترتيب",
    live: "مباراة مباشرة",
    upcoming: "القادمة",
    past_matches: "المباريات السابقة",
    view_all: "عرض الكل",
    leagues: "البطولات",
    top_3_matches: "أفضل 3 مباريات",
    today: "اليوم",
    versus: "VS",
    points_per_card: "#نقاط/ بطاقة",
    joined: "انضمت",
    join_or_play: "المشاركة او اللعب",
    ended: "انتهى",
  },
  change_language: {
    en: "EN",
    ku: "کوردی",
    ar: "العربية"
  },
  about: {
    title: "حول"
  },
  terms: {
    title: "الشروط والأحكام"
  },
  privacy:{
    title: "سياسة الخصوصية"
  },
  unsubscribe:{
    title: "إلغاء الاشتراك",
    sub_title_1: "نأسف جدًا لتوديعك",
    sub_title_2: "نود معرفة سبب إلغاء اشتراكك",
    reason_1: "لم تعجبني هذه اللعبة",
    reason_2: "انها مكلفة جدًا",
    reason_3: "ليس لدي وقت",
    reason_4: "الإشعارات كثيرة",
    reason_5: "السبب لا يتعلق بك",
    unsubscribe: "إلغاء الاشتراك",
    error_reason: "يرجى اختيار سبب واحد على الأقل"
  },
  modal_more:{
    winners: "الفائزين",
    manage_account: "إدارة الحساب",
    about: "حول",
    faq: "الأسئلة الشائعة",
    terms: "الشروط والأحكام",
    unsubscribe: "إلغاء الاشتراك",
    privacy: "سياسة الخصوصية",
    logout: "تسجيل الخروج"
  },
  cards:{
    sub_menu:{
      active: "البطاقات المفعلة",
      buy: "شراء بطاقة",
      history: "سجل البطاقات"
    },
    select_card: "اختر البطاقة",
    title: 'بطاقاتي',
    daily: "يومي",
    captain: "كابتن",
    card: "بطاقة",
    gold: "ذهبية",
    vs: "VS",
    pts: "نقاط",
    active: "نشطة",
    buy_card: "شراء بطاقة",
    unavailable: "يجب عليك شراء بطاقة للعب",
    daily_unavailable: "عد غدًا لتحصل على بطاقة للعب!",
    captain_unavailable: "ليس لديك أي بطاقات كابتن! احصل عليها الآن!",
    gold_unavailable: "ليس لديك أي بطاقات ذهبية! احصل عليها الآن!",
    captain_price: "For #240 IQD",
    gold_price: "For #480 IQD",
    dayly_price: "خمس بطاقات مجانًا"
 },
  modal_buy_cards:{
    buy_card: "شراء بطاقة",
    verify_payment: "تأكيد الدفع",
    please_verify: "يرجى تأكيد الرمز",
    from_number: "على رقم الهاتف",
    you_entered: "قمت بالدخول بنجاح",
    your_number: "رقمك",
    purchase_successful: "نجحت عملية الشراء",
    go_to_my_cards: "انتقل إلى بطاقاتي",
    submit: "تثبيت",
    cancel: "إلغاء",
    confirm: "تأكيد",
    buying: "جارِ الشراء",
    get: "احصل على",
    cards_by: "بطاقات بواسطة",
    iqd: "د.ع",
    for: "مقابل",
    invalid_pincode: "تم إدخال رمز سري غير صحيح",
    recharge: "عذرا ، لا يوجد رصيد كافي ، أعد رصيد وحاول مرة أخرى",
  },
  leagues:{
    title: "البطولات"
  },
  league:{
    sub_menu:{
      last_matches: "المباريات السابقة",
      standings: "الترتيب",
      schedule: "الجدول"
    },
    match_continues: "تستمر المباراة",
    match_over: "انتهت المباراة",
    draw_over: "انتهت  السحبة",
    won: "فاز",
    play: "العب"
  },
  leaders:{
    title: "القادة",
    sub_menu:{
      weekly: "هذا الأسبوع",
      monthly: "This month",
      all: "الكلي"
    },
    no_named: "بلا اسم"
  },
  news:{
    title: "أخبار",
    hot_news: "اهم الاخبار",
    lastest_news:"أحدث الأخبار"
  },
  daily_quiz:{
    title: "اختبار يومي",
    points_earned: "النقاط المكتسبة",
    next_question: "السؤال التالي",
    submit: "تثبيت",
    select_quiz_response: "اختر إجابة!"
  },
  winners:{
    title: "الفائزون",
    sub_menu:{
      weekly: "أسبوعيًا",
      grand: "الكبيرة"
    },
  },
  joined_match:{
    title: "الانضمام إلى المباراة",
    join_contest: "الانضمام إلى التحدي",
    super_matches_contest: "مسابقة مباريات السوبر",
    amount: "1,075,000",
    iqd_every_week: "د.ع أسبوعيًا",
    select_card: "اختر بطاقة",
    question: "السؤال",
    ends_draw: "تعادل",
    none: "ولا واحد",
    both: "كلاهما",
    congratulations: "مبروك",
    completed_questions: "لقد أكملت الأسئلة",
    points_correct_answers: "ستحصل على # نقطة مع الإجابات الصحيحة",
    notification_message: "سنرسل لك إشعارًا عند انتهاء المباراة. حظًا سعيدًا!",
    next_question: "السؤال التالي",
    submit: "تثبيت",
    go_home: "انتقل إلى الصفحة الرئيسية",
    red_cards: "البطاقات الحمراء",
    yellow_cards: "البطاقات الصفراء",
    goals: "الأهداف",
    choose_winner: "اختر الفائز؟",
    correct_answer: "الإجابة الصحيحة ",
    wrong_answer: "الإجابة الخاطئة "
  },
  manage_account:{
    title: "إدارة الحساب",
    rank: "الترتيب",
    matches_played: "مباريات شاركت بها",
    cards_played: "عدد البطاقات المستخدمة",
    favourite_cards: "البطاقات المفضلة",
    overall: "الترتيب العام",
    weekly: "الترتيب الأسبوعي"
  },
  faq:{
    title: "الأسئلة الشائعة",
    sub_menu:{
      general: "العامة",
      cards: "البطاقات",
      all: "بشكل كلي"
    }
  },
  match_list:{
    title_1:'المباریات',
    title_2:'نتائج المباریات',
    joined_match:'الانضمام إلى المباراة',
    total_points:'إجمالي النقاط المكتسبة',
    both: 'كلاهما',
    search_match: 'البحث عن مباراة',
    sub_menu:{
      live: "مباشرة",
      upcoming: "المباريات القادمة",
      past: "المباريات السابقة"
    },
  },
  logout:{
    msg: "هل أنت متأكد أنك تريد تسجيل الخروج؟ لن تكون قادرًا على استلام معلومات حول الرسائل والإشعارات بعد الآن. ستحتاج إلى تسجيل الدخول مرة أخرى.",
    cancel: "إلغا",
    yes: "نعم"
  },
  news_details:{
    source: "مصدر: ",
    source_url: "نوان المصدر: "
  }
}