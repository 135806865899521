import store from '../store'
import service from '../service'

const team = {
  getTeam: async (id) => {
    try {
      const response =  await service.$http.get(`${store.API_URL}team/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  getSponsoredActives: async (lang) => {
    try {
      const response =  await service.$http.get(`${store.API_URL}sponsored-team/active?lang=${lang}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
}

export default team