import store from '../store'
import service from '../service'

const news = {
  getNewsCategory: async (page, pageSize) => {
      try {
          const response = await service.$http.get(`${store.API_URL}news-category/userCategories?&page=${page}&pageSize=${pageSize}`);
          return response;
      } catch (e) {
          return e.response;
      } 
  },

  getNewsByCategory: async (categoryId, lang) => {
    try {
        const response = await service.$http.get(`${store.API_URL}news/${categoryId}/${lang}`);
        return response;
    } catch (e) {
        return e.response;
    } 
  },

  getAllNews: async (lang) => {
    try {
        const response = await service.$http.get(`${store.API_URL}news/${lang}`);
        return response;
    } catch (e) {
        return e.response;
    } 
  },

  getHotNews: async (lang) => {
    try {
        const response = await service.$http.get(`${store.API_URL}news/hotNews/${lang}`);
        return response;
    } catch (e) {
        return e.response;
    } 
  },

  getNewsDetailsById: async (id) => {
    try {
        const response = await service.$http.get(`${store.API_URL}news/details/${id}`);
        return response;
    } catch (e) {
        return e.response;
    } 
  },
}

export default news