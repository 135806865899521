<template>
  <div 
    name="modalLogout"
    class="flex justify-content-center align-items-center logout-dialog"
    :show-close="false"
    :visible="true"
    :append-to-body="false"
    width="90%"
    @close="closeDialog"
    center>
    <div class="flex flex-column justify-content-center align-items-center p-3">
      <div class="dialog-message"> {{ $t("logout.msg") }} </div>
      <div class="flex flex-row justify-content-center align-items-center p-3">
        <div class="flex justify-content-center align-items-center cancel-button" @click="closeDialog">{{ $t("logout.cancel")}} </div>
        <div class="flex justify-content-center align-items-center yes-button" @click="logout"> {{ $t("logout.yes")}} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalLogoutComponent",
  components:{

  },
  data() {
    return {}
  },
  methods: {
    closeDialog(){
        this.$emit("closeDialog", true);
    },
    logout(){
        this.$emit("logout");
        this.$emit("closeDialog", true);
    }
  }
};
</script>

<style scoped>
.logout-dialog{
    position: absolute;
    z-index: 2100;
    background: #282E3D;
    border-radius: 20px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.603922);
    height: 30%;
    width: 80%;
    font-weight: 400;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    margin-top: 60px;
}

.cancel-button{
    color: #FFCD00;
    margin-right: 40px;
}

.yes-button{
    color: #FFFFFF;
}

.dialog-message {
    color: #FFFFFF;
}



</style>