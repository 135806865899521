import i18n from "./translation";
import axios from "axios";
import mock from "./mocks/mock-tests";

import { store, notify } from "./store";
import router from "./router";

import user from "./services/user"
import dailyQuiz from "./services/daily-quiz"
import joinedMatch from "./services/joined-match"
import leagues from "./services/leagues"
import winners from "./services/winners"
import match from "./services/match"
import team from "./services/team"
import purchase from "./services/purchase"
import cards from "./services/cards"
import prizes from "./services/prizes"
import news from "./services/news"

const service = {
  $http: axios.create({ headers: { "Content-Type": "application/json" } }),
  user,
  dailyQuiz,
  leagues,
  joinedMatch,
  winners,
  match,
  team,
  purchase,
  cards,
  prizes,
  news
}

service.$http.interceptors.request.use((config) => {
  if(!store.ignoreLoading)
    store.layout.showLoading = true
  if(store.ignoreLoading)
    store.ignoreLoading = false
  config.headers.Authorization = "Bearer " + (store.user.token ? store.user.token : "")
  return config
});

function getHeader(response, header){
  return response && response.headers && response.headers[header] || 0;
}

service.$http.interceptors.response.use(
  (response) => {
    store.user.points = getHeader(response, "user-points");
    store.user.cards.daily = getHeader(response, "daily-card");
    store.user.cards.captain = getHeader(response, "captain-card");
    store.user.cards.gold = getHeader(response, "gold-card");
    store.layout.showLoading = false;
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      notify(i18n.t("error.error"), i18n.t("error.auth_error"), "error");
      setTimeout(() => {
        store.layout.showLoading = false;
        if(router.currentRoute.name && router.currentRoute.name != "login")
          router.push("/");
        localStorage.removeItem("userToken");
        store.user.token = null;
      }, 3000);
    } 
    else if (error.response.status == 400) {
      if (!store.ignoreDefaultError) {
        notify(i18n.t("error.error"), error.response.data.message || error.response.data.msg, "error", true);
      } 
      setTimeout(() => {
        store.layout.showLoading = false;
      }, 3000);
    } 
    else {
      if (!store.ignoreDefaultError) {
        notify(i18n.t("error.error"), i18n.t("error.connexion_error"),  "error", 1);
      }
      setTimeout(() => {
        store.layout.showLoading = false;
      }, 3000);
    }
    store.ignoreDefaultError = false;
    return Promise.reject(error);
  }
);

//process.env.NODE_ENV == "development" ? mock(service.$http) : "";

export default service