<template>
    <div class="loading" v-if="showLoading">
          <div class="stage">
            <div class="dot-falling"></div>
        </div>
        <div class="overlay"></div>
    </div>
</template>

<script>
import store from "../store";

export default {
  name: "LoadingComponent",
  computed: {
    showLoading(){
      return store.layout.showLoading
    },
  },    
  mounted(){
  }
}

</script>

<style scoped>

.loading {
    position: absolute;
    z-index: 9000;
    width: 100%;
    height: 100%;
    text-align: initial;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 32px 0;
    margin: 0 -5%;
    overflow: hidden;
    z-index: 8500;
  }

.overlay {
    opacity: .75;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(20px);
    position: absolute;
    z-index: 7000;
    height: 100%;
    width: 100%;
}

.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffcd00;
  color: #ffcd00;
  box-shadow: 9999px 0 0 0 #ffcd00;
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before, .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffcd00;
  color: #ffcd00;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffcd00;
  color: #ffcd00;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

</style>