import store from '../store'
import service from '../service'

const leagues = {
    showRanking: async () => {
        try {
            const response = await service.$http.get(`${store.API_URL}ranking-hide/showRanking`);
            return response;
        } catch (e) {
            return e.response;
        } 
   },
    seasons: async () => {
        try {
            const response = await service.$http.get(`${store.API_URL}season/currents`);
            return response;
        } catch (e) {
            return e.response;
        } 
   },
   leagueStandings: async (leagueId, year) => {
        try {
            const response = await service.$http.get(`${store.API_URL}standings/getSeasonStandings/${leagueId}/${year}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
    lastMatches: async (seasonId, page, pageSize) => {
        try {
            const response = await service.$http.get(`${store.API_URL}match/lastMatches/${seasonId}?page=${page}&pageSize=${pageSize}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
    lastMatchesByTeam: async (teamId, page, pageSize) => {
        try {
            const response = await service.$http.get(`${store.API_URL}match/lastMatchesByTeam/${teamId}?page=${page}&pageSize=${pageSize}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
    schedule: async (seasonId, page, pageSize) => {
        try {
            const response = await service.$http.get(`${store.API_URL}match/scheduledMatches/${seasonId}?page=${page}&pageSize=${pageSize}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
    scheduledMatchesByTeam: async (teamId, page, pageSize) => {
        try {
            const response = await service.$http.get(`${store.API_URL}match/scheduledMatchesByTeam/${teamId}?page=${page}&pageSize=${pageSize}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
}

export default leagues