import store from '../store'
import service from '../service'

const user = {
  getUser: async () => {
    try {
      const response = await service.$http.get(`${store.API_URL}user/me`);
      return response;
    } catch (e) {
      return e.response;
    }
  },  
  getUserCardHistory: async (page, pageSize) => {
    try {
      const response = await service.$http.get(`${store.API_URL}card/history?page=${page}&pageSize=${pageSize}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getUserRanking: async (type, page, pageSize) => {
      try {
          const response = await service.$http.get(`${store.API_URL}user-ranking/get-current-ranking?type=${type}&page=${page}&pageSize=${pageSize}`);
          return response;
      } catch (e) {
          return e.response;
      } 
  },
  requestPinCode: async () => {
    try {
      const response = await service.$http.get(`${store.API_URL}user/requestPinCode/964${store.phoneNumber}/${store.serviceLang}/${store.ti}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  verifyPinCode: async (pinCode,ts) => {
    try {
      const response =  await service.$http.get(`${store.API_URL}user/verifyPinCode/964${store.phoneNumber}/${pinCode}/${store.serviceLang}/${store.ti}/${ts}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  unsubscribe: async (msisdn,message,optionalNote) => {
    try {
      const response =  await service.$http.post(store.API_URL + "user/unsubscribeUser", {
                                                                    "msisdn" : msisdn,
                                                                    "message": message,
                                                                    "optionalNote": optionalNote
                                                                  });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  changeName: async (name) => {
    try {
      const response =  await service.$http.patch(store.API_URL + "user", {
                                                                    "name" : name,
                                                                  });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  changePhoto: async (img) => {
    try {
      const formData = new FormData();
      formData.append('file', img);
      const response = await service.$http.post(store.API_URL + "user/photo", formData, { 'Content-Type': 'multipart/form-data' })
      return response;
    } catch (e) {
      return e.response;
    }
  },
}

export default user