<template>
    <div class="content-page">
      <div class="flex flex-column align-content-center justify-content-start flex-wrap"> 
        <div class="flex align-content-center justify-content-center flex-wrap manage-account-content"> 
          <div class="flex flex-column">
            <div class="flex align-self-center justify-content-center align-items-center circle" @click="editPhotoAccount()">
              <img v-if="this.photoUrl" :src="getUserPhoto"/>
              <img v-else :src="require('@/assets/icons/soccer_ball.png')"/>
              <input ref="file" type="file" style="display: none;" @change="uploadFile">
            </div>
            <div class="flex align-self-center">
              <input class="custom-input" type="text" :ref="'editNameAccount'" :disabled="!this.edit" v-model="nameAccount" />
            </div>
            <div class="flex align-self-center ranking"> {{ $t("manage_account.rank") }} {{ this.overallRank }} </div>
          </div>
          <div class="flex" style="position: absolute;margin-left: 270px;margin-top: 20px;" @click="editNameAccount()">
            <img v-if="!this.edit" :src="require('@/assets/icons/edit_manage_account.png')" alt=""/>
            <img v-else :src="require('@/assets/icons/save_manage_account.png')" alt=""/>
          </div>
        </div>
      </div>
      <div class="flex flex-column align-content-center justify-content-center">
        <div class="flex align-items-center align-content-center justify-content-between align-self-center manage-account-card">
          <div class="flex flex-row align-items-center align-content-center justify-content-center pl-20">
            <div> <img :src="require('@/assets/icons/matchesIcon.png')" alt=""/> </div>
            <div class="text-card"> {{ $t("manage_account.matches_played") }} </div>
          </div>
          <div class="flex align-items-center align-content-center justify-content-center number-box mr-10"> {{ this.matchesPlayed }} </div>
        </div>
        <div class="flex align-items-center align-content-center justify-content-between align-self-center manage-account-card">
          <div class="flex flex-row align-items-center align-content-center justify-content-center pl-20">
            <div> <img :src="require('@/assets/icons/cardsIcon.png')" alt=""/> </div>
            <div class="text-card"> {{ $t("manage_account.cards_played") }} </div>
          </div>
          <div class="flex align-items-center align-content-center justify-content-center number-box mr-10"> {{ this.cardsPlayed }} </div>
        </div>
        <!-- <div class="flex flex-column align-items-start align-content-center justify-content-between align-self-center manage-account-card hg-140">
          <div class="flex flex-row align-items-center align-content-center justify-content-center pl-20 pt-10">
            <div> <img :src="require('@/assets/icons/favCardsIcon.png')" alt=""/> </div>
            <div class="text-card"> {{ $t("manage_account.favourite_cards") }} </div>
          </div>
          <div class="flex flex-column flex-row align-items-center align-content-center justify-content-center">
            <div v-if="this.favouriteCard == 'captain-card'"> 
              <span class="captain-card-text">
                {{ $t("cards.captain") }} 
                <span class="captain-card-text-white">{{ $t("cards.card") }} </span>
              </span>
              <img :src="require('@/assets/captain-card-manage-account.png')" alt=""/> 
            </div>
            <div v-if="this.favouriteCard == 'gold-card'" class="favourite-card"> 
              <div class="gold-card-div"> 
                <span class="gold-card-text">{{ $t("cards.gold") }}  
                  <span class="gold-card-text-2">{{ $t("cards.card") }}</span>
                </span> 
              </div>
            </div>
          </div>
        </div> -->
        <div class="flex flex-row align-items-center align-content-center justify-content-center align-self-center wd-90">
          <div class="flex flex-column align-items-center align-content-center justify-content-center align-self-center manage-account-card overall-rank">
              <div> <img :src="require('@/assets/icons/goldKas.png')" alt=""/> </div>
              <div class="text-card-no-margin"> {{ $t("manage_account.overall") }} </div>
              <div class="text-card-rank"> # {{ this.overallRank }} </div>
          </div>
          <div class="flex flex-column align-items-center align-content-center justify-content-center align-self-center manage-account-card weekly-rank">
              <div> <img :src="require('@/assets/icons/blueKas.png')" alt=""/> </div>
              <div class="text-card-no-margin"> {{ $t("manage_account.weekly") }} </div>
              <div class="text-card-rank"> # {{ this.weeklyRank }} </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import service from "../service";

export default {
  name: "LeagueComponent",
  props: {
    param: Object
  },
  components: {
  },
  data() {
    return {
      edit: false,
      nameAccount: 'Yilmazsatti',
      photoUrl:'',
      currentPoints: '',
      currentRanking: '',
      photoAccount: null,
      matchesPlayed: '',
      cardsPlayed: '',
      favouriteCard: '',
      weeklyRank: '',
      overallRank: ''
    }
  },
  methods: {
    editNameAccount(){
      if(this.edit){
        this.changeNameUser();
      }else{
        setTimeout(() => {
          this.$refs["editNameAccount"].focus();
        }, 50);
      }
      this.edit = !this.edit
    },
    editPhotoAccount(){
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    uploadFile() {
        this.photoAccount = this.$refs.file.files[0];
        this.submitFile()
    },
    async submitFile() {
      let response = await service.user.changePhoto(this.photoAccount);
      if (response && response.status == 201){
        this.photoUrl = response.data.photoUrl + "?qs=" + Math.random() 
      }
    },
    async changeNameUser() {
      let response = await service.user.changeName(this.nameAccount);
      if (response && response.status == 200){
        this.nameAccount = response.data.name;
        this.$forceUpdate();
      }
    },
    async getUserAsync() {
      let response = await service.user.getUser();
      if (response && response.status == 200){
        this.nameAccount = response.data.name;
        this.photoUrl = response.data.photoUrl;
        this.currentPoints = response.data.currentPoints;
        this.currentRanking = response.data.currentRanking;
        this.matchesPlayed = response.data.matchesPlayed;
        this.cardsPlayed = response.data.cardsPlayed;
        this.favouriteCard = response.data.favouriteCard;
        this.weeklyRank = response.data.weeklyRank;
        this.overallRank = response.data.overallRank;
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    getUserPhoto(){
      return "https://" + store.AWS_BUCKET +'.s3.'+ store.AWS_DEFAULT_REGION +'.amazonaws.com/'+ this.photoUrl
    }
    
  },
  async mounted() {
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("manage_account.title")
    await this.getUserAsync();
  },
};
</script>

<style scoped>
.hg-140{
  height: 140px !important;
}
.pt-10{
  padding-top: 10px !important;
}
.pl-20{
  padding-left:20px !important;
}

.mr-10{
  margin-right:10px !important;
}

.manage-account-content{
  width:90%; 
  min-height:170px; 
  background: linear-gradient(90deg, #0057B7 -4.76%, #002C5C 50%);
  border-radius: 18px;
}
.favourite-card{
  padding-bottom: 10px !important;
  padding-left: 5px !important;
}

.captain-card-text{
  position: absolute;
  margin-left: 40px;
  margin-top: 25px;
  font-weight: 300;
  font-size: 20px;
  color: #FFCD00;
}

.captain-card-text-white{
  color: #FFFFFF;
}

.wd-90{
  width: 90%;
}

.overall-rank{
  height:155px !important;
  width: 50% !important;
  margin-right: 10px !important;
  margin-top:0px !important;
}

.weekly-rank{
  height:155px !important;
  width: 50% !important;
}

.gold-card-div{
  background-image: url('../assets/gold-card-manage-account.png');
  background-repeat: no-repeat;
  width:330px;
  height:70px;
}

.gold-card-text{
  display: flex;
  font-weight: 700;
  font-size: 20px;
  color: rgb(25, 27, 34);
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gold-card-text-2{
  color: #FFFFFF;
  margin-left: 5px;
}

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
  min-height: var(--appHeight);
}

.circle {
  height: 75px;
  width: 75px;
  border: 1px solid #FECD01;
  border-radius: 50%;
  display: inline-block;
}

.circle img{
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.name{
  font-weight: 700;
  font-size: 25px;
  color: #FFFFFF;
}
.ranking{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
}
.manage-account-card{
  height: 70px;
  width: 90%;
  background-color: #282E3D;
  border-radius: 16px;
  margin-bottom: 10px;
}
.manage-account-card:first-child{
  margin-top: 10px;
}

.text-card{
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
}
.text-card-no-margin{
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
}
.text-card-rank{
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #FFCD00;
}
.number-box{
  background: #191B22;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.603922);
  backdrop-filter: blur(15px);
  border-radius: 23px;
  width: 65px;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #FFCD00;
}

.custom-input{
      border-width: 0;
      padding: 0px !important;
      background: transparent;
      width: 100%;
      min-height: 38px;
      text-align: center;
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 25px;
      color: #FFFFFF;
      /* height: 100%; */
  } 


</style>
