import store from '../store'
import service from '../service'

const leagues = {
    winners: async (type, year, page, pageSize) => {
        try {
            const response = await service.$http.get(`${store.API_URL}winner/byTypeYear/${type}/${year}?page=${page}&pageSize=${pageSize}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
}

export default leagues