<template>
    <div class="content-page">
      <div class="flex flex-column align-content-start justify-content-start flex-wrap content-about">
        <div class="flex align-self-center about_card">
        </div>
        <div class=" title " :class="language == 'en' ? 'text-left' : 'text-right'">
          {{ $t("about.title") }}
        </div>
        <div class="flex flex-column align-items-start align-content-start text" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">
          <div v-if="language == 'en'">
            <span>The “Captain Korek” Service is a subscription service exclusive to Korek Mobile Phone subscribers, renewed every day, which provides sports news feeds to
            users (web & SMS based). Moreover, it will consist of a web game in which users will play and compete to win weekly prizes, by correctly predicting the outcome
            of international football matches. Moreover, subscribers may answer the Trivia Questions to increase their points for the prizes. Participants, apart from the
            weekly top scorer prizes, enter weekly draws & the Grand Prize draw for prizes.
          </span>
            <ul>
              <li>
                Total Weekly Draw for 2 winners
                <div>600,000 IQD in cash</div>
              </li>
              <li>
                Weekly Top Scorers 1 winner
                <div>300,000 IQD in cash</div>
              </li>
              <li>
                Weekly Draw for 7 winners
                <div>25,000 IQD credit</div>
              </li>
              <li>
                Total Grand Prize Draw for 2 winners
                <div>50,000,000 IQD in cash</div>
              </li>
            </ul>
          </div>
          <div v-if="language == 'ar'" class="text-right">
            <span>خدمة "كابتن كورك" هي خدمة اشتراك حصرية لمشتركي كورك وتقدم محتوى رياضي مباشر على الانترنت وعبر
               الرسائل النصية القصيرة. بالإضافة الى ذلك، انها توفر لعبة على الإنترنت يلعب فيها المستخدمون ويتنافسون للفوز بجوائز نقدية أسبوعية من خلال توقع نتائج مباريات كرة القدم الدولية بشكل صحيح. ويمكن للمشتركين كذلك الإجابة على الأسئلة
               العامة لزيادة نقاطهم للفوز بالجوائز. بالاضافة الى ذلك، يمكن للمشتركين الإجابة على أسئلة عامة لزيادة نقاطهم للفوز بالجوائز. سيدخل المشتركون السحبات الأسبوعية وسحبة الجائزة الكبرى، ما عدا الجوائز الأسبوعية لصاحب أعلى النقاط.
          </span>
            <ul>
              <li>
                السحبة الأسبوعية لفائزين (2)
                <div> 600,000دينار عراقي كاش</div>
              </li>
              <li>
                الفائز الأسبوعي صاحب اعلى النقاط
                <div> 300,000دينار عراقي كاش</div>
              </li>
              <li>
                السحبة الأسبوعية لـ 7 فائزين
                <div> 25,000 رصيد  </div>
              </li>
              <li>
                سحبة الجائزة الكبرى لفائزين (2)
                <div> 50,000,000 دينار عراقي كاش</div>
              </li>
            </ul>
          </div>
          <div v-if="language == 'ku'" class="text-right">
            <span>خزمەتگوزاری کاپتن کورەك خزمەتگوزارییەکی بەشداریکردنی
               تایبەتە کە تەنها بۆ بەشداربووانی کورەك بەردەستە (لە ڕێگەی ئینتەرنێت و کورتەنامە) کە ڕۆژانە نوێ دەکرێتەوە. هەروەها، یارییەکی ئینتەرنێتی تێدایە کە بەکارهێنەران دەتوانن لە ڕێگەیەوە یاری
               و پێشبڕکێ بکەن بۆ بردنەوەی خەڵاتی هەفتانە بە پێشبینیکردنی دروستی ئەنجامی یارییە نێودەوڵەتییەکانی تۆپی پێ. بەشداربووان دەتوانن وەڵامی پرسیارە گشتیەکان بدەنەوە بۆ زیادکردنی خاڵەکانیان بۆ
               چانسی بەدەستهێنانی خەڵات. وە هەروەها بەشداربووان، جگە لە خەڵاتی هەفتانە بۆ خاوەنی زۆرترین خاڵ، بەشداری لە تیروپشکی هەفتانە و تیروپشکی خەڵاتی گەورە دەکەن بۆ بردنەوەی خەڵاتەکان.

          </span>
            <ul>
              <li>
                تیروپشکی هەفتانە بۆ 2 براوە
                <div>600,000 دینار کاش</div>
              </li>
              <li>
                هەفتانە 1 براوە خاوەنی زۆرترین خاڵ
                <div>300,000 دینار بە کاش</div>
              </li>
              <li>
                تیروپشکی هەفتانە بۆ 7 براوە
                <div>25,000 دینار باڵانس</div>
              </li>
              <li>
                تیروپشکی خەڵاتی گەورە بۆ 2 براوە
                <div>50,000,000 دینار کاش</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";

export default {
  name: "AboutComponent",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    language() {
      return i18n.locale;
    }
  },
  mounted() {
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("about.title")
  },
};
</script>

<style scoped>

.content-page{
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}
.content-about{
  min-height: var(--appHeight);
}

.about_card{
  background-image: url('../assets/about_card.png');
  background-repeat: no-repeat;
  width:350px;
  height:140px;
  margin-bottom: 20px;
}

.title{
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  padding-left: 40px;
  margin-bottom: 20px;

}

.text{
  font-weight: 400;
  font-size: 12px;
  color: #B0B1CA;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left
}

</style>
