<template>
<transition name="slide-fade">
  <div class="modal">
      <div class="modal-content">
        <div class="modal-cards">
          <div class="flex align-self-center line" @click="closeModal()"></div>
          <div class="flex justify-content-center align-items-center" style="margin-top: 5px"> 
            <div v-if="!this.selected && !this.purchased" class="flex text-1"> {{ $t("modal_buy_cards.buy_card") }} </div>
          </div>
          <div class="flex flex-column align-content-around justify-content-around align-items-center flex-wrap h-full">
            <div class="flex flex-column" v-if="!this.selected && !this.purchased">
              <div v-for="item in labelsPrices" :key="item.amount" >
                <div class="flex flex-row justify-content-between w-full" style="min-width: 300px; margin-top:10px;">
                  <div class="flex align-items-center text-white" style="font-size: 12px">
                     {{  $t('modal_buy_cards.get') + " " + item.cards + " " + $t('modal_buy_cards.cards_by')+ " " + parseInt(item.cost) + " " + $t('modal_buy_cards.iqd') }} 
                  </div>
                  <div class="flex align-self-end buy-card" @click="buyCardAmount(item.cards)">{{ $t('cards.buy_card') }}</div>
                </div>
              </div>
            </div>
            <div v-if="this.selected && !this.purchased" class="flex text-1"> 
              {{ $t("modal_buy_cards.verify_payment") }} 
            </div>
            <div v-if="this.selected && !this.submited && !this.purchased" class="flex flex-column"> 
              <div class="flex text-2"> {{ $t("modal_buy_cards.please_verify") }} </div>
              <div class="flex text-2"> {{ $t("modal_buy_cards.from_number") }} </div>
            </div>
            <div v-if="this.selected && this.submited && !this.purchased" class="flex flex-column"> 
              <div class="flex text-2 align-self-center"> {{ $t("modal_buy_cards.you_entered") }} </div>
              <div class="flex text-2 align-self-center"> {{ $t("modal_buy_cards.your_number") }} </div>
            </div>
            <div v-if="this.selected && this.purchased" class="flex"> 
              <img :src="require('@/assets/icons/check.png')" />  
            </div>
            <div v-if="this.selected && this.purchased" class="flex text-1"> 
              {{ $t("modal_buy_cards.purchase_successful") }} 
            </div>
            <div v-if="this.selected && this.purchased" class="flex flex-column"> 
              <div class="flex text-2">  {{ $t('modal_buy_cards.for') + ' #' + getCost + ' ' + $t('modal_buy_cards.iqd')}} </div>
              <div class="flex text-2">  {{ $t('modal_buy_cards.buying') }} </div>
            </div>
            <div v-if="this.selected && this.purchased" class="flex align-content-center justify-content-center align-items-center my-cards-button" @click="closeModal()"> 
              {{ $t("modal_buy_cards.go_to_my_cards") }}
            </div>
            <div v-if="this.selected && !this.purchased" class="flex align-self-center justify-content-center w-full" style="max-width: 390px;">
              <!-- <div v-if="!this.submited" class="flex align-self-center justify-content-center around-input">
                <div class="flex flex-column align-self-center">
                  <div class="flex justify-content-start phone-number-text">{{ $t("input.phone_number_label") }}</div>
                  <div class="flex justify-content-start phone-number-prefix-text">{{ $t("input.phone_number_prefix") }}</div>
                </div>
                <div class="flex align-self-end" style="padding-bottom:10px">
                  <input class="custom-input" type="text" v-maska="'7## ### ####'"/>
                </div>
              </div> -->
              <div class="flex flex-row align-self-center justify-content-around w-full">
                <div v-for="i in 4" :key="i" class="flex flex-column align-items-center justify-content-center around-input-otp" >
                  <input class="custom-input-otp" type="text" v-maska="'#'" @keydown="onKeyup($event, i)" :ref="'pinCode_' + i" v-model="pinCode[i - 1]"/>
                </div>
              </div>
            </div>
            
            <div v-if="this.selected && !this.purchased" class="flex w-full" style="max-width: 330px;"> 
              <div class="flex align-content-center justify-content-center align-items-center cancel-button" @click="closeModal()">{{ $t("modal_buy_cards.cancel") }}</div>
              <!-- <div v-if="!this.submited" class="flex align-content-center justify-content-center align-items-center confirm-button" @click="submitPhone()">{{ $t("modal_buy_cards.submit") }}</div> -->
              <div class="flex align-content-center justify-content-center align-items-center confirm-button" @click="confirm()">{{ $t("modal_buy_cards.confirm") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import service from "@/service";

export default {
  name: "ModalBuyCards",
  components: {
  },
  directives: { maska },
  data() {
    return {
      labelsPrices:[],
      selected: false,
      submited: false,
      purchased: false,
      pinCode: [],
      amountSelected: 0
    };
  },
  methods: {
    onKeyup(event, item) {
      if (item < 4 && event.keyCode != 8) {
        setTimeout(() => {
          this.$refs["pinCode_" + (item + 1)][0].focus();
        }, 15);
      } else if (item > 1 && event.keyCode == 8) {
        setTimeout(() => {
          this.$refs["pinCode_" + (item - 1)][0].focus();
        }, 15);
      }
    },

    async buyCardAmount(amount){
      this.selected = true
      this.amountSelected = amount
      await this.buyCoinsAssync()
    },

    async buyCoinsAssync() { 
      let response = await service.purchase.requestPinCode(this.language,this.getPurchasedTypeId())
      if (response && (response.status === 200 || response.status === 400) && response.data.status && response.data.status.toLowerCase() === 'failed') {
        notify(this.$t('modal_buy_cards.recharge'), '', 'error')
      } else {
        if (response && response.status === 200) {
          setTimeout(() => {
            this.pinCode = []
            setTimeout(() => {
              this.$refs["pinCode_1"][0].focus()
            }, 100)
          }, 50)
        }
      }
    },

    async confirm(){
      if(this.pinCode.length != 4){
        notify(this.$t('error.error'), this.$t('modal_buy_cards.invalid_pincode'), 'error')
      }
      else {
        let response = await service.purchase.verifyPinCode(this.pinCode.join(""), this.getPurchasedTypeId())
        if(response.status == 200)
          this.purchased = true
        else
          notify(this.$t('error.error'), this.$t('modal_buy_cards.invalid_pincode'), 'error')
      }
    },

    closeModal(){
      store.layout.showModalBuyCards = false;
      //this.$router.push("cards");
    },

    async getLabelsPrices(){
      let response = await service.purchase.getPurchaseType(store.cardsPrices.currentType + '-card')
      if (response && response.data && response.status == 200){
        this.labelsPrices = response.data.sort((a, b) => {
          return a.cards - b.cards;
        });
      }
    },

    getPurchasedTypeId(){
      let id = this.labelsPrices.find(l => l.cards == this.amountSelected).id
      return id
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
    getCost(){
      let cost = this.labelsPrices.find(l => l.cards == this.amountSelected).cost
      return parseInt(cost)
    }
  },
  async mounted() {
    await this.getLabelsPrices()
    document.addEventListener('mousedown', (event) => {
      if(event.target.className == 'modal') {
        store.layout.showModalBuyCards = false;
      }
    })
  },
};
</script>

<style scoped>
.line{
  background: rgba(176, 177, 202, 0.4);
  border-radius: 99px;
  width: 115px;
  height: 5px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.modal {
  display: block; 
  position: absolute; 
  z-index: 1001; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
}
.modal-content {
  background: #191B22;
  box-shadow: 0px -22px 11px rgba(25, 27, 34, 0.235294);
  border-radius: 25px;
  height: calc(var(--appHeight) - (var(--appHeight) * 0.2));
  margin-top: calc(var(--contentTop) + (var(--appHeight) * 0.2));
  margin-right: 10px;
  margin-left: 10px;
  max-width: 350px;
}
.modal-cards {
  flex-direction: column;
  display: flex;
  height: 100%;
}
.around-input{
  background-color: #282E3D;
  border-radius: 15px;
  width: 330px;
  height: 65px;
}
.around-input-otp{
  background-color: #282E3D;
  border-radius: 15px;
  width: 60px;
  height: 60px;
}
.custom-input{
      border-width: 0;
      padding: 0px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      color: #B0B1CA;
      background-color: #282E3D;
      width: 100%;
      height: 100%;
  } 
.phone-number-text{
  padding-left: 20px ;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}
.phone-number-prefix-text{
  padding-left: 20px ;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}
.text-1{
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
}

.text-2{
  font-size: 15px;
  color: #B0B1CA
}

.my-cards-button{
  background: #FFFFFF;
  border-radius: 15px;
  height: 50px;
  margin-right: 5px;
  font-size: 15px;
  color: #191B22;
  color: #191B22;
  font-weight: 700;
  width: 90%;
  max-width: 330px;
}

.cancel-button{
  background: #282E3D;
  border-radius: 15px;
  height: 50px;
  width: 30%;
  margin-right: 5px;
  font-size: 15px;
  color: #FFFFFF;
}

.confirm-button{
  background: #FFCD00;
  border-radius: 15px;
  height: 50px;
  width: 70%;
  font-weight: 700;
  font-size: 15px;
  color: #191B22;
}
::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}

.custom-input-otp{
      border-width: 0;
      padding: 0px !important;
      font-weight: 700;
      font-size: 15px;
      color: #FFFFFF;
      background-color: #282E3D;;
      width: 10px;
      height: 20px;
}
.buy-card{
  border-radius: 11px;
  background-color: #0057B7;
  color: #FFFFFF;
  height: 35px;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

</style>
