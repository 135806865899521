<template>
    <div class="content-page">
      <div class="flex flex-column align-content-center justify-content-start flex-wrap">
        <div class="flex flex-row align-self-center search-bar">
            <div class="flex align-items-center justify-content-center search-bar-icon">
              <img :src="require('@/assets/icons/search.png')" alt="" />
            </div>
            <div class="flex  align-items-center search-bar-input">
              <input class="custom-input" type="text" placeholder="Search Faq" @input="onInput" v-model="searchString"/>
            </div>
            <div class="flex align-items-center justify-content-center search-bar-icon">
              <img :src="require('@/assets/icons/settings-sliders.png')" alt="" />
            </div>
          </div>
        </div>
      <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
        <div class="flex align-self-center align-items-center justify-content-center sub-menu">
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-left" 
          :class="{'sub-page-active':checkActive('general')}" @click="setActiveSubPage('general')">
            {{ $t("faq.sub_menu.general") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item" 
          :class="{'sub-page-active':checkActive('cards')}" @click="setActiveSubPage('cards')">
            {{ $t("faq.sub_menu.cards") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-rigth" 
          :class="{'sub-page-active':checkActive('all')}" @click="setActiveSubPage('all')">
            {{ $t("faq.sub_menu.all") }}
          </div>
        </div>
      </div>
      <div class="flex flex-column align-content-start justify-content-start content-faq">
        <div v-for="(f,index) in faqList" :key="f.id" class="flex flex-column align-self-center align-items-center justify-content-start">
          <div class="flex align-self-center align-items-center justify-content-start faq-button" :class="{'faq-button-selected':indexSelected == index}" @click="select(index)">
            <div class="question-content"> 
              <img v-if="indexSelected == index" src="@/assets/icons/comment-question-yellow.png" alt=""/> 
              <img v-else src="@/assets/icons/comment-question.png" alt=""/> 
            </div>
            <div> {{ f.question }} ? </div>
          </div>
          <div :class="{'div-answer': true, 'div-answer-active':indexSelected == index}"> {{ f.answer }} </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";
import  service from "../service";

export default {
  name: "FaqComponent",
  components: {
  },
  data() {
    return {
      faqList: [{'id':1,'question':'question 1','answer':'answer 1'},
                {'id':2,'question':'question 2','answer':'answer 2'},
                {'id':3,'question':'question 3','answer':'answer 3'}],
      indexSelected: -1,
      subPageActive: 'general',
      searchString: ''
    }
  },
  methods: {
    onInput(event){
      if(this.searchString != '')
        this.faqList = this.faqList.filter(l => l.question.toLowerCase().includes(this.searchString) 
                                                || l.answer.toLowerCase().includes(this.searchString))
      else
        this.faqListAsync()
    },
    setActiveSubPage(page) {
      this.subPageActive = page;
      // if(this.subPageActive == 'general')
      //   this.lastMatches(this.season.id)
      // if(this.subPageActive == 'cards')
      //   this.leagueStandings(this.p.leagueID,this.p.year)
      // if(this.subPageActive == 'all')
      //   this.schedule(this.season.id)
    },
    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },
    select(index){
      this.indexSelected = index;
    },
    async faqListAsync() {

      this.faqList = [{'id':1,'question':'question 1','answer':'answer 1'},
                      {'id':2,'question':'question 2','answer':'answer 2'},
                      {'id':3,'question':'question 3','answer':'answer 3'}];

      // let response = await service.leagues.seasons();
      // if (response && response.status == 200)
      //   this.leagues = response.data;
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    selected(){
      return this.selectedArray
    }
  },
  mounted() {
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("faq.title")
  },
};
</script>

<style scoped>
.search-bar{
  margin-bottom: 10px;
  background-color: #282E3D;
  border-radius: 15px;
  width: 95%;
  height: 40px;
}
.search-bar-input{
  width: calc(100% - 80px);
}
.search-bar-icon{
  width: 40px;
}
.custom-input{
  border-width: 0;
  padding: 0px !important;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
  background-color: #282E3D !important;
  width: 100%;
  height: 100%;
} 
::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

.div-answer {
  display: none;
  width: 100px;
  height: 0;
  transition: height 1s;
}

.div-answer-active {
  display: block;
  height: 50px;
}

.content-page {
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}

.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
}

.sub-menu-item-left{
  border-radius: 7px 0px;
}

.sub-menu-item-rigth{
  border-radius: 0px 7px;
}

.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}
.content-faq{
  min-height: var(--appHeight)
}
.faq-button{
  background-color: #282E3D;
  border-radius: 15px;
  width: 330px;
  height: 55px;
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.faq-button-selected{
  background: #0057B7;
  font-weight: 700;
  font-size: 15px;
  color: #FFCD00;
}

::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}

.custom-input{
    border-width: 0;
    padding: 0px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #B0B1CA;
    background-color: #191B22;
    width: 85%;
    height: 80%;
} 
.question-content{
  width: 30px;
  padding-left: 10px;
  padding-right: 40px;
}
</style>
