<template>
  <div class="content-page">
    <div class="flex flex-column h-full content-daily">
      <div v-if="isHomeQuiz" class="cards-selection relative pb-4">
        <div class="absolute home-quiz pt-3 pb-3">
          <img :src="require('@/assets/icons/daily-quiz-icon.png')"/> 
          <div>{{ $t('daily_quiz.title') }}</div>
          <div class="mb-2">{{ $t("joined_match.super_matches_contest") }}<span class="ml-1 mr-1">{{ $t("joined_match.amount") }}</span>{{ $t("joined_match.iqd_every_week") }}</div>
        </div>
        <span class="select-card flex justify-content-left">{{ $t('cards.select_card') }}</span>
        <PlayCard
          type="captain"
          :amount="cards.captain"
          @selectCard="onSelectCardHome"
          :label="$t('cards.captain')"
          :points="cardsPoints.captain.correct + ' ' + $t('cards.pts')">
        </PlayCard>
        <PlayCard
          type="gold"
          :amount="cards.gold"
          @selectCard="onSelectCardHome"
          :label="$t('cards.gold')"
          :points="cardsPoints.gold.correct + ' ' + $t('cards.pts')">
        </PlayCard>
        <PlayCard
          type="daily"
          :amount="cards.daily"
          @selectCard="onSelectCardHome"
          :label="$t('cards.daily')"
          :points="cardsPoints.daily.correct + ' ' + $t('cards.pts')">
        </PlayCard>         
      </div>
      <div v-else class="play-quiz">
        <div class="question">
          <span>
            {{ question["description_" + language] }} 
          </span>
        </div>
        <div v-for="pos in 2" :key="pos" @click="selectAnswer(pos)"
          :class="{
            answer: true,
            selected: selectedAnswer == pos,
            correct: !isSubmit && rightAnswer == pos
            // rtlDirection: language != 'en',
          }">
          <span>{{ question["answer" + pos + "_" + language] }}</span>
          <img v-if="isSubmit && selectedAnswer == pos" :src="require('@/assets/icons/check-quiz.png')"/> 
          <img v-if="!isSubmit && pos == rightAnswer" :src="require('@/assets/icons/check-quiz.png')"/> 
          <img v-if="!isSubmit && pos != rightAnswer" :src="require('@/assets/icons/close.png')"/> 
        </div>
        
        <div class="cards-quiz">
          <PlayCard
            v-if="isSubmit || cardSelected == 'captain'"
            :amount="cards.captain"
            :checked="rightAnswer == selectedAnswer && !isSubmit"
            :label="$t('cards.captain')"
            type="captain"
            :points="cardsPoints.captain.correct + ' ' + $t('cards.pts')"
            @selectCard="onChangeSelectCard"
            :selected="cardSelected == 'captain'">
          </PlayCard>
          <PlayCard
            v-if="isSubmit || cardSelected == 'gold'"
            :amount="cards.gold"
            :checked="rightAnswer == selectedAnswer && !isSubmit"
            type="gold"
            :label="$t('cards.gold')"
            :points="cardsPoints.gold.correct + ' ' + $t('cards.pts')"
            @selectCard="onChangeSelectCard"
            :selected="cardSelected == 'gold'">
          </PlayCard>
          <PlayCard
            v-if="isSubmit || cardSelected == 'daily'"
            :amount="cards.daily"
            :checked="rightAnswer == selectedAnswer && !isSubmit"
            type="daily"
            :label="$t('cards.daily')"
            :points="cardsPoints.daily.correct + ' ' + $t('cards.pts')"
            @selectCard="onChangeSelectCard"
            :selected="cardSelected == 'daily'">
          </PlayCard>
        </div>

        <div v-if="!isSubmit" class="points-earned">
          <img width="44" height="40" :src="require('@/assets/icons/daily-quiz-icon.png')" alt=""/>
          <div class="points-description">
            <img :src="require('@/assets/icons/points-earned.png')" alt=""/>
            <div>{{ $t('daily_quiz.points_earned') }}<span class="mr-1 ml-1">{{ pointsEarned }}</span></div>
          </div>
        </div>

        <div @click="submitNextQuestion()" class="submit-next-question">
          {{ isSubmit ? $t('daily_quiz.submit') : $t('daily_quiz.next_question')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import PlayCard from "@/components/PlayCards.vue";
import service from "../service";
import utils from "@/utils";

export default {
  name: "DailyQuizComponent",
  components: {
    PlayCard
  },
  data() {
    return {
      isHomeQuiz: true,
      isSubmit: true,
      selectedAnswer: 0,
      rightAnswer: 0,
      userAnswerID: 0,
      pointsEarned: 0,
      question: {},
      cardSelected: ''
    }
  },
  methods: {
    async submitNextQuestion(){
      this.isSubmit ? this.answerQuestion() : await this.showNextQuestion();
    },

    async onSelectCardHome(type){
      this.cardSelected = type 
      if(this.cards[type] > 0){
        await this.showNextQuestion();
        this.isHomeQuiz = false
      }
      else {
        notify(this.$t('error.note'), this.$t(`cards.${type}_unavailable`), 'info')
        if(this.cardSelected !== "daily"){
          store.cardsPrices.currentType = type
          store.layout.showModalBuyCards = true
        }
      }
    },

    onChangeSelectCard(type){
      this.cardSelected = type  
      if(this.cards[type] < 1){
        notify(this.$t('error.note'), this.$t(`cards.${type}_unavailable`), 'info')
        if(this.cardSelected !== "daily"){
          store.cardsPrices.currentType = type
          store.layout.showModalBuyCards = true
        }
      }
    },

    async answerQuestion() {
      if (this.selectedAnswer == 0) {
        notify(this.$t("error.note"), this.$t("daily_quiz.select_quiz_response"), "warn");
        return;
      }
      else if(this.cards[this.cardSelected] < 1){
        if(this.cardSelected != 'daily'){
          store.cardsPrices.currentType = this.cardSelected
          store.layout.showModalBuyCards = true
        }
      }
      store.ignoreDefaultError = true
      let response = await service.dailyQuiz.answerQuestion(this.userAnswerID, this.selectedAnswer, `${this.cardSelected}-card`)
      if (response && response.status === 200) {
        this.isSubmit = false
        this.rightAnswer = response.data.question.rightAnswer
        this.pointsEarned = response.data.points
      }
      else if(response && response.status === 400) {
        notify(this.$t('error.note'), this.$t('cards.unavailable'), 'info')
        if(this.cardSelected !== "daily"){
          store.cardsPrices.currentType = this.cardSelected
          store.layout.showModalBuyCards = true
        }
      }
    },
    async showNextQuestion() {
      await this.getNextQuestion()
      this.isSubmit = true
      this.selectedAnswer = 0
      this.rightAnswer = 0
      this.pointsEarned = 0
    },
    selectAnswer(answer) {
      this.selectedAnswer = answer;
    },
    async getNextQuestion() {
      let response = await service.dailyQuiz.nextQuestion();
      if (response && response.status === 200) {
        this.userAnswerID = response.data.id;
        this.question = response.data.question;
      }
    },

    async getCardsActives(){
      if(store.cardsPrices.points.daily !== 0)
        return
      let response = await service.cards.cardsActives()
      if (response && response.status == 200){
        store.cardsPrices.points.daily = response.data.filter(c => c.type == 'daily-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.gold = response.data.filter(c => c.type == 'gold-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.captain = response.data.filter(c => c.type == 'captain-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
      }
    },
  },
  watch: {
    language: function (oldVal, newVal) {
      store.layout.headerTitle = this.$t("daily_quiz.title")
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    cards() {
      return { daily: store.user.cards.daily, captain: store.user.cards.captain, gold: store.user.cards.gold } 
    },
    cardsPoints(){
      return store.cardsPrices.points;
    }

  },
  async mounted() {
    store.layout.showHeader = true;
    store.layout.showFooter = false
    store.layout.showAccountFooter = false
    store.layout.headerTitle = this.$t("daily_quiz.title")
    await service.user.getUser();
    await this.getCardsActives()
  },
};
</script>

<style scoped>

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
  padding: 0 10px;
  color: white;
}

.content-daily{
  min-height: var(--appHeight);
}

.play-quiz {}

.question {
  height: auto;
  width: calc(100% - 20px);
  margin: 0px 10px;
  color  :white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B0B1C8FF' stroke-width='1' stroke-dasharray='3' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 15px;  
  box-shadow: 0px 10px 15px 0px #000;
  font-family: 'Poppins';
  background-color: #191B22;
}

.question span {
  padding: 20px 10px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.answer {
  width: calc(100% - 20px);
  margin: 0px 10px;
  border-radius: 15px;
  background-color: #282e3d;
  display: flex;
  height: 95px;
  margin-top: 15px;
  font-weight: 700;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
}

.answer.selected {
  background-color: #13283f;
}

.answer.correct {
  background-color: #0057B7;
}

.answer span {
  padding-left: 40px;
  align-items: center;
  display: flex;
}

.answer img {
  width: 32px;
}

.cards-quiz {
  margin-top: 20px;
}

.points-earned {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
}

.points-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:10px;
  color: #FFCD00;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins-SemiBold';
}

.points-description img {
  margin-right: 10px;
}

.submit-next-question {
  border-radius: 12px;
  background-color: #0057B7;
  color: #ffffff;
  font-weight: 700;
  padding: 12px 0;
  width: calc(100% - 20px);
  margin: 0px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-quiz {
  height: auto;
  width: calc(100% - 20px);
  margin: 0px 10px;
  top: -50%;
  color  :white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B0B1C8FF' stroke-width='1' stroke-dasharray='3' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 15px;  
  box-shadow: 0px 10px 15px 0px #000;
  font-family: 'Poppins-SemiBold';
  background-color: #191B22;
}

.home-quiz img {
  margin-top: 10px;
}

.home-quiz div:nth-child(2) {
   font-size: 28px;
   margin-top: 20px;
}

.home-quiz div:nth-child(3) {
    font-size: 16px;
    margin-top: 5px;
    padding: 0 18px;
}

.home-quiz div:nth-child(3) > span {
  color: #FFCD00;
}

.cards-selection {
    background-color: #282E3D;
    border-radius: 20px;
    padding-top: 35%;
    margin-top: 55%;
}

.select-card {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 0px;
}

.uncheck-card {
    background-color: #ADAEC6;
}

</style>
