<template>
    <div class="content-page">
      <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
        <div class="flex align-self-center align-items-center justify-content-center sub-menu">
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-left" :class="{'sub-page-active':checkActive('active')}" @click="setActiveSubPage('active')">
            {{ $t("cards.sub_menu.active") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item" :class="{'sub-page-active':checkActive('buy')}" @click="setActiveSubPage('buy')">
            {{ $t("cards.sub_menu.buy") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-rigth" :class="{'sub-page-active':checkActive('history')}" @click="setActiveSubPage('history')">
            {{ $t("cards.sub_menu.history") }}
          </div>
        </div>
      </div>

      <div class="flex flex-column">

        <!-- active cards -->
        <div class="flex flex-column align-self-center align-items-center justify-content-center" v-if="this.subPageActive == 'active'">
          <div v-for="c in cardsActives" :key="c.id+'active'" class="flex" 
               :class="{'captain-card':c.type == 'captain','gold-card':c.type == 'gold','daily-card':c.type == 'daily'}">
            <div class="content-card flex flex-column">
              <div class="title-card">
                <span>{{ c.type }}</span>
                {{ $t('cards.card') }}
              </div>
              <div class="price-card"> 
                {{ interpolate($t("cards.points"),[cardsPrices.points[c.type].correct]) }} 
                <span> {{ $t("cards.per_one_card") }} </span></div>
              <div class="active" :class="{'active-black':c.type == 'gold'}">  {{ $t("cards.active") }}</div>
              <div v-for="(cc,index) in cards" :key="index" >
                <span class="dot" v-if="cc.type == c.type+'-card'"> {{ cc.qty }} </span>
              </div>
            </div>
          </div>
        </div>

        <!-- buy cards -->
        <div class="flex flex-column align-self-center align-items-center justify-content-center" v-if="this.subPageActive == 'buy'">
          <div v-for="cb in cardsToBuy" :key="cb.id+'buy'" class="flex" 
               :class="{'captain-card':cb.type == 'captain','gold-card':cb.type == 'gold','daily-card':cb.type == 'daily'}">
            <div class="content-card flex flex-column">
              <div class="title-card">
                <span>{{ cb.type }}</span>
                {{ $t('cards.card') }}
              </div>
              <div class="price-card"> 
                {{ interpolate($t("cards.points"),[cardsPrices.points[cb.type].correct])  }} 
                <span> {{ $t("cards.per_one_card") }} </span>
              </div>
              <div v-for="(ccc,index) in cards" :key="index" >
                <span class="dot dot-buy" v-if="ccc.type == cb.type+'-card'"> {{ ccc.qty }} </span>
              </div>
              <div v-if="cb.type=='gold'" class="buy-gold-card" @click="buyCards('gold')"> {{ $t("cards.buy_card") }} </div>
              <div v-else class="buy-captain-card" @click="buyCards('captain')"> {{ $t("cards.buy_card") }} </div>
            </div>
          </div>
        </div>

        <!-- history -->
        <div class="flex flex-column align-content-center justify-content-center h-full gap-2" v-if="this.subPageActive == 'history'">
          <div v-for="(h,index) in history" :key="index" class="flex align-self-center history-card" >
            <div class="flex">
              <img class="card" :src="require('@/assets/'+h.type+'_small.svg')" alt=""  />
            </div>
            <div class="flex flex-column justify-content-center w-full">
              <div class="flex align-content-start align-items-start justify-content-between card-padding">
                <div v-if="h.type=='captain-card'" class="text-captain-card-history"> 
                  {{ $t("cards.captain") }} 
                  <span class="color-white"> {{ $t("cards.card") }} </span> 
                </div>
                <div v-if="h.type=='gold-card'" class="text-captain-card-history"> 
                  {{ $t("cards.gold") }} 
                  <span class="color-white"> {{ $t("cards.card") }} </span> 
                </div>
                <div v-if="h.type=='daily-card'" class="text-daily-card-history"> 
                  {{ $t("cards.daily") }} 
                  <span class="color-white"> {{ $t("cards.card") }} </span> 
                </div>
                <div class="text-date-history"> {{ h.dt_history | dateComplete }} </div>
              </div>
              <div class="flex align-content-start align-items-start justify-content-between w-full card-padding">
                <div class="text-game-history teste"> 
                  {{  h['hteam_'+ language] ? h['hteam_'+ language] +' '+ $t("cards.vs") +' '+ h['ateam_'+ language] : h['quiz_'+ language]}} 
                </div>
                <div class="text-points-history"> {{ h.points ? h.points : 0 }} {{ $t("cards.pts") }} </div>
              </div>
            </div>
          </div>
        </div>
        <div id="scroll-sentinel"></div>

      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import service from "../service";
import utils from "@/utils";

export default {
  name: "CardsComponent",
  mixins: [utils],
  components: {
  },
  data() {
    return {
      subPageActive: 'active',
      cards: [],
      cardsActives: [
        {
          "id": 1,
          "type": "captain",
          "price": "captain_price"
        },
        {
          "id": 2,
          "type": "gold",
          "price": "gold_price"
        },
        {
          "id": 3,
          "type": "daily",
          "price": "dayly_price"
        },
      ],
      cardsToBuy: [
        {
          "id": 1,
          "type": "captain",
          "price": "captain_price"
        },
        {
          "id": 2,
          "type": "gold",
          "price": "gold_price"
        }
      ],
      prices: {},
      history: [],
      page: 1,
      pageSize: 10
    }
  },
  methods: {
    setActiveSubPage(page) {
      this.subPageActive = page;
    },

    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },

    async getUserAsync() {
      let response = await service.user.getUser();
      if (response && response.status === 200) {
        this.cards = response.data.cards
      }
    },

    async getUserCardHistoryAsync() {
      let response = await service.user.getUserCardHistory(this.page, this.pageSize);
      
      if (response && response.status === 200) {
        if(response.data.result.length > 0){
          if(this.history.length > 0){
            response.data.result.forEach(e => {
                this.history.push(e);
              });
          }else{
            this.history = response.data.result;
          }
        }else{
          this.page -= 1
          this.page <= 0 ? this.page = 1 : this.page
        }
      }
    },

    async getCardsActives(){
      let response = await service.cards.cardsActives()
      if (response && response.status == 200){
        store.cardsPrices.points.daily = response.data.filter(c => c.type == 'daily-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.gold = response.data.filter(c => c.type == 'gold-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.captain = response.data.filter(c => c.type == 'captain-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];


        // this.cardsActives = response.data
        // response.data.forEach(e => {
        //   if(e.type != 'daily-card')
        //     this.cardsToBuy.push(e)
        // });

        // response.data.forEach(e => {
        //   if(e.type == 'daily-card')
        //     this.prices.daily = e.correct
        //   else if(e.type == 'gold-card')
        //     this.prices.gold = e.correct
        //   else if(e.type == 'captain-card')
        //     this.prices.captain = e.correct
        // });

        
      }
    },

    buyCards(type){
      store.cardsPrices.currentType = type
      store.layout.showModalBuyCards = true;
      store.layout.showAccountFooter = false;
    }
    
  },
  computed: {
    language() {
      return i18n.locale;
    },

    cardsPrices(){
      return store.cardsPrices;
    }
  },
  async mounted() {
    await this.getCardsActives();
    await this.getUserAsync();
    await this.getUserCardHistoryAsync();
    
    const observer = new IntersectionObserver( async (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
            this.page += 1
            await this.getUserCardHistoryAsync()
        }
      }
    });

    const scrollSentinel = document.querySelector("#scroll-sentinel");
    observer.observe(scrollSentinel);
   
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.footerItem = 'cards';
    store.layout.headerTitle = this.$t('cards.title');
  },
};
</script>

<style scoped>

#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}

@media (max-width: 600px) {
  .teste{
    text-align: left;
    width: 60%;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 600px) {
  .teste{
    text-align: left;
    width: 60%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}
.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
}

.sub-menu-item-left{
  border-radius: 7px 0px;
}

.sub-menu-item-rigth{
  border-radius: 0px 7px;
}

.captain-card{
  background-image: url('../assets/captain_card.png');
  background-repeat: no-repeat;
  width:330px;
  height:135px;
  margin-bottom: 20px;
}

.gold-card{
  background-image: url('../assets/gold_card.png');
  background-repeat: no-repeat;
  width:330px;
  height:135px;
  margin-bottom: 20px;
}

.daily-card{
  background-image: url('../assets/daily_card.png');
  background-repeat: no-repeat;
  width:330px;
  height:135px;
  margin-bottom: 20px;
}
.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #FFFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: calc(330px - 55px);
  top: -100px;
  padding-top: 2px;
  font-weight: 700;
  font-size: 15px;
  color: #191B22;
}

.dot-buy {
  top: -62px !important;
}
.active{
  font-weight: 700;
  font-size: 12px;
  color: #FFCD00;
  right: 8px;
  margin-top: 20px
}
.active-black{
  color: #191B22 !important;
}
.buy-captain-card{
  background: #FFFFFF;
  border-radius: 11px;
  height: 35px;
  font-weight: 700;
  font-size: 12px;
  color: #0057B7;
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.buy-gold-card{
  background: #FFFFFF;
  border-radius: 11px;
  height: 35px;
  font-weight: 700;
  font-size: 12px;
  color: #FFCD00;
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.history-card{
  height: 65px;
  width: 95%;
  background-color: #282E3D;
  border-radius: 16px;
}
.history-card .card{
  margin-left: -10px;
  width: 81px;
  height: 86px;
}

.history-card .gold-card{
  width: 80px;
  height: 80px;
}


.text-captain-card-history{
  font-weight: 700;
  font-size: 14px;
  color: #FFCD00;
}

.text-daily-card-history{
  font-weight: 700;
  font-size: 14px;
  color: #B0B1CA;
}

.color-white{
  color: #FFFFFF;
}
.text-date-history{
  font-weight: 300;
  font-size: 12px;
  color: #FFFFFF;
}
.text-game-history{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
}
.text-points-history{
  width: 20%;
  font-weight: 400;
  font-size: 12px;
  color: #FFCD00;
}

.card-padding{
  padding-left: 10px;
  padding-right: 10px;
}

.title-card {
    font-size: 24px;
    text-align: left;
    text-transform: capitalize;
    font-family: 'Poppins-SemiBold';
  }

  .title-card > span {
    margin-right: 4px;
  }
  .captain-card .title-card > span {
    color: #FFCD00;
  }

  .gold-card .title-card > span {
    color: #191B22;
  }

  .daily-card .title-card > span {
    color: #191B22;
  }

  .price-card {
    font-family: 'Poppins-SemiBold';
    font-size: 15px;
    line-height: 12px;
    white-space: nowrap;
    margin-top:5px;
  }

  .captain-card .price-card > span {
    color: #FFCD00;
  }

  .gold-card .price-card > span {
    color: #191B22;
  }

  .daily-card .price-card > span {
    color: #191B22;
  }

  .content-card {
    color: #FFFFFF;
    align-items: start;
    padding: 15px 0px 18px 18px;
  }

</style>
