<template>
    <div class="content-page">
      <div class="flex flex-column align-content-start justify-content-start flex-wrap content-terms" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">
        <div class="flex align-self-center about_card">
        </div>
        <div class="flex align-items-start title">
          {{ $t("terms.title") }}
        </div>
        <div v-if="language == 'en'">
          <div class="flex flex-column align-items-start align-content-start text">
            <p>
              Captain Korek Service -Terms & Conditions 
            </p>

            <p class="m-1">
              1.       Service Description
            </p>

            <p class="m-2"> 
              1.1.     The “Captain Korek” Service is a subscription service exclusively for Korek Mobile Phone subscribers, renewed every day, which provides 
            sports live feeds to users (web & SMS based). Moreover, it will consist of a web game in which users will play and compete to win weekly cash prizes, 
            telco prize, by correctly predicting the outcome of international football matches. Moreover, subscribers may participate in the premium Trivia game of 
            sports themed questions to increase their points for the prizes. Participant, apart from the weekly top scorer prizes, enter weekly draws, & the Grand Prize 
            draw for cash prizes. <br>

              1.2.     The Competition “Captain Korek” will be offered for 365 days 
            </p>

            <p class="m-1">
              2.       Terms of Use 
            </p>
            
            <p class="m-2">

              2.1.	All participants warrant that they have read and fully understand these Terms and Conditions which are on public view at cashchallenge.korektel.com.	<br>
              2.2.	By registering to the Subscription Service by sending an SMS to 3230 or online via the website https://captainkorek.korektel.com, the participants signal 
              their complete acceptance and full understanding of these Terms and Conditions and agree: <br>
              2.2.1.	 To receive SMS communication from the Organizer on their mobile phones relating to the “Captain Korek” competition; <br>
              2.2.2.	 To receive SMS communication/advertising offers from the Organizer or from other companies that cooperate with the Organizer. <br>
              2.3.	The Organizer reserves the right to cancel or amend the offer and these Terms and Conditions at any time. Any changes will e posted 
              online at https://captainkorek.korektel.com. <br>
              2.4.	In the event of dispute regarding the Terms and Conditions, conduct, results, and all other matters relating to the Competition, the decision of the 
              Organizer shall be final and no correspondence or discussion shall be entered into. <br>
              2.5.	This Agreement will remain in full force and effect while the Organizer provides the offer (not taking into account temporary suspensions of the offer 
              for operational reasons, e.g. for repairs, planned maintenance or upgrades). <br>
            </p>

            <p class="m-1">
              3.       Participation in the service: 
            </p>
        
            <p class="m-2">
              3.1.	The following are eligible to participate: 
            </p>

            <p class="m-3">
              3.1.1.	Natural persons (other than people employed by the Organizer, or its partners, for the period of the Competition, and also their relatives up to 4th degree 
              and spouses), who are mobile subscribers of Korek Telecom and have acquired at least 1 chance during the period of the Competition. If the winner is a minor, then at 
              the delivery date of delivering the prize, the winner’s guardian must appear to collect the prize having his/her ID as well as a certification proving that he/she is 
              the guardian. <br>
              3.1.2.	Legal entities (e.g. in the case of corporate-owned Mobile Subscriber Numbers of all Operators involved in this Campaign). Only an authorized signatory on 
              behalf of the legal entity will be entitled to take receipt of the prize. <br>
              3.1.3.	The Organizer reserves the right to disqualify any entrant if it has reasonable grounds to believe the entrant has breached any provision of the Terms and 
              Conditions. <br>
              3.1.4.	By participating in the Competition, entrants hereby warrant that all information submitted is true, current and complete. <br>
            </p>

            <p class="m-1">
              4.       Service Mechanics 
            </p>

            <p class="m-2">
              4.1.     The user receives an invitation to subscribe through the means of advertising (SMS) through the service numbers 3230 & digital advertisement with the support 
              of Korek. <br>
              4.2.     The user subscribes by <br>
            </p>

            <p class="m-3">
              4.2.1.  Sending an SMS to the service number 3230. <br>
              4.2.2.  Visiting the service website https://captainkorek.korektel.com and following the registration process. <br>
            </p>

            <p class="m-2">
              4.3.     When the subscription process is successful, the user receives a free welcome message through the service number 3230   informing the user of the duration 
              of the free period (one day), the cost of subscription, the method of leaving the service, in addition to a unique link to the web-game to have access to the news 
              feeds and the contests. The user will also receive one (one) free sport themed SMS content. <br>
              4.4.     After the end of the free trial day (2nd day onwards), the subscription will automatically be converted to "Paid" at a cost of 300 IQD per day and will 
              be executed through Direct Billing. <br>
              4.5.     Once the billing process is successful, the user receives the following: <br>
            </p>

            <p class="m-3">
              4.5.1.  Unique link to the web-game, to have access to the news feeds and the contests. 
            </p>

            <p class="m-2">
              4.6.     In the event that there is not enough credit to renew the subscription, the user does not receive any subscription from the system. The subscription fee 
              is not deducted from the days when the subscriber has no balance. Only at the days that there is available balance, the subscription fee will be deducted, and the 
              subscriber will be informed of the cost. <br>
              4.7. Once the participant enters the Competition he/she will receive the first chances for the prizes and a welcome message with a question and at least 2 possible 
              answers (one answer is correct and the other(s) wrong). <br>
              4.8.	For each correct answer to the question the participant receives at least chances depending on the card types, which are added up during the Competition. 
              Wrong/Invalid answers give chances depending on the cards. The chances for each correct answer to the question will be added in two modes: <br>
            </p>

            <p class="m-3">
              4.8.1.	Basic Mode: In basic mode, the participant receives chances for each correct answer and chances for wrong or invalid answers depending on the card type they 
              use. <br> 
              
              4.8.2.	Bonus Phases: There will be various bonus phases where the participant receives more chances than the ones awarded in the Basic Mode. These phases can last 
              from some minutes. 
            </p>

            <p class="m-1">
              5.       Availability And ease of access on the information, And the mechanism of opt out 
            </p>

            <p class="m-2">
              5.1.     All service information is available to subscribers on service website: https://captainkorek.korektel.com. <br>
              5.2.     Information on the number of points (chances) generated by subscribers during the service will be sent via SMS automatically as part of the dialogue 
              between subscribers and the platform. Subscribers can also see the points (chances) at any time in the portal. <br>
              5.3.     Each participant can exit the service by sending the opt-out keyword STOP in a message on the interactive service number 3230. To stop receiving promotional 
              material, the participants by sending the keyword 0 to 3230. <br>
              5.4.     The user can re-subscribe by sending the specified code back to the interactive number 3230 and has the right to re-activate the points (chances) previously 
              filled in the service. <br>
              5.5.     In case of termination, the organizer will announce its termination on the service website https://captainkorek.korektel.com. <br>
              5.6.     During the campaign, the organizer is entitled to add or cancel any of the interactive numbers without prior notice. If you cancel any interactive number, 
              the points of all subscribers who subscribed to the canceled interactive numbers will be saved. <br>
              5.7.     Discontinuity or early termination shall not exempt the Organizing Entity from its obligation to deliver the prizes already won by the Participants and 
              to execute the other required procedures. Except in cases where interruption or termination of service results from events beyond the control of the organizer. <br>
              5.8.     The organizer of the service shall not release any personal information of the participants to any other party unless required by law or to comply with 
              government bodies in Iraq. <br>
              5.9.     The organizer reserves the right not to engage in written negotiations or any other type of communication with the subscribers except in accordance with 
              the terms and conditions or in accordance with the requirements of the current legislation Iraq. <br>
            </p>

            <p class="m-1">
              6.       Subscription Charging 
            </p>

            <p class="m-2">
              6.1.     To subscribe to the service, the user must send the subscription code via SMS to the interactive service number 3230. <br>
              6.2.     Captain Korek members will receive daily SMS with the unique link to access the daily news feeds and the daily contests to win the prizes through the top 
              scorers’ mechanism, after deducting the cost of the service, which is 300 IQD. <br>
            </p>

            <p class="m-1">
              7.       Terms of awards 
            </p>

            <p class="m-2">
              7.1.     Weekly Top Scorer Prizes
            </p>

            <p class="m-3">
              7.1.1.  There are two types of weekly prizes.
            </p>

            <p class="m-4">
              7.1.1.1.  The first (1) top scorer will win 300,000 IQD <br>
              7.1.1.2.  2 Winners will win 300,000 IQD by draw weekly <br>
              7.1.1.3.   Seven winners will win telco prize (balance credit) of 25,000 IQD value by draw <br>
              7.1.1.4.   Grand prize for 2 winners is 25,000,000 each one by draw <br>
            </p>

            <p class="m-3">
              7.1.2.  Weekly prize periods are defined as starting from 00:00:00 on Sunday and ending at 23:59:59 of the Saturday. <br>
              7.1.3.	The record consists of entry sets corresponding to the total chances, earned by each participant until the end of each Trimester’s eligibility period at 
              23:59:59. During the Competition each chance earned for the answers is assigned to a unique for each participant pool. For example, 10 chances received for a correct 
              answer entitle the corresponding participant (MSISDN) to 10 entries; 500 chances - to 500 entries/raffles for the drawing of the prize. Each draw will determine 1 winner. <br>
              7.1.4.	The winner is notified by Korek Telecom via phone call to their subscriber number. The winner will receive notice within 10 business days from the date of the 
              nomination day. The name or its initials of the winners may be announced via Korek Telecom and/or via SMS to all the participants included in the record. <br>
            </p>

            <p class="m-2">
              7.2.     Winning Conditions 
            </p>

            <p class="m-3">
              7.2.1.  A single subscriber number (MSISDN) cannot win the same type prize more than once. <br>
              7.2.2.  Formation of the subscriber register throughout the service period will begin at 00:00:01 Erbil time from the first day of service and ends at 23:59:59 
              Erbil time from the last day of service. <br>
              7.2.3.  The winner will be notified by telephone of the number that has been subscribed. The winner will receive a notice within 10 working days of the nomination date. 
              The name or initials of winners may be announced at the dedicated web page at https://captainkorek.korektel.com. <br>
              7.2.4.  The winner of the weekly prize can win any additional prize during the service period, the organizer may also announce that additional prizes will be awarded to 
              the participants through the top scorer mechanism & draws. <br>
              7.2.5.  All participants who participated in the same period shall be eligible for this award. <br>
              7.2.6.  The winner may be awarded the equivalent of the additional prize in cash. <br>
            </p>

            <p class="m-2">
              7.3.     Prize Delivery 
            </p>

            <p class="m-3">
              7.3.1.  All prizes shall be delivered within 170 days from the date of termination of the competition. <br>
              7.3.2.  The winner is responsible for paying taxes and customs due to the award under any relevant current legislation. <br>
              7.3.3.  The winners will be contacted by the organizer. Winners must prove in writing that they are the legal owner of the winning number, that they 
              are entitled to win the prize and all necessary documentation provided by the organizer must be completed. All winners must submit identification documents 
              in order to receive the prize. The prize may be received by a representative of the winner, based on the legal authority of the winner. <br>
              7.3.4.  By subscribing to the service, the winners agree to enable the organizer to use any of their information, including name, photos, and video images for 
              promotional purposes without the prior consent of the winner and without any fees. <br>
              7.3.5.  The Organizer will select up to 10 winners as an extension. If the first winner rejects or does not receive the prize, the organizer has the right to 
              contact one of the winners to receive the prize. If no winner receives the prize within 10 days of notification, the prize will be the property of the organizer. <br>
              7.3.6.  Decisions taken by the Organizer shall be final and no negotiations shall be entered into. <br>
              7.3.7.  The prizes are non-transferable. <br>
            </p>

            <p class="m-1">
              8.       The limits of responsibilities 
            </p>

            <p class="m-2">
              8.1.     The Organizer shall not be liable for any error, omission, interruption, defect, delay in operation or transmission, or failure in the line of communications 
              when not under its complete control, for example, and not limited to <br>
              8.2.     The Organizer reserves the right to temporarily suspend the service whenever necessary for operational reasons (e.g. repair, maintenance or update). 
              The organizer undertakes to return the service as soon as possible after any temporary suspension. <br>
              8.3.	The Organizer advises participants to use caution and common sense when participating in the Competition at all times. <br>
            </p>

            <p class="m-1">
              9.       Disclaimer of Warranties 
            </p>

            <p class="m-2">
              9.1.     The Participants understand and agree that their participation in this Service is at their own risk and in their decision and they are not obliged to 
              participate in this service in any way. <br>
              9.2.     The Organizer does not guarantee, express or express that any part of the service will be uninterrupted or error free. <br>
              9.3.     Neither the Organizer nor any of its directors, employees, agents or suppliers accept any responsibility for any damage, loss, injury, or disappointment 
              suffered by any of the service participants or by their acceptance of the award. <br>
              9.4.     The Organizer is not responsible for any problems or technical malfunction of any networks or telephone lines, computer systems on the Internet, service 
              providers or computers, software malfunctions for any email or input received due to technical malfunction or congestion on the Internet, telephone lines Or any Web 
              site, or any combination thereof, including any injury or damage to the Participant's computer, computer or telephone of any related person as a result of the 
              subscription or download of any materials for the Contest. <br>
              9.5.     By subscribing to the Service, the Participants agree that no claim for loss or injury (including special, indirect, or consequential loss) will be made 
              by the Parent Company, subsidiaries, directors, employees or agents from any and all losses, Injuries, rights or procedures of any kind resulting from the acceptance 
              or use of any award, including without exception, personal injury, death, and damage to property. <br>
              9.6.       Any information regarding sports results, live scores, betting odds, sports updates, statistics or any other information published through the Services, is 
              provided and published by different third parties, and not on the behalf of Captain Korek or any of its affiliates or representatives. Please note that such information 
              does not constitute an advice, recommendation nor an authorized opinion, and does not replace the necessity of consulting with a professional to the extent necessary. 
              For the avoidance of doubt, Captain Korek shall not be liable for any mistakes, errors, or inaccuracy in the information published through the Services.
              You hereby exempt Captain Korek from any liability for such mistakes, errors, or inaccuracy and/or for any damages whatsoever caused to you due to your reliance on any 
              information displayed through the Services. <br>
              9.7.      Furthermore, Captain Korek shall not be liable for the Content or the credibility of any advertising displayed in the Applications, and such advertisements do 
              not constitute encouragement or recommendation by Captain Korek for the purchase of the advertised services or products. <br>
              9.8. Please note, that the Services may provide for links to other websites, applications or add-ons maintained by third parties, which may not be governed by these 
              Terms, and Captain Korek shall not be responsible for the compatibility or nature of such links or their content. <br>
            </p>

            <p class="m-1">
              10.    Data and the protection of publicity
            </p>

            <p class="m-2">
              10.1. Personal data relating to audio, image, name, address or other relevant information will be stored in the Organization's Depository System as a Data Control System and may be used for participation in the Service, postal advertising or any other 
              purpose relating to the Services of the Organizer, unless otherwise indicated. <br>
              10.2. Participants agree that if they win the prize, their names and part of the telephone numbers they subscribe to will be posted for informational purposes only at https://captainkorek.korektel.com. <br>
            </p>

            <p class="m-1">
              11.    Governing Law 
            </p>

            <p class="m-2">
            11.1. In the event of any dispute, the participants may submit their complaint to the organizer, and the case will be considered in accordance with the laws in force in Iraq. 
            </p>
            
            <p class="m-1">
              12.    Stores Involvement 
            </p>

            <p class="m-2">
              12.1. Apple is not involved in any way with the contest or sweepstakes.  
            </p>

            <p class="m-1">
              13. Intellectual Property Infringement Claims by Third Parties 
            </p>

            <p class="m-2">
              13.1. If you believe there is content linked through the Services that violates copyright law, please notify us, and we may remove or prevent access to the allegedly infringing material following receipt from you of all of the following 
              information: 
            </p>

            <p class="m-2">
              13.1.1.a description of the copyrighted work that you claim has been infringed; <br>
              13.1.2.a description of where the material that you claim is infringing is located; <br>
              13.1.3. your address, telephone number, and e-mail address; <br>
              13.1.4.a statement under penalty of perjury that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law and that the information you are submitting is accurate; 5. 
              an electronic or physical signature of the owner of the person authorized to act on behalf of the owner of the copyright interest. <br>
              13.2. You may submit your Notification of Copyright Infringement (“Notification”) to Captain Korek. By e-mail to: info@tornet.co. Please be aware that anyone submitting a Notification who makes misrepresentations concerning 
              copyright infringement may be liable for damages incurred to captain korek of such Notification. <br>
            </p>
          </div>
        </div>
        <div v-if="language == 'ar'">
          <div class="flex flex-column align-items-start align-content-start text text-right" dir="rtl">
            <p>
              الشروط والأحكام 
            </p>

            <p class="m-1">
              1.       شرح الخدمة
            </p>

            <p class="m-2"> 
              1.1.  خدمة "كابتن كورك " هي خدمة اشتراك حصرية لمشتركي كورك، يتم تجديدها كل يوم، والتي تقدم محتوى رياضي حي للمستخدمين (تعتمد على الويب بالإضافة الى انها توفر لعبة على شبكة الإنترنت يلعب فيها المستخدمون ويتنافسون للفوز بجوائز نقدية أسبوعية، وجائزة اتصالات، من خلال التخمين بشكل صحيح لنتائج مباريات كرة القدم الدولية  لزيادة مجموع نقاطهم لفرصة ربح الجوائز. يحصل المشترك بغض النظر عن الجوائز الأسبوعية لصاحب اعلى رصيد على فرصة دخول السحبات الأسبوعية والسحب على الجائزة الكبرى للجوائز النقدية. <br>

              1.2.  مسابقة كابتن كورك ستبدأ في 01 / 08 / 2023.

              1.3.  عند الاشتراك في مسابقة "كابتن كورك" يحصل المستخدم على يوم مجاني تجريبي واحد بعد ذلك يتم اسقتطاع 300 دينار عراقي يوميا.
            </p>

            <p class="m-1">
              2.    قبول شروط الاستخدام:
            </p>
            
            <p class="m-2">

              2.1.	يضمن جميع المشتركين بأنهم قد قرأوا وفهموا تماما هذه الشروط والأحكام وهي متاحة للجميع https://captainkorek.korektel.com 	<br>
              2.2.	عبر إرسال رسالة نصية قصيرة على الرقم 3232 أو التسجيل في خدمة الاشتراك عبر إرسال رسالة نصية الى الرمز التفاعلي الى 3230. أو عبر الموقع التالي: https://captainkorek.korektel.com يقر جميع المشتركين قبولهم التام وفهمهم لهذه الشروط والأحكام، ويوافقون على:
              <br>
              2.2.1.	 تلقي رسائل نصية قصيرة متعلقة بمسابقة "كابتن كورك" من الجهة المنظمة على هواتفهم المحمولة. <br>
              2.2.2.	 تلقي رسائل أو عروض إعلانية عبر الرسائل النصية القصيرة من الجهة المنظمة  أو أي شركة أخرى تتعامل مع الجهة المنظمة. <br>
              2.3.	الجهة المنظمة تحتفظ بحق إلغاء أو تعديل هذا العرض وهذه الشروط والأحكام في أي وقت. في حالة أي تغيير، سيتم نشره على https://captainkorek.korektel.com  <br>
              2.4.	في حالة نشوب نزاع بشأن الشروط والأحكام، السلوك، النتائج، وجميع المسائل الأخرى المتعلقة بالمسابقة، يعد قرار الجهة المنظمة نهائيا ولا يجوز النقاش فيه. <br>
              2.5.	تعد هذه الاتفاقية سارية المفعول والتأثير طالما هذا العرض ساري من قبل الجهة المنظمة (دون الاخذ في الاعتبار أي تعليق مؤقت للعرض لأسباب تشغيلية. على سبيل المثال للإصلاحات، الصيانة المنظمة أو التحديث) <br>
            </p>

            <p class="m-1">
              3.   المشاركون في المسابقة:
            </p>
        
            <p class="m-2">
              3.1.	المؤهلون للاشتراك: 
            </p>

            <p class="m-3">
              3.1.1.	الأشخاص الطبيعيون (بخلاف الموظفين من قبل الجهة المنظمة، أو أي من شركاء الجهة المنظمة، خلال فترة المسابقة، وأيضا أقاربهم حتى الدرجة الرابعة و أزواجهم) من مشتركي  شركة كورك تيليكوم خلال فترة المسابقة. إذا كان الفائز قاصر، يجب على الوصي عليه أن يكون حاضرا في يوم تسليم الجائزة لاستلامها على أن يكون معه بطاقة هويته وشهادة تثبت أنه الوصي. <br>
              3.1.2.	الكيانات القانونية (على سبيل المثال المشتركون في خدمات الهواتف المحمولة المملوكة من قبل شركات بأي من شركات الاتصالات المشاركة في هذه الحملة) يحق فقط للأشخاص المفوضين بالتوقيع بالنيابة عن الكيانات القانونية باستلام الجائزة. <br>
              3.1.3.	يحق للجهة المنظمة أن تستبعد أي مشارك إذا كانت هناك أسباب وجيهة للاعتقاد بأن المشارك قد أخل بأي بند من الشروط والأحكام.  <br>
              3.1.4.	من خلال المشاركة في المسابقة، يضمن المشاركين بأن جميع المعلومات المقدمة صحيحة، سارية، وكاملة.  <br>
            </p>

            <p class="m-1">
              4. 	آليات الخدمة
            </p>

            <p class="m-2">
              4.1.     يتلقى المستخدم دعوة للاشتراك من خلال الوسائل الإعلانية (SMS) من خلال خدمة أرقام 3230 و3232 والإعلان الرقمي بدعم من كورك. <br>
              4.2.     يمكن للمستخدم الاشتراك عبر: <br>
            </p>

            <p class="m-3">
              4.2.1.  ارسال رسالة نصية قصيرة الى الرقم 3230 <br>
              4.2.2. من خلال زيارة موقع الخدمة https://captainkorek.korektel.com وإتباع خطوات التسجيل الموجودة هناك. <br>
            </p>

            <p class="m-2">
              4.3.   عند نجاح عملية الاشتراك، يتلقى المستخدم رسالة ترحيب مجانية من رقم الخدمة 3230 لإعلام المستخدم بمدة الفترة المجانية (يوم واحد)، وتكلفة الاشتراك، وطريقة مغادرة الخدمة، بالإضافة إلى رابط لعبة الويب للوصول إلى آخر الأخبار والمعلومات. سيتلقى المستخدم أيضًا محتوى واحد مجاني على شكل رسالة نصية يحمل موضوعًا رياضيًا. <br>
              4.4.   بعد انتهاء اليوم التجريبي المجاني (اليوم الثاني فصاعدًا)، سيتم تحويل الاشتراك تلقائيًا إلى "مدفوع" بتكلفة 300 دينار عراقي في اليوم وسيتم تنفيذه من خلال الفوترة المباشرة. <br>
              4.5.   بمجرد نجاح عملية الفوترة، يتلقى المستخدم ما يلي: <br>
            </p>

            <p class="m-3">
              4.5.1.  رابط لعبة الويب، للوصول إلى الأخبار والمسابقات.
            </p>

            <p class="m-2">
              4.6.   في حالة عدم وجود رصيد كافٍ لتجديد الاشتراك، لا يتلقى المستخدم أي اشتراك من النظام. لا يتم خصم رسوم الاشتراك من الأيام التي لا يوجد فيها رصيد للمشترك. فقط في الأيام التي يتوفر فيها رصيد، سيتم خصم رسوم الاشتراك، وسيتم إبلاغ المشترك بالتكلفة. <br>
              4.7. عند الاشتراك بالمسابقة سيتلقى المشارك فرصه الأولى للجوائز ورسالة ترحيب تحتوي على سؤال وإجابتين على الاقل (إجابة واحدة صحيحة وباقي الإجابات خاطئة (يجيب المشارك على السؤال عن طريق إرسال رسالة قصيرة تحتوي على الإجابة الصحيحة (الإجابات ممثلة برقم) على الرقم التفاعلي 3232. <br>
              4.8.	لكل إجابة صحيحة، يحصل المشترك على  فرص اعتمادا على أنواع البطاقات والتي تجمع خلال المسابقة. الإجابة الخاطئة تعطي فرص اعتمادا على أنواع البطاقات. وستضاف النقاط لكل إجابة صحيحة على السؤال الوارد في وضعين: <br>
            </p>

            <p class="m-3">
              4.8.1.	الوضع الأساسي: ويحصل المشارك في الوضع الأساسي على فرص لكل إجابة صحيحة، وفرص لكل إجابة خاطئة اعتمادا على أنواع البطاقات <br> 
              
              4.8.2.	مراحل المكافأة: ستكون هناك عدة مراحل مكافآت حيث يحصل المشارك على فرص أكثر من تلك التي يحصل عليها في  
                الوضع الأساسي. مدة هذه المراحل قد تمتد لعدة دقائق.
            </p>

            <p class="m-1">
              5.   توافر وسهولة الحصول على المعلومات، وآلية الانسحاب
            </p>

            <p class="m-2">
              5.1.     جميع المعلومات الخاصة بالمسابقة متاحة لمشاركي المسابقة على موقع  https://captainkorek.korektel.com <br>
              5.2.     المعلومات الخاصة بعدد الفرص (المشاركات/ الفرص) التي يحرزها المشاركون خلال المسابقة سترسل للمشتركين عبر رسائل نصية قصيرة تلقائيا كجزء من الحوار بين المتسابقين ولعبة الأسئلة في الرابط <br>
              5.3.     يستطيع كل مشارك الخروج من المسابقة أو وقف تلقي أي معلومات عن المسابقة عبر إرسال كلمة "قف" في رسالة نصية على الرقم 3230. لعدم استلام المواد الترويجية، يمكن للمشاركين إرسال 0 إلى 3230. <br>
              5.4.     - إذا اختار المشارك إعادة إرسال الرمز المحدد على الرقم 3230 سيكون له الحق في إعادة تفعيل النقاط (الفرص) التي حصل عليها سابقا في الخدمة. <br>
              5.5.     في حال انتهاء المسابقة ستقوم الجهة المنظمة بإعلان انتهاء المسابقة على الموقع الرسمي الخاص بالمسابقة وهوhttps://captainkorek.korektel.com <br>
              5.6.     خلال الحملة، يحق للمنظم إضافة أو إلغاء أي من الأرقام التفاعلية دون إشعار مسبق. إذا قمت بإلغاء أي رقم تفاعلي، فسيتم حفظ نقاط جميع المشتركين الذين اشتركوا في الأرقام التفاعلية الملغاة. <br>
              5.7.     الانقطاع أو الإنهاء المبكر للمسابقة لا يعفي الجهة المنظمة من التزامها بتسليم الجوائز التي فاز بها متسابقون بالفعل وتنفيذ الإجراءات الأخرى المطلوبة. تستثنى من ذلك الحالات التي يكون فيها الانقطاع أو انتهاء المسابقة ناتجا عن أحداث خارجة عن سيطرة الجهة المنظمة للمسابقة. <br>
              5.8.     - لا يحق للجهة المنظمة للمسابقة الإفصاح عن أي معلومات شخصية خاصة بالمشتركين لأي طرف آخر إلا في حال طلب منها ذلك بموجب القانون أو للامتثال للهيئات الحكومية للعراق.  <br>
              5.9.     تحتفظ الجهة المنظمة بالحق في عدم الدخول في مفاوضات مكتوبة أو أي نوع آخر من الاتصال مع المشاركين في المسابقة وذلك إلا لما يتوافق مع الأحكام والشروط أو وفقا لمتطلبات التشريعات الحالية لدولة العراق.  <br>
            </p>

            <p class="m-1">
              6.     تكلفة الاشتراك
            </p>

            <p class="m-2">
              6.1.     للاشتراك في الخدمة، يجب على المستخدم إرسال رمز الاشتراك عبر رسالة نصية قصيرة إلى رقم الخدمة التفاعلية 3230. <br>
              6.2.     سيتلقى مشتركي كابتن كورك رسالة نصية يومية تحتوي على رابط للوصول إلى الأخبار اليومية والمسابقات اليومية للفوز بالجوائز من خلال آلية صاحب أعلى رصيد، بعد خصم تكلفة الخدمة وهي 300 دينار عراقي. <br>
            </p>

            <p class="m-1">
              7.     شروط الجوائز
            </p>

            <p class="m-2">
              7.1.    الجوائز الاسبوعية لصاحب اعلى رصيد
            </p>

            <p class="m-3">
              7.1.1.   يوجد نوعان من الجوائز الاسبوعية:
            </p>

            <p class="m-4">
              7.1.1.1.  صاحب اعلى رصيد الاول يحصل على 300,000 دينار عراقي  <br>
              7.1.1.2.  رابحين اثنين عن طريق السحبة يحصلون على 300,000 دينار عراقي لكل واحد منهما <br>
              7.1.1.3.  سبعة رابحين عن طريق السحبة يحصلون على رصيد للاتصال بقيمة 25,000 دينار عراقي. <br>
            </p>

            <p class="m-3">
              7.1.2.  - يتم اختيار الفائزين بالجوائز الأسبوعية من خلال آلية صاحب الرصيد الاعلى. <br>
              7.1.3.	Tيتم تحديد فترات الجوائز الأسبوعية بداية من 00:00:00 يوم الاحد وتنتهي في 23:59:59 من يوم السبت. <br>
              7.1.4.	سيتم إعلام الفائز عن طريق كورك تيليكوم عبر مكالمة هاتفية عل الرقم الذي تم الاشتراك به. سيحصل الفائز على إشعار خلال 10 أيام عمل من تاريخ الترشيح. قد يتم الإعلان عن اسم أو الأحرف الأولى من اسم الفائزين، عبر كورك تيليكوم أو عبر رسائل نصية قصيرة لجميع المشتركين في السجل. <br>
            </p>

            <p class="m-2">
              7.2.   شروط الفائزين:
            </p>

            <p class="m-3">
              7.2.1.  لا يمكن لرقم مشترك واحد (MSISDN) أن يربح الجائزة نفسها أكثر من مرة واحدة خلال المسابقة. <br>
              7.2.2.  تشكيل سجل المشتركين طوال فترة المسابقة سيبدأ عند الساعة 00:00:01 من اليوم الأول للمسابقة وينتهي عند الساعة23:59:59 من اليوم الأخير للمسابقة. <br>
              7.2.3.  سيتم إعلام الفائز عن طريق كورك تيليكوم عبر مكالمة هاتفية عل الرقم الذي تم الاشتراك به. سيحصل الفائز على إشعار خلال 10 أيام عمل من تاريخ الترشيح. قد يتم الإعلان عن اسم أو الأحرف الأولى من اسم الفائزين، عبر موقع الخدمة https://captainkorek.korektel.com. <br>
              7.2.4.  يمكن للفائز بالجائزة الأسبوعية الفوز بأي جائزة إضافية خلال فترة الخدمة، وقد يعلن المنظم أيضًا أنه سيتم منح جوائز إضافية للمشاركين من خلال آلية وسحب الفائزين. <br>
              7.2.5.  سيكون جميع المشاركين الذين شاركوا في نفس الفترة مؤهلين للحصول على هذه الجائزة. <br>
            </p>

            <p class="m-2">
              7.3.    تسليم الجائزة:
            </p>

            <p class="m-3">
              7.3.1.  سيتم تسليم جميع الجوائز خلال مدة لا تتجاوز 170 يوم من تاريخ انتهاء المسابقة. <br>
              7.3.2.  الفائز هو المسؤول عن دفع الضرائب والجمارك المستحقة للجائزة بموجب أي تشريعات حالية ذات صلة. <br>
              7.3.3.  سيتم الاتصال بالفائزين من قبل الجهة المنظمة. يجب على الفائزين أن يثبتوا خطيا بأنهم المالك القانوني للرقم الفائز، وبأنه يحق لهم الفوز بالجائزة ويجب استكمال جميع الوثائق اللازمة التي يوفرها المنظم. يجب على جميع الفائزين تقديم وثائق الهوية من أجل استلام الجائزة. قد يتم استلام الجائزة من قبل ممثل للفائز وذلك بناء على تأكيد خطي من الفائز. <br>
              7.3.4.  عبر الاشتراك في المسابقة، يوافق الفائزون على تمكين كورك تيليكوم من استعمال أي من معلوماتهم، بما في ذلك اسم العائلة، صور، وصور الفيديو على كورك تيليكوم أو على أي وسط آخر وفي أي دولة لأغراض دعائية بدون موافقة مسبقة من الفائز وبدون أي رسوم. <br>
              7.3.5.  المنظم سيختار ما يصل إلى 10 فائزين، وإذا رفض الفائز الجائزة أو لم يستلمها، يحق للمنظم بأن يتصل بأحد الوصفاء ليستلموا الجائزة. إذا لم يستلم أي من الوصفاء الجائزة في خلال ال 10 أيام من إعلامهم، ستعد الجائزة ملكا للمنظم. <br>
              7.3.6.  القرارات التي يتخذها المنظم نهائية ولن يتم الدخول في أي مفاوضات. الجوائز غير قابلة للتحويل. <br>
              7.3.7.  الجوائز غير قابلة للتحويل <br>
            </p>

            <p class="m-1">
              8.      حدود المسؤولية
            </p>

            <p class="m-2">
              8.1.     الجهة المنظمة لا تتحمل أي مسؤولية عن أي خطأ، إغفال، انقطاع، عيب، تأخير في التشغيل أو الإرسال، أو فشل في خط الاتصالات عندما لا يكون تحت سيطرتها تماما وذلك على سبيل المثال وليس الحصر. <br>
              8.2.     تحتفظ الجهة المنظمة بحق تعليق المسابقة مؤقتا كلما دعت الحاجة لأسباب تشغيلية (على سبيل المثال للإصلاح، لإجراء الصيانة أو التحديث). تتعهد الجهة المنظمة بإعادة المسابقة في أقرب وقت ممكن بعد أي تعليق مؤقت. <br>
              8.3.	   تنصح الجهة المنظمة المشتركين بتوخي الحذر واتباع المنطق خلال الاشتراك في المسابقة في جميع الأوقات. <br>
            </p>

            <p class="m-1">
              9.       إخلاء المسؤولية عن الضمانات
            </p>

            <p class="m-2">
              9.1.     يفهم المشاركون ويوافقون على أن مشاركتهم في هذه المسابقة هي على مسؤوليتهم الخاصة وبقرارهم ولم يتم إجبارهم على المشاركة في هذه المسابقة بأي طريقة. <br>
              9.2.     الجهة المنظمة لا تضمن، ولم تصرح أو تعرب عن أن أي جزء من المسابقة سيكون بدون انقطاع أو بدون أخطاء.  <br>
              9.3.     لا تقبل الجهة المنظمة أو أي من مدراءها، موظفيها، وكلاءها، أو مورديها، تحمل أي مسئولية عن أي ضرر، خسارة، إصابة، أو خيبة أمل يعاني منها أي من المشتركين في المسابقة أو من جراء قبولهم الجائزة.   <br>
              9.4.     - الجهة المنظمة ليست مسؤولة عن أي مشاكل أو خلل فني لأي من شبكات أو خطوط الهاتف، أنظمة الكمبيوتر على الأنترنت، مقدمي الخدمات، أو أجهزة الكمبيوتر، أعطال البرامج لأي بريد إلكتروني أو إدخال يتم استلامه بسبب خلل فني أو ازدحام على شبكة الأنترنت، خطوط الهاتف أو أي موقع إلكتروني، أو أي مزيج منها، بما في ذلك أي إصابة أو ضرر لكمبيوتر المشارك أو كمبيوتر أو هاتف أي شخص ذو صلة من جراء الاشتراك أو تحميل أي مواد خاصة بالمسابقة.  <br>
              9.5.     عبر الاشتراك في المسابقة، يوافق المشتركون على ألا تتم أي مطالبة تتعلق بالخسارة أو الإصابة (بما في ذلك خسائر خاصة، غير مباشرة، أو تبعية) من الجهة المنظمة، الشركات الأم، الشركات التابعة، المدراء، الموظفين، أو الوكلاء من أي وكل الخسائر، الإصابات، الحقوق أو الإجراءات من أي نوع ناجمة عن قبول أو استعمال أي جائزة، بما في ذلك وبدون استثناء، الإصابات الشخصية، الموت، والأضرار في الممتلكات. <br>
            </p>

            <p class="m-1">
              10.    حماية البيانات والدعاية
            </p>

            <p class="m-2">
              10.1. سيتم حفظ البيانات الشخصية المتعلقة بالصوت والصورة، الاسم، العنوان أو أي معلومات أخرى ذات صلة في نظام إيداع تابع للجهة المنظمة كنظام تحكم بالبيانات، ويمكن استخدامها للمشاركة بالمسابقة، والدعاية البريدية، أو أي غرض آخر يتعلق بخدمات الجهة المنظمة، ما لم يذكر خلاف ذلك.   <br>
              10.2. يوافق المشاركون على أنه في حال فازوا بالجائزة، سيتم نشر أسمائهم وجزء من أرقام الهواتف التي اشتركوا بها لأغراض إعلامية فقط على موقع الخدمة https://captainkorek.korektel.com <br>
            </p>

            <p class="m-1">
              11.    القانون الحاكم
            </p>

            <p class="m-2">
            11.1. في حال نشوب أي خلاف، يمكن للمشاركين تقديم شكواهم إلى الجهة المنظمة، وستنظر القضية وفقا لقوانين العراق.
            </p>
            
            <p class="m-1">
              12.    مشاركة المتاجر
            </p>

            <p class="m-2">
              12.1. لا تشارك App Store  بأي شكل من الأشكال في المسابقة أو السحبات.
            </p>
          </div>
        </div>
        <div v-if="language == 'ku'">
          <div class="flex flex-column align-items-start align-content-start text text-right">
            <p>
              خزمەتگوزاریی کاپتن كورەك - بەند و مەرجەکان
            </p>

            <p class="m-1">
              1.             وەسفی خزمەتگوزاری
            </p>

            <p class="m-2"> 
              1.1.          خزمەتگوزاریی "کاپتن کورەك" خزمەتگوزارییەکی بەشداریکردنی تایبەتە بۆ بەشداربووانی مۆبایلی كورەك، هەموو ڕۆژێک نوێ دەبێتەوە، وە هەواڵی ڕاستەوخۆ سەبارەت بە وەرزشەکان بۆ بەکارهێنەران دابین دەکات (لەسەر ئینتەرنێت ). هەروەها، یارییەکی ئینتەرنێتی تێدایە کە بەکارهێنەران دەتوانن لە ڕێگەیەوە یاریی و پێشبڕکێ بکەن بۆ بردنەوەی خەڵاتی کاشی هەفتانە، خەڵاتی تێلیکۆم، ئەویش بە پێشبینیکردنی دروستی یارییە نێودەوڵەتییەکانی تۆپیپێ. هەروەها، بەشداربووان دەتوانن بەشداریی لە یاریی رسیار و وەڵامی تایبەت بە وەرزش بکەن بۆ ئەوەی خاڵەکانیان بۆ بەدەستهێنانی خەڵاتەکان زیاتر بکەن. بەشداربووان، جگە لە خەڵاتەکانی یەکەمەکانی هەفتە، ناویان دەچێتە تیروپشکی هەفتانە،و تیروپشکی خەڵاتی گەورە. <br>

              1.2.         پێشبڕکێی "کاپتن كورەك" لە 01/08/2023 دەکەوێتە کار.
            </p>

            <p class="m-1">
              2.           بەندەکانی بەکارهێنان
            </p>
            
            <p class="m-2">

              2.1.	هەموو بەشداربووان گەرەنتی ئەوە دەدەن ئەم مەرج و رێنماییانەیان بە وردی خوێندۆتەوە و لێی تێگەیشتوون، مەرج و رێنماییەکان بۆ هەمووان بەردەستن لە cashchallenge.korektel.com.	<br>
              2.2.	بە ناردنی کورتەنامەیەک بۆ کورتەژمارەی 3232 یان خۆتۆمارکردن لە خزمەتگوزاریی بەشداریکردن بە ناردنی کورتەنامە بۆ کورتەژمارەی 3230 یان بە ئۆنلاین لە ڕێگەی ماڵپەڕی  https://captainkorek.korektel.com،  بەشداربووان پشتڕاستی دەکەنەوە ڕازین بە بەند و مەرجەکان و بەتەواوەتی لێی تێدەگەن، وە ڕەزامەندن بە: <br>
              2.2.1.	 وەرگرتنی کورتەنامەی پەیوەندی لە ڕێکخەرەوە بۆ مۆبایلەکانیان سەبارەت بە پێشبڕکێی "کاپتن كورەك"؛<br>
              2.2.2.	 وەرگرتنی کورتەنامەی پەیوەندی/ئۆفەری ڕیکلام لە ڕێکخەر یان ئەو کۆمپانیانەی کار لەگەڵ ڕێکخەرەکە دەکەن. <br>
              2.3.	ڕءکخەرەکە مافی ئەوە بۆ خۆی گلدەداتەوە کە  هەر کاتێک بیەوێت ئۆفەرەکە و ئەم مەرج  و رێسانە هەڵبوەشێنێتەوە یان گۆڕانکاریان تێدا بکات. هەر گۆڕانکاریەک کە ئەنجامبدرێت لە ئۆنلاین لە رێگەی  https://captainkorek.korektel.com بڵاودەکرێتەوە. <br>
              2.4.	لە حاڵەی سکاڵا دەربارەی مەرج و رێساکان، بەڕێوەبردن، ئەنجام و هەموو تەوەرەکانی پەیوەست بە پێشبڕکێکە، بڕیاری ڕێکخەرەکە کۆتاییە و  نابێت هیچ  گفتوگۆ و تێهەڵچوونەوەیەکی بۆ بکرێت. <br>
              2.5.	ئەم ڕێکەوتننامە دەبێت کاریگەر بن و لە بواری جێبەجێکردندا بن بەدرێژایی ئەو ماوەی ڕێکخەرەکە ئۆفەرەکە دابین دەکات (بەبێ ئەژمارکردنی هەڵپەساردنە کاتیەکانی ئۆفەرەکە یان هۆکارێکی تەکنیکی، وەک چاکردنەوە و بەرزکردنەوە و نوێگەریی بەرنامە بۆدانراو). <br>
            </p>

            <p class="m-1">
              3.        بەشداریکردن لە خزمەتگوزاریەکە:
            </p>
        
            <p class="m-2">
              3.1.	ئەمانەی خوارەوە شمولی بەشداریکردن دەکەن: 
            </p>

            <p class="m-3">
              3.1.1.	هەر کەسێکی سروشتی (جگە لەو کەسانەی لە لایەن ڕێکخەرەکە، یان هاوبەشەکانیان، لە ماوەی پێشبڕکێکە، دامەزرێنراون، و خزمەکانیان تا پلەی 4 و هاوسەرەکانیان)، کە بەشداربووی مۆبایلی كورەك تێلیکۆم-ن و لە ماوەی پێشبڕکێکەدا بەلایەنی کەم 1 چانسیان بەدەستهێناوە. ئەگەر کەسی براوە خوار تەمەنی یاسایی بێت، لە ڕێکەوتی پێدانی خەڵاتەکە دەبێت سەرپەرشتیاری براوەکە بێت بۆ وەرگرتنی خەڵاتەکە و ناسنامە و بەڵگەی سەلاماندنی سەرپەرشتیاریی ئەو لەگەڵ خۆی بهێنێت. <br>
              3.1.2.	دامەزراوە یاساییەکان (نم. ئەگەر دامەزراوە یاساییەکە خاوەنی ئەو ژمارە یان ژمارانە بوون کە هەڵمەتەکەدا بەشدار بوون). تەنها یەک کەسایەتی یان پۆستی رێگەپێدراو دەتوانێت بە ناوی دامەزراوەکەوە خەڵات وەربگرێت. <br>
              3.1.3.	ڕێکخەرەکە ئەو مافە بۆ خۆی گلدەداتەوە هەر بەشداربوویەک دەربکات ئەگەر لەسەر بنەمای ماقوڵ بڕوای وابێت بەشداربووەکە هەریەک لە بڕگەکانی بەند و مەرجەکانی پێشێل کردوە. <br>
              3.1.4.	بە بەشداریکردن لە پێشبڕکێکە، بەشداربوو زامنی ئەوە دەدات لە هەموو زانیاریە دراوەکان راست و نوێ و کامڵن. <br>
            </p>

            <p class="m-1">
              4.         شێوازەکانی خزمەتگوزاری
            </p>

            <p class="m-2">
              4.1.    بەکارهێنەر بانگهێشتنامەیەکی پێدەگات بۆ بەشداریکردن لە ڕێگەی ئامرازەکانی کورتەنامەی ڕیکلام لە ڕێگەی ژمارەکانی 3230 و 3232، هەروەها ڕیکلامی دیجیتاڵیی بە پاڵپشتیی كورەك. <br>
              4.2.       بەکارهێنەر بەشداری دەکات بە <br>
            </p>

            <p class="m-3">
              4.2.1.  ناردنی کورتەنامە بۆ ژمارەی خزمەتگوزاریی 3230. <br>
              4.2.2. سەردانکردنی ماڵپەڕی خزمەتگوزاریەکە https://captainkorek.korektel.com  و جێبەجێکردنی پرۆسەی خۆتۆمارکردن. <br>
            </p>

            <p class="m-2">
              4.3.    کە پرۆسەی بەشداریکردن سەرکەوتوو بوو، بەکارهێنەر نامەیەکی بەخێرهاتنی بێبەرامبەریی لە ڕێگەی ژمارەی خزمەتگوزاریی 320 پێ دەگات و بەکارهێنەر لە ماوەی بێبەرامبەر (یەک ڕۆژ)، تێچووی بەشداریکردن، شێوازی جێهێشتنی خزمەتگوزاریەکە ئاگادار دەکاتەوە، هەروەها بەستەرێکی ناوازە بۆ یاریی ئینتەرنێتی بۆ ئەوەی دەستی بە هەواڵەکان و پێشبڕکێکان بگات. بەکارهێنەر هەروەیا یەک (یەک) کورتەنامەی پەیوەندیدار بە وەرزشی پێ دەگات. <br>
              4.4.       دوای کۆتاییهاتنی ڕۆژی تاقیکردنەوەی بێبەرامبەر (لە ڕۆژی دووەمەوە)، شێوازی بەشداریکردن "بەپارە"ـیە و نرخی 300 دیناری عێراقییە بۆ هەر ڕۆژێک و لە ڕێگەی تێچوونی ڕاستەوخۆ وەردەگیرێت. <br>
              4.5.     کە پرۆسەی پارە بڕین سەرەکەوتوو بوو، بەکارهێنەر ئەمەی خوارەوەی پێ دەگات: <br>
            </p>

            <p class="m-3">
              4.5.1. بەستەرێکی ناوازە بۆ یاریی ئینتەرنێت، بۆ ئەوەی دەستی بە هەواڵ و پێشبڕکێکان بگات. 
            </p>

            <p class="m-2">
              4.6.     .     لە حاڵەتێکدا، بڕی پێویست یەکە نەبوو بۆ نوێکردنەوەی بەشداریکردن، بەکارهێنەرەکە هیچ بەشداریکردنێک لە سیستەمەکەوە وەرناگرێت. تێچووی بەشداریکردنی ئەو ڕۆژانەی بەشداربووەکە باڵانسی پێ نەبووە لە بەکارهێنەر نابڕدرێت. تەنها لەو ڕۆژانەی باڵانس هەیە تێچووی بەشداریکردن دەبڕدرێت، وە بەشداربوو لە تێچووەکە ئاگادار دەکرێتەوە. <br>
              4.7. لەگەڵ داخڵبوونی بەشداربووەکە بە پێشبڕکێکە،  یەکەم چانسەکان و نامەی بەخێرهاتنی لەگەڵ پرسیارێک پێ دەگات لە بە لایەنەی کەم دوو وەڵامی لەگەڵدایە (یەک وەڵام ڕاستە و وەڵامەک(ان)ـەی دیکە هەڵەیە. بەشداربوو بە ناردنی کورتەنامەی وەڵامی راست (کە وەڵامێکی ژمارەیی دەبێت) بۆ کۆدی ژمارەیی 3232 وەڵامی پرسیارەکان دەداتەوە. <br>
              4.8.	بۆ هەر وەڵامێکی ڕاست بۆ پرسیارەکە، بەشداربوو چانس وەردەگرێت، وە لە ماوەی پێشبڕکێکەدا بۆی کۆدەکرێتەوە. وەڵامی هەڵە/نادروست چانس دەدەن. چانسەکانی هەر وەڵامێکی ڕاستی پرسیارەکان بە دوو دۆخ کۆدەکرێنەوە بەپێی جۆری کارتەکان <br>
            </p>

            <p class="m-3">
              4.8.1.	دۆخی بنەڕەتی: لە دۆخی بنەڕەتیدا، بەشداربوو چانس وەردەگرێت بۆ هەر وەڵامێکی ڕاست  چانس وەردەگرێت بۆ هەر وەڵامێکی هەڵە یان نادروست. بەپێی جۆری کارتەکان <br> 
              
              4.8.2.	قۆناغی بەخشش: چەندین قۆناغی جۆراوجۆی بەخشش هەن کە تیایدا بەشداربوو چانسی زیاتر لەوانەی دۆخی بنەڕەتی وەردەگرێت. ئەم قۆناغانە دەکرێت تا چەند خولەکێک درێژە بکێشن. 
            </p>

            <p class="m-1">
              5.           بەردەستی و وەستانی دەستگەیشتن بە زانیاریەکان، وە میکانیزمی کشانەوە
            </p>

            <p class="m-2">
              5.1.         هەموو زانیاریەکانی خزمەتگوزاریەکە بۆ بەشداربووان لەسەر ماڵپەڕی خزمەتگوزاریەکە بەردەستە: https://captainkorek.korektel.com. <br>
              5.2.    زانیاریەکان لەسەر ژمارەی خاڵەکان (چانسەکان) کە لە ماوەی خزمەتگوزاریەکە لە لایەن بەشداربووەکە کۆکراونەتەوە لە ڕێگەی کورتەنامەوە خودکارانە وەک بەشێک لە ئاڵوگۆڕی پەیوەندیی نێوان بەشداربووان و سەکۆکە دەنێردرێت بۆ بەشداربوو و دەتوانی لە ڕێگەی لینکەکە بیبینیت <br>
              5.3.       هەر بەشداربوویەک دەتوانێت لە خزمەتگوزارییەکە بچێتە دەرەوە بە ناردنی کلیلە وشەی کشانەوەی "STOP" بە نامەیەک بۆ ژمارەی خزمەتگوزاریی 3230. بۆ ئەوەی چیی دیکە کەرەستەی بەرەودانت پێ نەگات، بەشداربووان دەتوانن کلیلەوشەی 0 بنێرن بۆ 3230. <br>
              5.4.     بەکارهێنەر دەتوانێت بەشداریی لە خزمەتگوزارییەکە بکاتەوە بە ناردنی کۆدی دیاریکراو بۆ ژمارەی 3230، وە مافی ئەوەی هەیە ئەو خاڵانە (چانسانە) کارا بکاتەوە کە پێشتر کۆی کردوونەتەوە و لە خزمەتگوزاریەکە تۆمار کراون. <br>
              5.5.     لە حاڵەتی کۆتایی پێهێنان، ڕێکخەرەکە کۆتایی پێهێنانەکە لەسەر ماڵپەڕی خزمەتگوزاریەکە https://captainkorek.korektel.com ڕادەگەیەنێت.<br>
              5.6.     لە ماوەی هەڵمەتەکەدا، ڕێکخەرەکە مافی ئەوەی هەیە هەر ژمارەیەکی ئاڵوگۆری زیاد بکات یان پوچەڵی بکاتەوە بەبێ ئاگادارکردنەوەی پێشوەخت. ئەگەر هەر ژمارەیەکی ئاڵوگۆڕکاری پوچەڵ بکەیتەوە، هەموو خاڵەکانی هەموو بەشداربووان کە بەشدارییان لەو ژمارە ئاڵوگۆڕکارییە پوچەڵکراوە کردبوو خەزن دەکرێت.<br>
              5.7.     بچڕان یان کۆتایی پێهێنانی زوو دامەزراوەی ڕێکخەرەکە لە ئەرکەکانی نابوورێت بۆ پێدانی ئەو خەڵاتانەی پێشتر براونەتەوە لە لایەن بەشداربووان و جێبەجێکردنی ڕێکارە داواکراوەکان. مەگەر لە حاڵەتێکدا نەبێت بچڕان یان کۆتایی پێهێنانی خزمەتگوزاریەکە لە ئەنجامی ڕووداوگەلێک بن لە دەرەوەی کۆنتڕۆڵی ڕێکخەرەکە. <br>
              5.8.       ڕێکخەرەکە نابێت هیچ زانیارییەکی کەسیی بەشداربووان بۆ  هەر لایەنێکی سێهەم ئاشکرا بکات مەگەر بەیاسا داواکراو بێت یان پابەندبوون بێت بە داواکانی لایەنە حکومیەکانی عێراق. <br>
              5.9.     ڕێکخەرەکە ئەو مافە بۆ خۆی گلدەداتەوە کە بەشداریی لە هیچ مامەڵەیەکی نووسراو یان هیچ جۆرە ئاڵوگۆڕێکی پەیوەندی لەگەڵ بەشداربوان نەکات مەگەر بەپێی بەند و مەرجەکان یان بەپێی داواکاریە یاساییە هەنووکەییەکانی عێراق نەبێت. <br>
            </p>

            <p class="m-1">
              6.     تێچووەکانی بەشداریکردن
            </p>

            <p class="m-2">
              6.1.    بۆ بەشداریکردن لە خزمەتگوزارییەکە، بەشداربوو دەبێت کۆدی بەشداریکردن لە ڕێگەی کورتەنامە بنێرێت بۆ ژمارەی ئاڵوگۆڕکاریی خزمەتگوزاریی 3230. <br>
              6.2.     ئەندامانی کاپتن كورەك کورتەنامەی ڕۆژانەیان بە بەستەری ناوازە پێدەگات بۆ دەستگەیشتن بە هەواڵ و پێشبڕکێکانی ڕۆژانە بۆ بردنەوەی خەڵات لە ڕێگەی میکانیزمی ئەوانەی زۆرترین خاڵیان هەیە، ئەویش دوای داشکاندنی تێچووی خزمەتگوزاریەکە، کە 300 دیناری عێراقییە. <br>
            </p>

            <p class="m-1">
              7.        بەندەکانی خەڵات
            </p>

            <p class="m-2">
              7.1.      خەڵاتەکانی هەفتانەی خاوەن زۆرترین خاڵ
            </p>

            <p class="m-3">
              7.1.1. دوو جۆر خەڵاتی هەفتانە هەن.
            </p>

            <p class="m-4">
              7.1.1.1.  یەکەم (1) خاوەنی زۆرترین خاڵ 300,000 د.ع کاش دەباتەوە <br>
              7.1.1.2.  دوو براوە 300,000 د.ع کاش دەبەنەوە بە ڕێگەی تیرووپشک <br>
              7.1.1.3.    حەوت براوە (یەکەی باڵانس) بە بەهای 25,000 د.ع دەبەنەوە بە ڕێگەی تیرووپشک <br>
              7.1.1.4.    دوو براوە خەڵاتی گەورە دەبەنەوە کە بریتیە لە 25،000،000 دیناری عیڕاقی بۆ هەر یەکێکیان بە ڕێگەی تیرووپشک <br>
            </p>

            <p class="m-3">
              7.1.2.	ماوەکانی خەڵاتەکانی هەفتانە لە 00:00:00 لە ڕۆژی یەکشەممە دەستپێدەکات و لە 23:59:59ـی ڕۆژی شەممە کۆتایی دێت. <br>
            </p>

            <p class="m-2">
              7.2.      مەرجەکانی بردنەوە
            </p>

            <p class="m-3">
              7.2.1.  یەک ژمارەی بەشداربوو (MSISDN) ناتوانێت زیاتر لە جارێک هەمان جۆری خەڵات بباتەوە. <br>
              7.2.2. پێکهاتنی تۆماری بەشداربووان بە درێژایی ماوەی خزمەتگوزارییەکە لە 00:00:01 بە کاتی هەولێر لە یەکەم ڕۆژی خزمەتگوزارییەکە دەستپێدەکات و لە 23:59:59 بە کاتی هەولێر لە کۆتا ڕۆژی خزمەتگوزارییەکە کۆتایی دێت. <br>
              7.2.3.  براوەکە بە هەمان ئەو ژمارەی بەشداریی خزمەتگوزارییەکەی کردوە پەیوەندیی تەلەفۆنیی پێوە دەکرێت. کەسی براوە لە ماوەی 10 رۆژ لە ڕێکەوتی هەڵبژاردن ئاگادار دەکرێنەوە. ناو یان پیتی یەکەمی ناوی براوەکان لە پەڕەیەکی تایبەتی https://captainkorek.korektel.com ڕادەگەیەنرێن. <br>
              7.2.4.  براوەی خەڵاتی هەفتانە دەتوانێت لە ماوەی خزمەتگوزاریەکە هەر خەڵاتێکی دیکە بباتەوە، ڕێکخەرەکە ڕەنگە ڕایبگەیەنێت کە خەڵاتی زیادە لە ڕێگەی میکانیزمی خاوەنی زۆرترین خاڵ و تیروپشک دەدرێن بە بەشداربووان. <br>
              7.2.5.  هەموو ئەو بەشداربووانەی لە هەمان ماوە بەشدارییان کردوە دەبێت شمولی ئەم خەڵاتە بکەن. <br>
              7.2.6.  براوەکە ڕەنگە بەهای هاوشێوەی خەڵاتە زیادەکەی بە پارەی کاش پێ بدرێت. <br>
            </p>

            <p class="m-2">
              7.3.      پێدانی خەڵاتەکە
            </p>

            <p class="m-3">
              7.3.1.  هەموو خەڵاتەکان لە ماوەی 170 ڕۆژ لە ڕێکەوتی کۆتاییهاتنی پێشبڕکێکە دەدرێن. <br>
              7.3.2.  کەسی براوە بەرپرسە لە دانی باج و گومرگ کە ڕەنگە بەپێی یاسا پەیوەندیدارە هەنووکەییەکان بکەوێتە ئەستۆی. <br>
              7.3.3.  براوەکان لە لایەن ڕێکخەرەکەوە پەیوەندیان پێوە دەکرێت. کەسی براوە دەبێت بە نووسراو بیسەلمێنێت ئەوان خاوەنیی یاسایی ژمارە براوەکەن، وە مافی بردنەوەی خەڵاتەکەیان هەیە و هەموو ئەو پەڕاوە پێویستانەی لە لایەن ڕێکخەرەکەوە پێی دەدرێت پڕی بکاتەوە. هەموو براوەکان دەبێت بەڵگەنامەی ناساندن پێشکەش بکەن بۆ وەرگرتنی خەڵاتەکە. دەکرێت  خەڵاتەکە لە لایەن نوێنەری رێپێدراوی کەسی براوە وەربگیرێن لە کاتی پێدانی نووسراوی پشتڕاستکردنەوە لە لایەن براوەکەوە. <br>
              7.3.4.  بە بەشداریکردن لە خزمەتگوزاریەکە، براوەکان ڕازی دەبن ڕێگە بە ڕێکخەرەکە بدەن بەبێ ڕەزامەندیی پێشوەختی براوەکان و بەبێ هیچ تێچوویەک هەر کام لە زانیاریەکانی براوەکان بەکاربهێنێت، بە لەخۆگرتنی ناو، وێنە و ڤیدیۆ بە مەبەستی برەودان و  بازاڕگەری. <br>
              7.3.5.  Tڕێکخەرەکە وەک جێگرەوە تا 10 براوەکی دیکە هەڵدەبژێرێت. ئەگەر براوەی یەکەم ڕەتی بکاتەوە یان خەڵاتەکە وەرنەگرێت، ڕێکخەرەکە مافی ئەوەی هەیە پەیوەندی بە یەکێک لە براوەکان بکات بۆ وەرگرتنی خەڵاتەکە. ئەگەر لە ماوەی 10 ڕۆژی دوای ئاگادارکردنەوە، براوەکە خەڵاتەکە وەرنەگرێت، خەڵاتەکە دەبێت بە موڵکی ڕێکخەرەکە. <br>
              7.3.6.  ئەو بڕیارانەی لە لایەن ڕێکخەرەکەوە دەدرێن کۆتایین و دەرفەتی گفتوگۆی تێدا نین.. <br>
              7.3.7. خەڵاتەکان ناگوازرێنەوە بۆ کەسێکی دیکە. <br>
            </p>

            <p class="m-1">
              8.         سنوورەکانی بەرپرسیارێتیەکان
            </p>

            <p class="m-2">
              8.1.       ڕێکخەرەکە نابێت بەرپرسیاربێت بۆ هەر هەڵە، پەڕاندن، بچڕان، کەموکوڕی، دواکەوتن لە کارپێکردن یان گواستنەوە، یان شکستهێنان لە ئاڵوگۆڕی پەیوەندی کاتێک بارودۆخەکە لە ژێر کۆنتڕۆڵی تەواوی خۆیدا نەبێت.<br>
              8.2.     ڕێکخەرەکە ئەو مافە بۆ خۆی گلدەداتەوە هەر کاتێک پێویست بوو بۆ مەبەستی کارپێکردن خزمەتگوزاریەکە بەشێوەیەکی کاتی هەڵپەسێرێت (نم. چاککردنەوە، چاکسازی یان نوێکردنەوە) ڕێکخەرەکە بەڵێنی گەڕانەوەی خزمەتگوزاریەکە دەدات بە زووترین کات دوای هەر هەڵپەساردنێکی کاتی. <br>
              8.3.	ڕێکخەرەکە ئامۆژگاریی بەشداربووان دەکات هەموو کاتێک لە پێشبڕکێکەدا ئاگایی و  سەلیقەی خۆیان بەکاربهێنن. <br>
            </p>

            <p class="m-1">
              9.          خۆبواردن لە گەرەنتیەکان
            </p>

            <p class="m-2">
              9.1.     بەشداربووان تێدەگەن و ڕازی دەبن کە بەشدارییان لەم خزمەتگوزارییە بە بڕیاری خۆیانە و مەترسیەکانی لە ئەستۆی خۆیانە، وە بە هیچ شێوەیەک ناچار نین بەشداریی لەم خزمەتگوزارییە بکەن. <br>
              9.2.       ڕێکخەرەکە گەرەنتی ئەوە نادات، چ لاوەکی و چ روونکراوە، کە هەر بەشێکی خزمەتگوزاریەکە بڕینی تێناکەوێت یان کێشەی نابێت. <br>
              9.3.     نە ڕێکخەرەکە و نە هیچ لە بەڕێوەبەرەکانی، کارمەندەکانی، بریکارەکانی یان دابینکەرەکانی بەرپرسیاریی قبوڵ ناکەن بۆ هەر زیان، لەدەستدان، پێکان، یان بێئومێدییەک کە تووشی هەر یەک لە بەشداربووەکان ببێت یان لە قاتی قبوڵکردنی خەڵاتەکە بێتە ئاراوە. <br>
              9.4.       ڕێکخەرەکە بەرپرسیار نیە بۆ هەر کێشەیەک یان گرفتێکی تەکنیکیی هەر تۆڕ یان هێڵێکی تەلەفۆنی، سیستمی کۆمپوتەری لەسەر ئینتەرنێت، دابینکەری خزمەتگوزاری یان کۆمپیوتەرەکان، گرفتی نەرمەواڵە بۆ هەر ئیمێڵ یان هاتوویەک بەهۆی گرفت یان کۆسپێکی ئینتەرنێتی، هێڵی تەلەفۆنی یان هی هەر ماڵپەڕێکی ئینتەرنێت، یان هەر تێکەڵەیەک لەوانە، بە لەخۆگتنی پێکان یان زیان کە بەر کۆمپیوتەر، کۆمپیوتەر، تەلەفۆن یان هەر کەسێکی پەیوەندیدار دەکەوێت لە ئەنجامی بەشداریکردن یان دابەزاندنی هەر کەرەستەیەکی پێشبڕکێکە. <br>
              9.5.    بە بەشداریکردن لە خزمەتگوزارییەکە، بەشداربووان ڕازی دەبن هیچ داواکاریەک پێشکەش ناکەن بۆ هەر لەدەستدان یان پێکان (بە لەخۆگرتنی زیانی تایبەت، ناڕاستەوخۆ یان دەرئەنجامی) دژی کۆمپانیای سەرەکی، لاوەکیەکانی، بەڕێوەبەرەکانی، کارمەندەکانی یان بریکارەکانی بۆ هیچ جۆرە زیان، پێکان، ماف یان ڕێکار لە هەر جۆرێک کە لە ئەنجامی قبوڵکردن یان بەکارهێنانی هەر کام لە خەڵاتەکان دێنە ئاراوە، بە لەخۆگرتنی بێ هەڵاوێردەی پێکانی کەسی، مەرگ، یان زیان بە موڵک. <br>
            </p>

            <p class="m-1">
              10.   داتا و پاراستنی شوناس
            </p>

            <p class="m-2">
              10.1. . داتا کەسییەکان کە پەیوەندیی بە دەنگ، وێنە، ناو، ناونیشان یان زانیارییە پەیوەندیدارەکان هەیە لە سیستمی هەڵگرتنی ڕێکخراوەکە وەک سیستمێکی کۆنتڕۆڵی داتا هەڵدەگیرێن و ڕەنگە بۆ بەشداریکردن لە خزمەتگوزاریەکە، بڵاوکراوەکی ڕیکلامی یان هەر مەبەستێکی دیکە کە پەیوەندیی بە خزمەتگوزارییەکانی ڕێکخەرەکە، مەگەر پێچەوانەکەی ڕوون کرابێتەوە، بەکاردەهێنرێن. <br>
              10.2. بەشداربووان ڕازی دەبن ئەگەر خەڵاتەکە ببەنەوە، ناویان و بەشێک نە ژمارە تەلەفۆنەکانیان کە لە ڕێگەیەوە بەشدارییان پێ کردوە تەنها بۆ مەبەستی زانیاریبەخشین لە  https://captainkorek.korektel.com بڵاو دەکرێنەوە. <br>
            </p>

            <p class="m-1">
              11.    حوکمی یاسا 
            </p>

            <p class="m-2">
            11.1. لە حاڵەتی هەر ناکۆکییەک، بەشداربووان دەتوانن سکاڵاکانیان پێشکەشی ڕێکخەرەکە بکەن، وە دۆزەکەیش بەپێی یاسای کارپێکراوی عێراقی مامەڵەی لەگەڵ دەکرێت. 
            </p>
            
            <p class="m-1">
              12.   . بەشداری کۆگاکان
            </p>

            <p class="m-2">
              12.1.  ئاپ ستۆر بە هیچ شێوەیەک بەشداری لە پێشبڕکێ و بردنەوەکان ناکات.  
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";

export default {
  name: "TermsComponent",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    language() {
      store.layout.headerTitle = this.$t("terms.title")
      return i18n.locale;
    }
  },
  mounted() {
    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("terms.title")
  },
};
</script>

<style scoped>

.content-page {
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}
.content-terms{
  min-height: var(--appHeight);
}
.about_card{
  background-image: url('../assets/terms_card.png');
  background-repeat: no-repeat;
  width:350px;
  height:140px;
  margin-bottom: 20px;
}
.title{
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 20px;
}
.text{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #B0B1CA;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.indent{
  margin-left: 25px;
}

</style>
