<template>
    <div class="content-page">
      <div class="flex flex-column content-leagues">
        <div class="flex flex-row align-self-center search-bar">
          <div class="flex align-items-center justify-content-center search-bar-icon">
            <img :src="require('@/assets/icons/search.png')" alt="" />
          </div>
          <div class="flex  align-items-center search-bar-input">
            <input class="custom-input" type="text" placeholder="Search Match" @input="onInput" v-model="searchString"/>
          </div>
          <div class="flex align-items-center justify-content-center search-bar-icon">
            <img :src="require('@/assets/icons/settings-sliders.png')" alt="" />
          </div>
        </div>
        <div v-for="l in this.leagues" :key="l.id" class="flex align-self-center leagues-card" @click="goToLeague(l)">
          <div class="flex align-items-center justify-content-center leagues-card-icon">
            <div class="flex align-items-center justify-content-center around-img"> 
              <img :src="l.league.logo" alt=""/>
            </div>
          </div>
          <div class="flex flex-column justify-content-center align-items-start leagues-card-name">
            <div class="text-league-name"> {{ l.league.name_en }} </div>
            <div class="sub-text-league-name"> {{ l.description }} </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation"
import { store } from "../store"
import  utils from "../utils"
import service from "../service"

export default {
  name: "LeagueListComponent",
  data() {
    return {
      leagues: [],
      searchString: '',
    }
  },
  methods: {
    onInput(){
      if(this.searchString != ''){
        this.leagues = this.leagues.filter(l => l.description.toLowerCase().includes(this.searchString) || l.league.name_en.toLowerCase().includes(this.searchString))
      }
      else{
        this.leagues = []
        this.leagueList()
      }
    },

    goToLeague(l) {
      let league = { "leagueId": l.leagueId, "year": l.year }
      this.$router.push({ name: 'league' , params: { leagueInfo: league } })
    },

    async leagueList() {
      let response = await service.leagues.seasons()
      if (response && response.status == 200)
      if(response.data.length > 0){
        if(this.leagues.length > 0)
          this.leagues.concat(response.data)
        else
          this.leagues = response.data
      }
    },
  },
  computed: {
    language() {
      return i18n.locale
    }
  },
  async mounted() {
    await this.leagueList()

    store.layout.showHeader = true
    store.layout.showFooter = true
    store.layout.showAccountFooter = false
    store.layout.headerTitle = this.$t('leagues.title')
  },
};
</script>

<style scoped>

#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
}

.content-leagues{
  min-height: var(--appHeight);
  margin-bottom: 80px;
}
.search-bar{
  margin-bottom: 40px;
  margin-top: 10;
  background-color: #282E3D;
  border-radius: 15px;
  width: 95%;
  height: 40px;
}
.search-bar-input{
  width: calc(100% - 80px);
}
.search-bar-icon{
  width: 40px;
}
.custom-input{
  border-width: 0;
  padding: 0px !important;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
  background-color: #282E3D;
  width: 100%;
  height: 100%;
} 
::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

.leagues-card{
  height: 65px;
  width: 95%;
  background-color: #282E3D;
  border-radius: 16px;
  margin-bottom: 10px;
}

.leagues-card-icon{
  width: 70px
}

.leagues-card .around-img{
  width: 45px; 
  height: 45px;
  border: 1px solid #FFFFFF;
  border-radius: 16px;
}

.leagues-card img{
  width: 40px; 
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 16px;
}

.leagues-card-name{
  width: calc(100% - 70px);
  padding-left: 10px;
}

.text-league-name{
  font-weight: 700;
  font-size: 12px;
  color: #FFCD00;
}

.sub-text-league-name{
  font-size: 12px;color: #B0B1CA;
}


</style>
