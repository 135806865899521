<template>
    <div class="content-page">
      <div class="flex flex-column">
        <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
          <div class="flex align-self-center align-items-center justify-content-start sub-menu">
            <div v-for="c in newsCategoryList" :key="c.id" class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item" 
            :class="{'sub-page-active':checkActive(c.id)}" @click="setActiveNewsCategory(c.id)">
              {{ c['name_'+language] }}
            </div>
          </div>
        </div>
      </div>
      <div class="cards-large flex gap-3 ml-4 pr-4">
        <div v-for="f in filteredNewsList" :key="f.id" >
          <div class="flex bg-cover bg-no-repeat" :style="'background-image: url('+f.image_url+');border-radius: 10px;'" @click="goToNewsDetails(f)">
            <div class="content-card-large flex flex-column justify-content-end">
              <div class="flex justify-content-start text-card-large-title"> 
                {{ f.title }}
              </div>
              <div class="flex justify-content-start text-card-large-description"> 
                {{ f.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-self-start align-items-center justify-content-center mt-10 mb-10"> 
        <div class="flex align-self-center align-items-center justify-content-start text-sub-title"> 
          {{ $t('news.hot_news') }}
        </div>
      </div>
      <div class="cards flex gap-3 ml-4 pr-4">
        <div v-for="n in hotNewsList" :key="n.id">
          <div class="flex bg-cover bg-no-repeat" :style="'background-image: url('+n.image_url+'); border-radius: 10px;'" @click="goToNewsDetails(n)">
            <div class="content-card flex flex-column">
            </div>
          </div>
          <div class="flex justify-content-start text-card-title"> 
            {{ n.title }}
          </div>
        </div>
      </div>
      <div class="flex align-self-start align-items-center justify-content-center mt-10 mb-10"> 
        <div class="flex align-self-center align-items-center justify-content-start text-sub-title"> 
          {{ $t('news.lastest_news') }}
        </div>
      </div>
      <div class="flex flex-column align-self-center align-items-center justify-content-center cards-horizontal"> 
        <div v-for="a in allNewsList" :key="a.id" class="flex align-self-center align-items-center justify-content-center mb-20 ml-10" @click="goToNewsDetails(a)"> 
          <div class="wd-85">
            <div class="flex bg-cover bg-no-repeat" :style="'background-image: url('+ a.image_url +');width: 80px; height: 70px; border-radius: 10px;'">
            </div>
          </div>
          <div class="flex flex-column ml-10 he-70"> 
            <div class="text-cards-horizontal-title">{{ a.title }}</div>
            <div class="text-cards-horizontal-description">{{ a.description }}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";
import  utils from "../utils";
import service from "../service";

export default {
  name: "AboutComponent",
  components: {
  },
  data() {
    return {
      newsCategoryIdActive: '',
      newsCategoryList: [],
      filteredNewsList: [],
      allNewsList: [],
      hotNewsList: [],
      page: 0,
      pageSize: 0
    }
  },
  methods: {
    async setActiveNewsCategory(newsCategoryId) {
      this.newsCategoryIdActive = newsCategoryId
      await this.getNewsByCategoryList();
    },

    checkActive(newsCategoryId) {
      return this.newsCategoryIdActive === newsCategoryId ? true : false;
    },

    goToNewsDetails(news) {
      let n = {"news":news};
      utils.methods.goToRouteWithParams('news-details', n)
    },

    async getNewsCategoryList() {
      let response = await service.news.getNewsCategory(this.page, this.pageSize);
        if (response && response.status == 200){
          this.newsCategoryList = response.data.result;
          this.newsCategoryIdActive = this.newsCategoryList[0].id;
      }
    },

    async getNewsByCategoryList() {
      let response = await service.news.getNewsByCategory(this.newsCategoryIdActive, this.language);
        if (response && response.status == 200){
          this.filteredNewsList = response.data;
      }
    },

    async getAllNewsList() {
      let response = await service.news.getAllNews(this.language);
        if (response && response.status == 200){
          this.allNewsList = response.data;
      }
    },

    async getHotNewsList() {
      let response = await service.news.getHotNews(this.language);
        if (response && response.status == 200){
          this.hotNewsList = response.data;
      }
    },
    async loadAllPage(){
      await this.getNewsCategoryList();
      await this.getNewsByCategoryList();
      await this.getAllNewsList();
      await this.getHotNewsList();
    }
  },
  computed: {
    language() {
      store.layout.headerTitle = this.$t("news.title")
      return i18n.locale;
    }
  },
  watch:{ 
    language(newValue, oldValue) { 
      if (newValue) {
        this.loadAllPage()
      }
    }
  },
  async mounted() {
    await this.getNewsCategoryList();
    await this.getNewsByCategoryList();
    await this.getAllNewsList();
    await this.getHotNewsList();

    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("news.title")
  },
};
</script>

<style scoped>

.content-page{
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
}
.content-news{
  min-height: var(--appHeight);
}

.title{
  font-weight: 700;
  font-size: 28px;
  color: #FFFFFF;
}

.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  background-color: #282E3D;
  border-radius: 7px 7px 7px 7px;
  margin-left: 5px;
}
.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}

.cards {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 200px;
  }

  .cards::-webkit-scrollbar {
    display: none;
  }

  .cards > div {
    max-width: 150px;
    max-height: 130px;
    justify-content: space-between;
  }

  .cards-large {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 200px;
  }

  .cards-large::-webkit-scrollbar {
    display: none;
  }

  .cards-large > div {
    max-width: 295px;
    max-height: 200px;
    justify-content: space-between;
  }

  .content-card-large {
    color: #FFFFFF;
    align-items: start;
    padding: 15px 0px 18px 18px;
    width: 295px;
    height: 200px;
  }

  .item-card-captain {
    background-image: url("@/assets/card-home-capitain.png");
  }

  .item-card-gold {
    background-image: url("@/assets/card-home-gold.png");
  }

  .item-card-daily {
    background-image: url("@/assets/card-home-daily.png");
  }

  .content-card {
    color: #FFFFFF;
    align-items: start;
    padding: 15px 0px 18px 18px;
    width: 150px;
    height: 130px;
  }

  .title-card {
    font-size: 20px;
    text-align: left;
    text-transform: capitalize;
    font-family: 'Poppins-SemiBold';
  }

  .title-card > span {
    margin-right: 4px;
  }
  .item-card-captain .title-card > span {
    color: #FFCD00;
  }

  .item-card-gold .title-card > span {
    color: #191B22;
  }

  .item-card-daily .title-card > span {
    color: #191B22;
  }

  .text-sub-title{
    font-weight: 300; 
    size: 12px; 
    color:#B0B1CA;
    width: 95%;
  }
  
  .text-card-large-title{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color:#FFCD00;
    max-height: 40px;
    overflow: hidden;
  }

  .text-card-large-description{
    font-size: 12px;
    text-align: left;
    color:#FFFFFF;
    max-height: 70px;
    margin-top: 10px;
    height: 35px; 
    overflow: hidden;
  }

  .text-card-title{
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color:#FFFFFF;
    max-height: 70px;
    overflow: hidden;
  }

  .text-cards-horizontal-title{
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color:#FFFFFF;
    max-height: 45px;
    overflow: hidden;
  }
  .text-cards-horizontal-description{
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color:#B0B1CA;
    max-height: 35px;
    overflow: hidden;
  }
  .mt-10{
    margin-top: 10px;
  }
  .mt-20{
    margin-top: 30px;
  }

  .mb-10{
    margin-bottom: 10px;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .ml-10{
    margin-left: 10px
  }
  .he-70{
    height: 70px;
  }
  .wd-85{
    width: 85px;
  }
  .cards-horizontal{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 95%;
  }

</style>
