export default {
  general:{
    currency: "د.ع",
    rank: "ڕیزبەندی ",
    quiz: "تاقیکردنەوە",
    coming_soon: "بەم زووانە",
    english: "ئینگلیزی",
    en: "en",
    arabic: "عەرەبی",
    ar: "ar",
    kurdish: "کوردی",
    ku: "ku",
    points: "خاڵەکان"
  },
  error:{
    invalid_phone_number: "ژمارەی مۆبایل هەڵەیە",
    invalid_pin_code: "کۆدی نهێنی نادروستە",
    error: "هەڵە",
    select_answer: "دانەیەك هەڵبژێرە!",
    connexion_error: "هەڵەیەک ڕوویدا لە پەیوەندیکردن!"
  },
  input:{
    phone_number_label: "ژمارەی مۆبایل",
    phone_number_prefix: "+964"
  },
  login: {
    login: "چوونە ژوورەوە",
    lets_get_started: "با دەست پێ بکەین",
    welcome: "بەخێربێی بۆ کاپتن کۆڕەک!",
    first_day: "ڕۆژی یەکەم بەخۆڕاییە، پاشان ڕۆژانە 300 دینارە",
    unsubscribe: "بۆ ڕاگرتنی بەشداریکردن، 0 بنێرە بۆ 3230",
    free_charge: "(بەخۆڕایی)…",
    to_continue: "بۆ بەردەوامبوون",
    successfully_entered: "بە سەرکەوتوویی داخڵ کراوە",
    your_number: "ژمارەکەت",
    subscribe: "بەشداریکردن",
    continue: "بەردەوام بوون",
    re_send_pin: "دووبارە کۆدەکە بنێرە"
  },
  header:{
    title: "کاپتن كۆڕەک"
  },
  home: {
    title:"لاپەڕەی سەرەکی",
    total_points: "کۆی خاڵەکان",
    ranking: "ڕیزبەندی ",
    live: "یاری ڕاستەوخۆ",
    upcoming: "هاتوو",
    past_matches: "یارییەکانی پێشوو",
    view_all: "بینینی هەموو",
    leagues: "خولەکان",
    top_3_matches: "باشترین 3 یاری",
    today: "ئەمڕۆ",
    versus: "VS",
    points_per_card: "# خاڵ بۆ هەر کەرتێک ",
    joined: "بەشداریت کرد",
    join_or_play: "یاری بکە",
    ended: "کۆتایی هات",
  },
  change_language: {
    en: "ئینگلیزی",
    ku: "کوردی",
    ar: "العربية"
  },
  about: {
    title: "دەربارەی"
  },
  terms: {
    title: "مەرج و یاساکان"
  },
  privacy:{
    title: "یاسای پاراستنی نهێنی"
  },
  unsubscribe:{
    title: "ڕاگرتنی بەشداریکردن",
    sub_title_1: "پێمان ناخۆش بو کە بەشداریکردنت ڕاگرت ",
    sub_title_2: "حەز دەکەین بزانین بۆچی بەشداریکردنت ڕاگرت",
    reason_1: "ئەم یارییەم بەدڵ نەبوو",
    reason_2: "نرخی زۆرە",
    reason_3: "کاتم نییە",
    reason_4: "ئاگادارییەکان زۆرن",
    reason_5: "هۆکارەکە لەسەر تۆ نییە",
    unsubscribe: "ڕاگرتنی بەشداریکردن",
    error_reason: "لانی کەم هۆکارێک هەڵبژێرە"
  },
  modal_more:{
    winners: "براوەکان",
    manage_account: "بەڕێوەبردنی هەژمار",
    about: "دەربارەی",
    faq: "پرسیارە باوەکان",
    terms: "مەرج و یاساکان",
    unsubscribe: "ڕاگرتنی بەشداریکردن",
    privacy: "یاسای پاراستنی نهێنی",
    logout: "چوونە دەرەوە"
  },
  cards:{
    sub_menu:{
      active: "کارتی کاراکراو",
      buy: "کڕینی کارتی ",
      history: "ئەرشیفی"
    },
    select_card: "کارتەکە هەڵبژێرە",
    title: 'کارتەکانم',
    daily: "ڕۆژانە",
    captain: "کاپتن",
    card: "کارت",
    gold: "زێڕین",
    vs: "VS",
    pts: "خاڵ",
    active: "چالاک",
    buy_card: "کڕینی کارتی",
    unavailable: "بۆ یاریکردن دەبێت کارتێک بکڕیت",
    daily_unavailable: "بەیانی کارتی ڕۆژانەت بۆ نوێ دەبێتەوە!",
    captain_unavailable: "کاپتن کاردتت نەماوە ئێستا بیانکڕە!",
    gold_unavailable: "گؤڵدن کاردتت نەماوە ئێستا بیانکڕە!",
    captain_price: "بە #240 د.ع",
    gold_price: "بە #480 د.ع",
    dayly_price: "05 کاردی ڕۆژانە بەخۆڕایی!"
 },
  modal_buy_cards:{
    buy_card: "کڕینی کارتی",
    verify_payment: "پشتڕاستکردنەوە",
    please_verify: "تکایە کۆدەکە پشتڕاست بکەرەوە",
    from_number: "لەسەر ژمارەی مۆبایل",
    you_entered: "بە سەرکەوتوویی داخڵ بوویت",
    your_number: "ژمارەکەت",
    purchase_successful: "کڕین سەرکەوتوو بوو",
    go_to_my_cards: "بڕۆ بۆ کارتەکانم",
    submit: "جێبەجێکردن",
    cancel: "هەڵوەشاندنەوە",
    confirm: "دڵنیابوون",
    buying: "کڕین",
    get: "کڕینی",
    cards_by: "کارت تەنها بە",
    iqd: "د.ع",
    for: "بە",
    invalid_pincode: "وشەی نهێنی هەڵە داخڵ کرا",
    recharge: "بە داخەوە، باڵانسی پێوەستت نییە، باڵانست پڕ بکەرەوە و دووبارە هەوڵبدەرەوە",
  },
  leagues:{
    title: "خولەکان"
  },
  league:{
    sub_menu:{
      last_matches: "یاریەکانی ڕابردوو",
      standings: "ڕیزبەندی",
      schedule: "خشتە"
    },
    match_continues: "یارییەکە بەردەوامە",
    match_over: "يارىیەکە کۆتایی هات",
    draw_over: "تیروپشکەکە کۆتایی هات",
    won: "سەرکەوتوو بوو",
    play: "یاری بکە"
  },
  leaders:{
    title: "یەکەمەکان",
    sub_menu:{
      weekly: "ئەم هەفتەیە!",
      monthly: "This month",
      all: "بەگشتی"
    },
    no_named: "بێ ناو"
  },
  news:{
    title: "هەواڵەکان",
    hot_news: "هەواڵەگەرمەکان",
    lastest_news:"نوێترین هەواڵ"
  },
  daily_quiz:{
    title: "تاقیکردنەوەی ڕۆژانە",
    points_earned: "خاڵە بەدەست هێنراوەکان",
    next_question: "پرسیاری داهاتوو",
    submit: "جێبەجێکردن",
    select_quiz_response: "وەڵامێک هەڵبژێرە!"
  },
  winners:{
    title: "براوەکان",
    sub_menu:{
      weekly: "هەفتانە",
      grand: "گەورە"
    },
  },
  joined_match:{
    title: "بەشداری یارییەکە کراوە",
    join_contest: "بەشداری چالێنجەکە بکە",
    super_matches_contest: "پێشبڕکێی یارییە سوپەرەکان",
    amount: "1,075,000",
    iqd_every_week: "د.ع هەفتانە",
    select_card: "کارتێک هەڵبژێرە",
    question: "پرسیارەکە",
    ends_draw: "یەکسان",
    none: "هیچیان",
    both: "هەردووكيان",
    congratulations: "پیرۆزە!",
    completed_questions: "پرسیارەکانت تەواو کردووە",
    points_correct_answers: "بە وەڵامی ڕاست # خاڵ بەدەست دەهێنیت",
    notification_message: "کاتێک یارییەکە تەواو بوو ئاگادارییەکت بۆ دەنێرین. بەهیوای بەختێکی باش!",
    next_question: "پرسیاری داهاتوو",
    submit: "جێبەجێکردن",
    go_home: "بڕۆ بۆ لاپەڕەی سەرەکی",
    red_cards: "کارتی سوور",
    yellow_cards: "کارتی زەرد",
    goals: "گۆڵەکان",
    choose_winner: "براوە هەڵبژێرە",
    correct_answer: "وەڵامی ڕاست",
    wrong_answer: "وەڵامی هەڵە"
  },
  manage_account:{
    title: "به‌ڕێوه‌ بردنی حیساب",
    rank: "ڕیزبەندی",
    matches_played: "ئەو یارییانەی کە بەشداریت تێدا کردووە",
    cards_played: "کارتەکانی بەکارهێنراو",
    favourite_cards: "کارتە دڵخوازەکان",
    overall: "ڕیزبەندی گشتی",
    weekly: "ڕیزبەندی هەفتانە"
  },
  faq:{
    title: "پرسیارە باوەکان",
    sub_menu:{
      general: "بەگشتى",
      cards: "کارتەکان",
      all: "هەموو کاتەکان"
    }
  },
  match_list:{
    title_1:'يارييه كان',
    title_2:'ئەنجامی یارییەکان',
    joined_match:'بەشداری یارییەکە بکە',
    total_points:'کۆی خاڵەکانی بەدەست هێنراو',
    both: 'هەردووكيان',
    search_match: 'یارییەک بدۆزەرەوە',
    sub_menu:{
      live: "ڕاستەوخۆ",
      upcoming: "یاریەکانی داهاتوو",
      past: "یارییە ئەنجامدراوەکان"
    },
  },
  logout:{
    msg: "دڵنیای کە دەتەوێت بچیتە دەرەوە؟ چیتر ناتوانیت لە ڕێگەی نامە و ئاگادارکردنەوە زانیاری وەربگریت. پێویستە جارێکی تر بچیتە ژوورەوە.",
    cancel: "هەڵوەشاندنەوە",
    yes: "بەڵێ"
  },
  news_details:{
    source: "سەرچاوە: ",
    source_url: "لینکی سەرچاوە: "
  }
}