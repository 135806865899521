<template>
  <div v-if="showFooter" class="flex flex-column justify-content-center footer-content" :class="{'footer-content-with-account':showAccountFooter}">
    <div v-if="showAccountFooter" class="flex flex-row align-self-center align-content-center justify-content-between flex-wrap account-leaders-card">
      <div class="flex align-items-center justify-content-center width-20">
        <img class="photo" :src="'https://'+bucket+'.s3.'+region+'.amazonaws.com/'+photoUrl" alt=""/>
      </div>
      <div class="flex flex-column align-items-center justify-content-center width-40">
        <div class="account-leaders-text"> {{ userName }} </div>
        <div class="account-leaders-sub-text" >{{ $t("general.rank") + ' ' + userRanking }} </div>
      </div>
      <div class="flex align-items-center justify-content-center account-leaders-points width-40">
        {{ userPoints }}
      </div>
    </div>
    <div class="flex flex-column align-self-center align-content-between justify-content-between flex-wrap footer-bar">
        <div v-for="menu in this.menuArray" :key="menu" class="flex align-items-center align-content-center justify-content-center item" 
            :class="{'item-active':checkActive(menu)}" @click="setItemActive(menu)"> 
          <img :src="require('@/assets/icons/'+menu+'.png')" alt=""/>
        </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import i18n from "@/translation";

export default {
  name: "FooterComponent",
  data() {
    return {
      menuArray:['home','match-list','cards','daily-quiz','leaders','news'],
      showModalLogout:false,
      userInfo: ''
    };
  },
  methods: {
    setItemActive(item) {
      store.layout.footerItem = item;
      if (this.$route.name != item) this.$router.push(item);
    },
    checkActive(item) {
      return this.activeItem === item ? true : false;
    },
  },
  computed: {
    showFooter() {
      return store.layout.showFooter;
    },
    showAccountFooter() {
      return store.layout.showAccountFooter;
    },
    activeItem() {
      return store.layout.footerItem;
    },
    language() {
      return i18n.locale;
    },
    userName(){
      return store.user.name;
    },
    userRanking(){
      return store.user.currentRanking;
    },
    userPoints(){
      return store.user.currentPoints;
    },
    bucket(){
      return store.AWS_BUCKET;
    },
    region(){
      return store.AWS_DEFAULT_REGION;
    },
    photoUrl(){
      return store.user.photoUrl;
    }
  },
  mounted(){
  }
};
</script>

<style scoped>
.footer-content {
  opacity: .85;
  width: 100%;
  height: 55px;
  position: absolute;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  z-index: 2;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  color: #FFFFFF;
}
.account-leaders-card{
  height: 65px;
  width: 95%;
  background-color: #FFCD00;
  border-radius: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 10.5px rgba(0, 0, 0, 0.443137);
  backdrop-filter: blur(15px);
}
.account-leaders-card img{
  width: 37px;
  height: 37px;
}

.width-20{
  width: 20%;
}

.width-40{
  width: 40%;
}
.photo{
  border-radius: 50%;
}
.footer-content-with-account{
  height: 120px !important;
}

.account-leaders-text{
  font-weight: 700;
  font-size: 12px;
}
.account-leaders-sub-text{
  font-weight: 300;
  font-size: 10px;
}
.account-leaders-points{
  font-weight: 700;
  font-size: 12px;
}
.footer-bar{
  width: 95%;
  height: 55px; 
  background: #0057B7;
  backdrop-filter: blur(14.7685px);
  border-radius: 20px;
  max-width: 650px;
}

.item{
  height: 55px;
  width: calc(95% / 6);
  max-width: 75px;
}
.item-active {
  background-color: rgba(255,255,255,0.2); 
  border-radius: 21px;
}

</style>
