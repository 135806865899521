<template>
  <div class="content-page">
    <div id="prizes-home-div" class="content-login-cards flex gap-3 mr-2">
      <div
        v-for="prize in prizes"
        :key="prize.id"
        class="login-card flex bg-auto bg-no-repeat"
      >
        <img :src="prize['imageUrl_' + language]" />
      </div>
    </div>

    <div
      class="flex flex-column align-content-around justify-content-around content-login ml-4"
    >
      <div class="flex">
        <span class="login">{{ $t("login.login") }}</span>
      </div>
      <div class="flex">
        <div class="flex flex-column">
          <div class="flex">
            <span class="text">{{ $t("login.lets_get_started") }}</span>
          </div>
          <div class="flex align-items-center justify-content-start sub-text">
            {{ $t("login.welcome") }}
          </div>
          <div class="flex align-items-center justify-content-start sub-text">
            {{ $t("login.first_day") }}
          </div>
          <div class="flex align-items-center justify-content-start sub-text">
            {{ $t("login.unsubscribe") }}
          </div>
          <div class="flex align-items-center justify-content-start sub-text">
            {{ $t("login.free_charge") }}
          </div>
        </div>
      </div>
      <div class="flex flex-row align-self-end">
        <span
          class="language"
          :class="{ 'language-selected': selectedLang == 'en' }"
          @click="changeLanguage('en')"
          >{{ $t("change_language.en") }}</span
        >
        <span
          class="language"
          :class="{ 'language-selected': selectedLang == 'ku' }"
          @click="changeLanguage('ku')"
          >{{ $t("change_language.ku") }}</span
        >
        <span
          class="language"
          :class="{ 'language-selected': selectedLang == 'ar' }"
          @click="changeLanguage('ar')"
          >{{ $t("change_language.ar") }}</span
        >
      </div>
    </div>

    <div class="flex flex-row around-input align-self-center">
      <div
        class="flex align-items-center justify-content-start phone-number-text mr-2"
      >
        {{ $t("input.phone_number_label") }}
      </div>
      <!-- <div class="flex flex-column align-self-center"> -->
      <!-- <div class="flex align-self-end" style="padding-bottom: 10px;"> -->
      <input
        class="custom-input"
        type="tel"
        v-maska="'75# ### ####'"
        v-model="phoneNumber"
        placeholder="75"
      />
      <!-- </div> -->
      <!-- </div> -->
    </div>

    <button
      class="flex flex-row align-items-center align-self-center justify-content-center continue-button"
      @click="sendSMS()"
    >
      <span class="subscribe-text">{{ $t("login.subscribe") }}</span>
    </button>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import utils from "../utils";
import service from "../service";
import { v4 as uuidv4 } from "uuid";
import { store, notify } from "../store";

export default {
  name: "LoginComponent",
  mixins: [utils],
  props: {
    msg: String,
  },
  components: {},
  directives: { maska },
  data() {
    return {
      elementsToScroll: ["prizes-home-div"],
      pinCode: [],
      otpRequested: false,
      phoneNumber: "",
      ts: null,
      selectedLang: "en",
      prizes: [],
    };
  },
  watch: {
    otpRequested: function (oldVal, newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$refs["pinCode_1"][0].focus();
        }, 15);
      }
    },
  },
  methods: {
    changeLanguage(lang) {
      this.selectedLang = lang;
      i18n.locale = this.selectedLang;
      store.serviceLang = lang;
    },

    onKeyup(event, item) {
      if (item < 4 && event.keyCode != 8) {
        setTimeout(() => {
          this.$refs["pinCode_" + (item + 1)][0].focus();
        }, 15);
      } else if (item > 1 && event.keyCode == 8) {
        setTimeout(() => {
          this.$refs["pinCode_" + (item - 1)][0].focus();
        }, 15);
      }
    },

    async sendSMS() {
      if (this.phoneNumber.length != 12) {
        notify("Error", this.$t("error.invalid_phone_number"), "error", !false);
        return;
      }
      store.phoneNumber = this.phoneNumber.replaceAll(" ", "");
      store.ti = "WC-Web-App-" + uuidv4();
      let response = await service.user.requestPinCode();
      if (response && response.status == 200)
        utils.methods.goToRoute("/login-otp");
    },

    requestOtp() {
      this.otpRequested = true;
    },

    validateOtp() {
      utils.methods.goToRoute("home");
    },

    getNowEpoch() {
      return parseInt(new Date().getTime() / 1000);
    },

    async getBannerInfo() {
      let response = await service.prizes.prizesActives();
      if (response && response.status == 200) {
        this.prizes = response.data
          .filter((f) => f.imageUrl != null)
          .map((p) => {
            return {
              id: p.id,
              imageUrl_en: p.imageUrl,
              imageUrl_ar: p.imageUrlAr,
              imageUrl_ku: p.imageUrlKu,
            };
          });
      }
    },

    formatPrizeAmount(amount) {
      return new Intl.NumberFormat("en-US").format(amount);
    },

    scrollBanner() {
      const scrollContainer = document.getElementById("prizes-home-div");
      const cardWidth = 350; // Width of each card
      const gapWidth = 10; // Gap between cards
      let scrollAmount = 0;
      const scrollInterval = 2000;

      setInterval(() => {
        if (scrollContainer) {
          scrollAmount += cardWidth + gapWidth;
          if (scrollAmount >= scrollContainer.scrollWidth) {
            scrollAmount = 0; // Reset scroll amount if it reaches the end
          }
          scrollContainer.scrollTo({
            left: scrollAmount,
            behavior: "smooth",
          });
        }
      }, scrollInterval);
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    this.getBannerInfo();
    store.layout.showHeader = false;
    store.layout.showAccountFooter = false;
    store.layout.showFooter = false;
    this.phoneNumber = store.phoneNumber;
    this.ts = this.getNowEpoch();
    store.ti = "WC-Web-App-" + uuidv4();
    // this.scrollBanner(this.elementsToScroll);
    this.scrollBanner();
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .content-page {
    background-image: url("../assets/bg-max-width-600.png");
  }
}

@media (min-width: 600px) {
  .content-page {
    background-image: url("../assets/bg-min-width-600.png");
  }
}

.content-page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 0 !important;
  padding-top: 60px;
  height: 100%;
}

.content-login-cards {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  justify-content: space-between;
}

.content-login-cards::-webkit-scrollbar {
  display: none;
}

.login-card {
  height: 159px;
  width: 325px;
  justify-content: space-between;
  margin: 0 10px;
}

.login-card img {
  background-size: cover;
  border-radius: 10px;
}

.now {
  padding-left: 20px;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  text-transform: uppercase;
}

.you-can-win {
  padding-left: 20px;
  font-weight: 300;
  font-size: 26px;
  text-transform: capitalize;
  color: #ffcd00;
}

.one-million-iqd {
  padding-left: 20px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
}

.login {
  font-weight: 600;
  font-size: 28px;
  color: #ffcd00;
}

.text {
  font-weight: 600;
  font-size: 28px;
  color: #ffffff;
}

.sub-text {
  font-weight: 400;
  font-size: 15px;
  color: #b0b1ca;
}

.language {
  font-weight: 400;
  font-size: 15px;
  color: #b0b1ca;
  padding-right: 10px;
}

.language-selected {
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  padding-right: 10px;
}

.around-input-otp {
  background-color: #191b22;
  border: 1px solid #707070;
  border-radius: 15px;
  width: 60px;
  height: 60px;
}

.around-input {
  background-color: #191b22;
  border-radius: 15px;
  width: 330px;
  height: 65px;
}

.phone-number-text {
  padding-left: 20px;
  font-weight: 300;
  font-size: 12px;
  color: #b0b1ca;
  white-space: nowrap;
}

.phone-number-prefix-text {
  margin-top: 4px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}

.subscribe-button {
  background-color: #0057b7;
  border-radius: 15px;
  width: 330px;
  height: 55px;
}

.subscribe-text {
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  color: #ffffff;
}

.continue-button {
  margin-bottom: 10px;
  background-color: #0057b7;
  border-radius: 15px;
  width: 330px;
  border: none;
  height: 55px;
  /* position: absolute;
  transition: position 10s ease; */
}

.continue-button:hover {
  position: static;
}

::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #b0b1ca;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #b0b1ca;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #b0b1ca;
}

.custom-input {
  border-width: 0px;
  padding: 15px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #cecece;
  background-color: #474a55af;
  border-radius: 0px 15px 15px 0px;

  width: 100%;
  height: 100%;
}

.custom-input-otp {
  border-width: 0;
  padding: 0px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #b0b1ca;
  background-color: #191b22;
  width: 10px;
  height: 20px;
}

.re-send-pin-button {
  border: 1px solid #707070;
  border-radius: 15px;
  width: 330px;
  height: 55px;
}
</style>
