import store from '../store'
import service from '../service'

const dailyQuiz = {
    nextQuestion: async () => {
        try {
            const response = await service.$http.get(`${store.API_URL}user-answer/nextQuestion`);
            return response;
        } catch (e) {
            return e.response;
        } 
   },
   answerQuestion: async (userAnswerID, answer, cardType) => {
        try {
            const response = await service.$http.patch(store.API_URL + 'user-answer/answerQuestion/' + userAnswerID, {
                'answer': answer,
                'cardType': cardType
            })
            return response;
        } catch (e) {
            return e.response;
        } 
    },
}

export default dailyQuiz