import store from '../store'
import service from '../service'

const match = {
  getScheduleMatches: async (page, pageSize) => {
    try {
        const response = await service.$http.get(`${store.API_URL}match/scheduledMatches?&page=${page}&pageSize=${pageSize}`);
        return response;
    } catch (e) {
        return e.response;
    } 
},

  getLiveMatches: async (page, pageSize) => {
    try {
        const response = await service.$http.get(`${store.API_URL}match/liveMatches?&page=${page}&pageSize=${pageSize}`);
        return response;
    } catch (e) {
        return e.response;
    } 
  },

  getLastMatches: async (page, pageSize) => {
      try {
          const response = await service.$http.get(`${store.API_URL}match/lastMatches?&page=${page}&pageSize=${pageSize}`);
          return response;
      } catch (e) {
          return e.response;
      } 
  },
  getMatch: async (id) => {
    try {
      const response =  await service.$http.get(`${store.API_URL}match/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getMatchDays: async () => {
    try {
      const response =  await service.$http.get(`${store.API_URL}match-day/matchesDay`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
  getTopMatches: async (id) => {
    try {
      const response =  await service.$http.get(`${store.API_URL}match/topMatches/${id}`);
      return response;
    } catch (e) {
      return e.response;
    }
  },
}

export default match