import Vue from "vue";
import VueI18n from "vue-i18n";
import en from './translations/english.js'
import ar from './translations/arabic.js'
import ku from './translations/kurdish.js'

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages: {
    en,
    ar,
    ku
  },
});
export default i18n;
