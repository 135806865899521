<template>  
    <div v-if="showHeader" class="content-header">
        <div v-if="isHome" class="soccer-ball">
          <img :src="require('@/assets/icons/soccer_ball.png')"/>
          <div class="app-title">{{ $t("header.title") }}</div>
        </div>
        <div v-else class="go-back" @click="goBack()">
          <img :src="require('@/assets/left-arrow.png')"/>
        </div>
        <div v-if="!isHome" class="header-title">
            {{ headerTitle }}
        </div>
        <div class="points-menu">
            <div @click="togglePoints" :class="{'points': true, 'hide-points': isHome}">
                <div :class="{'slide-points': true, 'slide-left': showPoints > 0, 'slide-right': showPoints < 0}">
                    <img :src="require('@/assets/coin-points.png')"/>
                    <span class="pointsWidth">{{ totalPoints }}</span>
                </div>
            </div>
            <div class="left-menu" @click="showModalMore()">
                <img :src="require('@/assets/left-menu.png')"/>            
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import utils from "@/utils";
import i18n from "@/translation";

export default {
  name: "HeaderComponent",
  mixins: [utils],
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
        showPoints: 0,
        pointsWidth: 0,
        totalPoints: 0
    }
  },
  methods: {
    togglePoints(){
      this.showPoints = this.showPoints <= 0 ? 1 : -1
    },
    showModalMore(){
      store.layout.showModalMore = true
    },
    setPointsWidth(points){
      this.showPoints = 0
      this.totalPoints = points
      if(document.querySelector('.pointsWidth')){
        document.querySelector('.pointsWidth').style.width = 'auto'
        setTimeout(() => {
          this.pointsWidth = this.getAbsoluteWidth('.pointsWidth') + 'px'
          document.documentElement.style.setProperty("--pointsWidth", this.pointsWidth);
        }, 10)
      }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
    headerTitle() {
      return store.layout.headerTitle
    },
    isHome(){
      return this.$route.name == "home"
    },
    showHeader() {
      return store.layout.showHeader
    },
    points(){
      return store.user.points
    }
  },
  watch: {
    points(val){
      //this.totalPoints = val
      this.setPointsWidth(val)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setPointsWidth(this.points)
    })
  },
};
</script>

<style scoped>
.content-header {
  justify-content: space-between;
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191b22;
  height: 60px;
  display: flex;
  position: absolute;
  width: 100%;
}

.soccer-ball {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 25px;
}
.app-title {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.go-back {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 25px;
}

.go-back img {
    width: 14px;
    margin-right: 20px;
    margin-left: 1px;
}
.header-title {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 190px);
}

.points-menu {
  display: flex;
  width: 60px;
}
.points {
  padding-top: 18px;
  padding-right: 2px;
  padding-left: 2px;
  position: relative;
  z-index: 5;
  display: inline-block;
}

.hide-points {
  visibility: hidden;
}

.slide-points {
  position: absolute;
  right: 0px;
  display: flex;
  width: calc(20px + var(--pointsWidth));
  transform: translateX(var(--pointsWidth)) translateY(0);
}

.slide-left {
  transform: translateX(-5px) translateY(0); 
  transition: transform 500ms ease-in-out;
}

.slide-right {
  transform: translateX(var(--pointsWidth)) translateY(0); 
  transition: transform 500ms ease-in-out;
}

.points div {
  margin-right: 5px;
  color: #f39e0d;
  font-weight: bold;
}

.points div span {
  padding-left: 5px;
  display: block;
}
.left-menu {
  display: flex;
  align-items: center;
  padding-right: 25px;
  position: relative;
  z-index: 10;
  padding-left: 10px;
  background-color: #191b22;
}
</style>
