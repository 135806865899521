<template>
  <div class="content-page">
    <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
        <div class="flex align-self-center align-items-center justify-content-center sub-menu">
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-left" 
          :class="{'sub-page-active':checkActive('weekly')}" @click="setActiveSubPage('weekly')">
            {{ $t("winners.sub_menu.weekly") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-rigth" 
          :class="{'sub-page-active':checkActive('grand')}" @click="setActiveSubPage('grand')">
            {{ $t("winners.sub_menu.grand") }}
          </div>
        </div>
      </div>
    <div class="flex flex-column background-winners">
      <div v-for="w in winnersList" :key="w.id" class="flex align-self-center justify-content-center winner-around">
        <div class="flex align-items-center justify-content-center" style="width: 30%;">
          <img :src="w.urlImage" alt=""/>
        </div>
        <div class="flex flex-column justify-content-center" style="width: 70%;padding: 20px;">
          <div class="flex align-content-between justify-content-between">
            <div class="name"> {{ w.name }} </div>
            <div class="value"> {{ w.reward.value | formatNumber}} {{ $t("general.currency") }} </div>
          </div>
          <!-- <div class="date"> {{ w.createdAt }} </div> -->
          <div class="sub-text">{{ w.summary }} </div>
        </div>
      </div>
    </div>
    <div id="scroll-sentinel"></div>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import {store} from "../store";
import  service from "../service";

export default {
name: "WinnersComponent",
components: {
},
directives: { maska },
data() {
  return {
    winnersList: [],
    subPageActive: 'weekly',
    page: 1,
    pageSize: 10
  }
},
methods: {
  setActiveSubPage(page) {
      this.subPageActive = page;
      this.winnersList = [],
      this.page = 1,
      this.pageSize = 10
      this.getWinners();
    },
    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },
    async getWinners() {
      let year = new Date().getFullYear();
      let response = await service.winners.winners(this.subPageActive.toLocaleUpperCase(),year,this.page,this.pageSize);
      if (response && response.status == 200){
        if(response.data.result.length > 0){
          if(this.winnersList.length > 0){
            response.data.result.forEach(e => {
                this.winnersList.push(e);
              });
          }else{
            this.winnersList = response.data.result;
          }
        }else{
          this.page -= 1
          this.page <= 0 ? this.page = 1 : this.page
        }
      }
    },
},
computed: {
  language() {
    return i18n.locale;
  }
},
async mounted() {
  await this.getWinners()

  const observer = new IntersectionObserver( async (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
            this.page += 1
            await this.getWinners()
        }
      }
    });

    const scrollSentinel = document.querySelector("#scroll-sentinel");
    observer.observe(scrollSentinel);

  store.layout.showHeader = true;
  store.layout.showFooter = true;
  store.layout.showAccountFooter = false;
  store.layout.footerItem = 'winners';
  store.layout.headerTitle = this.$t("winners.title")
},
};
</script>

<style scoped>
#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}
.content-page {
font-weight: 400;
font-family: 'Poppins';
font-style: normal;
}
.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3;
  background-color: #191b22;
}

.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
}

.sub-menu-item-left{
  border-radius: 7px 0px;
}

.sub-menu-item-rigth{
  border-radius: 0px 7px;
}

.sub-page-active{
  background-color: #0A57B7 !important;
}
.background-winners{
  height: var(--appHeight);
  margin-bottom: 80px;
  background-color: #191b22;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}
.winner-around{
background-color: #282E3D;
box-shadow: 0px 3px 10.5px rgba(0, 0, 0, 0.443137);
border-radius: 15px;
width: 90%;
height: 95px;
margin-bottom: 10px;
}
.winner-around img{
  padding: 5px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.name{
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
}
.value{
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: #FFCD00;
}
.date{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
}
.sub-text{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
}
</style>
