<template>
    <div id="app">
      <Toast />
      <header-component v-if="showHeader"></header-component>
      <div class="content">
        <router-view :style="{'visibility' : showLoading ? 'hidden' : 'visible'}"></router-view>
      </div>
      <footer-component></footer-component>
      <modal-more-component v-if="showModalMore"></modal-more-component>
      <modal-buy-cards-component v-if="showModalBuyCards"></modal-buy-cards-component>
      <loading-component></loading-component>
    </div>
</template>

<script>
import store  from "./store";
import i18n from "./translation";
import ModalMoreComponent from './components/ModalMore.vue';
import ModalBuyCardsComponent from './components/ModalBuyCards.vue';
import FooterComponent from './components/Footer.vue';
import HeaderComponent from './components/Header.vue'
import LoadingComponent from './components/Loading.vue'
import 'primeflex/primeflex.css';

export default {
  name: "App",
  components: {
    HeaderComponent,
    ModalMoreComponent,
    ModalBuyCardsComponent,
    FooterComponent,
    LoadingComponent
  },
  data(){
    return {
      height: '100%',
    }
  },
  computed: {
    showLoading(){
      return store.layout.showLoading
    },
    showModalMore(){
      return store.layout.showModalMore
    },
    showModalBuyCards() {
      return store.layout.showModalBuyCards
    },
    showHeader(){
      return store.layout.showHeader
    },
  },
  methods: {
    setAppHeight() {
      let headerHeight = 60;
      let footerHeight = 0;
      this.height = (window.innerHeight - (headerHeight + footerHeight)) + 'px';
      document.documentElement.style.setProperty("--appHeight", this.height);
    },
  },
  mounted() {
    window.visualViewport.addEventListener('resize', this.setAppHeight);
    window.visualViewport.addEventListener("focus", this.setAppHeight);
    window.visualViewport.addEventListener("blur", this.setAppHeight);
    this.setAppHeight();

    let urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get("lang");
    if (lang && ["en", "ar", "ku"].indexOf(lang) > -1) {
      i18n.locale = lang;
    } else if (lang && ["kr"].indexOf(lang) > -1) {
      i18n.locale = "ku";
    }
    store.serviceLang = lang && ["en", "ar", "ku", "kr"].indexOf(lang) > -1 ? lang : "en";
  },
  watch: {
    showHeader() {
      if(!this.showHeader)
        document.documentElement.style.setProperty("--contentTop", '0px');
      else
        document.documentElement.style.setProperty("--contentTop", this.showUserWallet ? '95px' : '60px');
      this.setAppHeight();
    },
  }
};
</script>

<style>

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Poppins.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('@/assets/fonts/Poppins-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('@/assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 400;
}

:root {
  --appHeight: 100%;
  --contentTop: 60px;
  --contentBottom: 0px;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  background-color: #191B21;
  font-family: var(--appFontFamily);
  font-weight: 400;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-text-size-adjust: none;
  touch-action: pan-y; /*prevent user scaling*/
}

@media (max-width: 600px) {
  #app {
    background-color: #191B22;
    max-width: 600px;
    color: #707070;
  }

  .content {
    max-width: 100%;
    z-index:1;
  }
}

@media (min-width: 600px) {
  #app {
    background-color: #191B22;
  }

  .content {
    max-width: 650px;
  }
}

.content::-webkit-scrollbar { 
  display: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin: 0 auto;
  margin-top: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  /* background-size: 100vw 100vw; */
}

.content {
  margin: 0 auto;
  top: var(--contentTop);
  bottom: var(--contentBottom);
  left: 0px;
  right: 0px;
  width: 100%;
  overflow-y: auto;
  padding: 0px 0px;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: calc(100% - (var(--contentBottom) + var(--contentTop)));
}


.content-page { 
  padding-bottom: 80px;
  font-weight: 400;
  font-family: "Poppins";
  font-style: normal;
}

:focus-visible {
  outline: none;
}

.rtlDirection {
  direction: rtl;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.p-toast {
  max-width: 90%;
  z-index: 9005!important;
}

.p-toast-top-right {
  top: 10px!important;
  right: 5px!important;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #ffcd00;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #ffcd00;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #ffcd00;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
</style>
