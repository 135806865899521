<template>
    <div @click="selectCard" class="flex justify-content-center">
        <div :class="`${type}-card flex align-items-center justify-content-between`" :style="language != 'en'? 'direction: rtl;' : ''">
            <img v-if="checked" class="checked-icon" :src="require('@/assets/icons/check-card.png')"/> 
            <div v-else :class="[`${(!this.selected ? 'un' : '')}check-card`, 'border-circle', 'ml-3',{'mr-3':language != 'en'}]" ></div>
            <div class="ml-2 mr-auto text-xl">
              <span :class="`${type}-text`">{{ label }}</span> {{ $t('cards.card') }}
            </div>
            <div class="mr-2 font-bold">{{ points }}</div>
            <div class="amount flex align-content-center flex-wrap justify-content-center mr-3 border-circle w-2rem h-2rem bg-white" :class="{'ml-3':language != 'en'}">
              {{ amount }}
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "@/translation";

export default {
  name: "PlayCardsComponent",
  props: ["type", "points", "amount", "label", "selected",  "checked"],
  data() {
    return {
      checkHandle: false
    }
  },
  methods: {
    selectCard(){
      this.$emit('selectCard', this.type)
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {}
};
</script>

<style scoped>

.captain-card {
  background-image: url('../assets/small-captain-card.png');
  background-repeat: no-repeat;
  width: 330px;
  height: 50px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.captain-text {
   color: #FFCD00
}

.gold-text {
   color: #191B22;
   font-weight: 700;
}

.daily-text {
   color: #191B22;
   font-weight: 700;
   font-size: 18px;
}

.gold-card {
  background-image: url('../assets/small-gold-card.png');
  background-repeat: no-repeat;
  width: 330px;
  height: 50px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.daily-card {
  background-image: url('../assets/small-daily-card.png');
  background-repeat: no-repeat;
  width: 330px;
  height: 50px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.uncheck-card, .check-card {
  height: 24px!important;
  width: 24px!important;
}
.uncheck-card {
  background-color: #e0e0e0;
  border: none;
}

.check-card {
  border: 4px solid #ADAEC6;
  background-color: #FFCD00;
}

.checked-icon {
  margin-left: 12px;
  margin-top: 8px;
}

.amount {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
}

</style>
