export default {
  general:{
    currency: "IQD",
    rank: "Rank",
    quiz: "Quiz",
    coming_soon: "Coming Soon",
    english: "English",
    en: "en",
    arabic: "Arabic",
    ar: "ar",
    kurdish: "Kurdish",
    ku: "ku",
    points: "points"
  },
  error:{
    invalid_phone_number: "Invalid phone number",
    invalid_pin_code: "Invalid pin code",
    error: "Error",
    note: "Note",
    select_answer: "Select an option!",
    connexion_error: "A connexion error ocurred!"
  },
  input:{
    phone_number_label: "Phone Number",
    phone_number_prefix: "+964"
  },
  login: {
    login: "Login",
    lets_get_started: "Let's get Started",
    welcome: "Welcome to Captain KOREK!",
    first_day: "1 day is free and 300 IQD / Day.",
    unsubscribe: "To unsubscribe send 0 to 3230",
    free_charge: "(Free of charge)…",
    to_continue: "To continue",
    successfully_entered: "You successfully entered",
    your_number: "your number,",
    subscribe: "Subscribe",
    continue: "Continue",
    re_send_pin: "Re-send pin"
  },
  header:{
    title: "Captain Korek"
  },
  home: {
    title:"Home",
    total_points: "Total Points",
    ranking: "Ranking",
    live: "Live",
    upcoming: "Upcoming",
    past_matches: "Past Matches",
    view_all: "View All",
    leagues: "Leagues",
    top_3_matches: "Top 3 Matches",
    today: "Today",
    versus: "VS",
    points_per_card: "# pts per one card",
    joined: "Joined",
    join_or_play: "Join or Play",
    ended: "Ended",
  },
  change_language: {
    en: "EN",
    ku: "KU",
    ar: "العربية"
  },
  about: {
    title: "About"
  },
  terms: {
    title: "T&C"
  },
  privacy:{
    title: "Privacy Policy"
  },
  unsubscribe:{
    title: "Unsubscribe",
    sub_title_1: "We are so sory to lost you.",
    sub_title_2: "We would like to know what caused this separation.",
    reason_1: "The game does not interest me.",
    reason_2: "Very Expensive.",
    reason_3: "I do not have time.",
    reason_4: "too many notifications",
    reason_5: "it’s not about you.",
    unsubscribe: "Unsubscribe",
    error_reason: "Select at least one reason"
  },
  modal_more:{
    winners: "Winners",
    manage_account: "Manage Account",
    about: "About",
    faq: "FAQ",
    terms: "T&C",
    unsubscribe: "Unsubscribe",
    privacy: "Privacy Policy",
    logout: "Logout"
  },
  cards:{
    sub_menu:{
      active: "Active",
      buy: "Buy Card",
      history: "Card History"
    },
    select_card: "Select Card",
    title: 'My Cards',
    daily: "Daily",
    captain: "Captain",
    card: "Card",
    gold: "Gold",
    vs: "VS",
    pts: "pts",
    active: "Active",
    buy_card: "Buy Card",
    unavailable: "You need to get a card to play",
    daily_unavailable: "Get back tommorow to get a card to play!",
    captain_unavailable: "You do not have any Captain Cards! Get them now!",
    gold_unavailable: "You do not have any Gold Cards! Get them now!",
    captain_price: "For #240 IQD",
    gold_price: "For #480 IQD",
    dayly_price: "For Free 05 cards",
    points: "# Points",
    per_one_card: "per one card"
 },
  modal_buy_cards:{
    buy_card: "Buy Card",
    verify_payment: "Verify Payment",
    please_verify: "Please verify the code",
    from_number: "from the phone number",
    you_entered: "You successfully entered",
    your_number: "your number,",
    purchase_successful: "Purchase successful",
    go_to_my_cards: "Go To My Cards",
    submit: "Submit",
    cancel: "Cancel",
    confirm: "Confirm",
    buying: "Buying",
    get: "Get",
    cards_by: "Cards by",
    iqd: "IQD",
    for: "For",
    invalid_pincode: "Invalid Pincode entered",
    recharge: "Sorry, no enough balance, recharge and try again",
  },
  leagues:{
    title: "Leagues"
  },
  league:{
    sub_menu:{
      last_matches: "Last Matches",
      standings: "Standings",
      schedule: "Schedule"
    },
    match_continues: "Match Continues",
    match_over: "Match Over,",
    draw_over: "Draw is over.",
    won: "won.",
    play: "Play"
  },
  leaders:{
    title: "Leaders",
    sub_menu:{
      weekly: "This week",
      monthly: "This month",
      all: "All time"
    },
    no_named: "No named"
  },
  news:{
    title: "News",
    hot_news: "Hot News",
    lastest_news:"Lastest News"
  },
  daily_quiz:{
    title: "Daily Quiz",
    points_earned: "Points Earned",
    next_question: "Next Question",
    submit: "Submit",
    select_quiz_response: "Select an anwser!"
  },
  winners:{
    title: "Winners",
    sub_menu:{
      weekly: "Weekly",
      grand: "Grand"
    },
  },
  joined_match:{
    title: "Joined Match",
    join_contest: "Join Contest",
    super_matches_contest: "Super Matches Contest",
    amount: "1,075,000",
    iqd_every_week: "IQD Every Week",
    select_card: "Select Card",
    question: "Question",
    ends_draw: "Ends in a draw",
    none: "None",
    both: "Both",
    congratulations: "Congratulations",
    completed_questions: "You completed the questions.",
    points_correct_answers: "You will Get # points with Correct Answers",
    notification_message: "We will send you notification when the match is over. good luck",
    next_question: "Next Question",
    submit: "Submit",
    go_home: "Go To Home",
    red_cards: "Red Cards",
    yellow_cards: "Yellow Cards",
    goals: "Goals?",
    choose_winner: "Choose the winner?",
    correct_answer: "Correct answer ",
    wrong_answer: "Wrong answer "
  },
  manage_account:{
    title: "Manage Account",
    rank: "Rank",
    matches_played: "Matches Played",
    cards_played: "Cards Played",
    favourite_cards: "Favorite Cards",
    overall: "Overall Rank",
    weekly: "Weekly Rank"
  },
  faq:{
    title: "FAQ",
    sub_menu:{
      general: "General",
      cards: "Cards",
      all: "All time"
    }
  },
  match_list:{
    title_1:'Matches',
    title_2:'Match Result',
    joined_match:'Joined Match',
    total_points:'Total points earned :',
    both: 'Both',
    search_match: 'Search Match',
    sub_menu:{
      live: "Live",
      upcoming: "Upcoming",
      past: "Past matches"
    },
  },
  logout:{
    msg: "Are you sure you want to sign out You will no longer be able to receive information about messages and notifications. You will need to login again.",
    cancel: "Cancel",
    yes: "Yes"
  },
  news_details:{
    source: "Source: ",
    source_url: "Source Url: "
  }
}