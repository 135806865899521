<template>
<transition name="slide-fade">
  <div id="myModal" class="modal">
      <div class="modal-content">
        <div class="more">
          <div class="flex align-content-around justify-content-around flex-wrap more-header">
            <div class="flex align-items-center align-content-center justify-content-center circle">
              <img v-if="userInfo.photoUrl" class="photo" :src="getUserPhoto"/>
              <img v-else class="photo" :src="require('@/assets/icons/soccer_ball.png')"/>
            </div>
            <div class="flex flex-column justify-content-center">
              <div class="flex align-items-center name"> {{ userInfo.name }}  </div>
              <div class="flex align-items-center rank"> {{ $t("general.rank") +' '+userInfo.overallRank }}</div>
            </div>
            <div class="flex align-items-center locale"> 
              <div class="dropdown-language">
                <span class="dropdown-language-selected">{{ selectedLang }}</span>
                <div class="dropdown-language-content">
                  <p v-for="lang in this.languages" :key="lang" @click="changeLanguage(lang)">{{ lang }}</p>
                </div>
              </div>
            </div>
          </div>
          <div :class="{ 'rtl': language != 'en' ? true : false, 'items': true }">
          <div v-for="(m,index) in menu" :key="index">
            <div class="flex align-items-center flex-wrap clicable-area" @click="m.route != 'logout' ? goToRoute(m.route) : showLogout()">
              <div class="flex align-items-center justify-content-center">
                <img class="icons" :src="require('@/assets/icons/'+m.icon)" alt=""/>
              </div>
              <div class="flex align-items-center justify-content-center text-item">{{ $t(m.text) }}</div>
            </div>
            <div  class="flex flex-column align-content-around justify-content-around flex-wrap">
              <div class="dashed-line"></div>
            </div>
          </div>
          </div>
          <ModalLogoutComponent v-if="showLogoutModalMore" @closeDialog="closeDialog" @logout="logout"></ModalLogoutComponent>
        </div>
      </div>
    </div>
    </transition>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";
import service from "../service";
import ModalLogoutComponent from "./ModalLogout.vue";

export default {
  name: "ModalMoreComponent",
  components: {
    ModalLogoutComponent
  },
  data() {
    return {
      selectedLang: '',
      languages:['en','ar','ku'],
      menu:[{
          route:'winners',
          icon:'trophy-star.png',
          text:'modal_more.winners'
        },
        {
          route:'manage-account',
          icon:'settings.png',
          text:'modal_more.manage_account'
        },
        {
          route:'about',
          icon:'information.png',
          text:'modal_more.about'
        },
        // {
        //   route:'faq',
        //   icon:'comment-question.png',
        //   text:'modal_more.faq'
        // },
        {
          route:'terms',
          icon:'bulb.png',
          text:'modal_more.terms'
        },
        {
          route:'unsubscribe',
          icon:'document.png',
          text:'modal_more.unsubscribe'
        },
        {
          route:'privacy',
          icon:'document2.png',
          text:'modal_more.privacy'
        },
        {
          route:'logout',
          icon:'sign-out-alt.png',
          text:'modal_more.logout'
        }],
      showChangeLanguage: false,
      showChangeTheme: false,
      userInfo: {},
      logoutDialog: false
    };
  },
  methods: {
    goToRoute(route) {
        this.$router.push("/" + route);
        store.layout.showModalMore = false;
    },
    closeDialog(){
      store.layout.showLogoutModalMore = false;
    },
    logout(){
      store.layout.showLoading = false;
      localStorage.removeItem("user.token");
      store.user.token = "";
      store.layout.showLogoutOverlay = false;
      this.$router.push("/");
    },
    showLogout(){
      store.layout.showLogoutModalMore = true;
    },
    changeLanguage(value){
      this.selectedLang = value;
      this.languages = ['en','ar','ku'];
      this.languages = this.languages.filter(l => l != this.selectedLang);
      i18n.locale = this.selectedLang
    },
    checkActive(item) {
      return [
        "item-footer",
        this.activeItem === item ? "item-footer-active" : "",
      ];
    },
    async getUserAsync() {
      let response = await service.user.getUser();
      if (response && response.status === 200) {
        this.userInfo = response.data;
      }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
    showModalMore() {
      return store.layout.showModalMore
    },
    showLogoutModalMore() {
      return store.layout.showLogoutModalMore
    },
    getUserPhoto(){
      return "https://" + store.AWS_BUCKET +'.s3.'+ store.AWS_DEFAULT_REGION +'.amazonaws.com/'+ this.userInfo.photoUrl
    }
  },
  watch: {
  },
  mounted() {
    this.getUserAsync();
    this.selectedLang = i18n.locale;
    this.languages = this.languages.filter(l => l != this.selectedLang);
    this.showChangeLanguage = false
    this.showChangeTheme = false
    document.addEventListener('mousedown', (event) => {
      if(event.target.className == 'modal') {
        store.layout.showModalMore = this.showChangeLanguage = this.showChangeTheme = false;
      }
    })
  },
};
</script>

<style scoped>

.buttons-container{
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
.buttons{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 33px;
  border-radius: 5px;
  margin: 5px;
  background-color: #A9FB03;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #2A3757
}

.dialog-message {
  width: 70%;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #E8E8E8;
}

.el-dialog--center .el-dialog__body {
  padding: 25px 20px 15px 20px !important;
}

:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  background-color: #2A3757 !important;
  color: rgb(28, 0, 73);
  font-size: 14px;
  border: #212b42 1px solid;
}

:deep(.el-input-number) {
  width: 100%;
}

:deep(.dialog-footer) button {
  width: 100%;
  font-size: 14px;
  display: block;
  background-color: #2A3757 !important;
  border: #212b42 1px solid;
  color: #212b42;
}

.dialog-message .sub-message {
  font-size: 13px;
}


.dashed-line{
  border: 0.5px dashed #B0B1C8;
  border-radius: 15px;
  width: calc(100% - 50px);
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B0B1C8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
  
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.modal {
  display: block; 
  position: absolute; 
  z-index: 1001; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}
.modal-content {
  float: right;
  border-top-left-radius: 20px;
  width: 90%; 
  height: calc(var(--appHeight) - 30px);
  margin-top: calc(var(--contentTop) + 20px);
}

.more {
  flex-direction: column;
  display: flex;
  height: 100%;
}
.more-header{
  background-color: rgb(0,87,183);
  height: 125px; 
  border-top-left-radius: 20px;
}

.photo{
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.name{
  font-weight: 700;
  font-size: 17px;
  color: #FFFFFF;
}
.rank{
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
}
.locale{
  font-weight: 700;
  font-size: 17px;
  color: #FFFFFF;
}
.icons{
  padding-left: 50px;
  padding-right: 50px;
}
.items {
  background-color: #282E3D;
  color: #ffffff;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}
.text-item {
  font-weight: 600;
  font-size: 15px;
  color: #FFFFFF;
}

.clicable-area{
  min-height: 75px
}

.rtl .list-item {
  flex-direction: row-reverse;
}

.rtl .list-item i {
  transform:  rotate(180deg);;
}

.circle {
  height: 75px;
  width: 75px;
  border: 1px solid #FECD01;
  border-radius: 50%;
  display: inline-block;
}

.dropdown-language {
  text-transform: uppercase;
  position: relative;
  display: flex;
}

.dropdown-language-content {
  display: none;
  position: absolute;
  background: #FFFFFF;
  color: #282E3D;
  width: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  z-index: 100;
  top: 30px;
}

.dropdown-language-selected {
  width: 40px;
  margin-bottom: 0px;
  padding: 0 0 10px 0;
}

.dropdown-language:hover > .dropdown-language-content {
  display: block;
}
</style>