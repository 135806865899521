import Vue from "vue";
import { app } from "@/main";

export const store = Vue.observable({
  user: {
    // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiZHVtbXkgdXNlcjQiLCJzdWIiOiJiYmViNDRhMy0yNTU4LTRjNmItYWNiNy04YzQzYWY1MjgxYTIiLCJwaG9uZU51bWJlciI6Ijk2NDc1MDEyMzEyMzQiLCJpYXQiOjE2ODUxMzI5MjksImV4cCI6MTcxNjY2ODkyOX0.vv_GTs0mJQO2gAaraeJ7g5DgDEbdGdFTofr3AUIl8RQ',
    token: "",
    phoneNumber: "",
    cards: {
      daily: 0,
      captain: 0,
      gold: 0,
    },
    points: 0,
    name: "",
    currentPoints: 0,
    currentRanking: 0,
    photoUrl: "",
  },
  ti: "",
  layout: {
    showLoading: false,
    showModalMore: false,
    showModalBuyCards: false,
    showHeader: true,
    showLogoutModalMore: false,
    headerTitle: "",
    showFooter: false,
    footerItem: "",
    showAccountFooter: false,
  },
  cardsPrices: {
    currentType: "",
    points: {
      daily: 0,
      captain: 0,
      gold: 0,
    },
  },
  serviceLang: "en",
  //API_URL: 'http://localhost:3000/',
  // API_URL: "https://captainkorekapi-production.up.railway.app/",
  API_URL: "https://captainkorek.tornest.net:9337/",
  AWS_BUCKET: "captainkorek",
  AWS_DEFAULT_REGION: "eu-central-1",
  ignoreDefaultError: false,
});

export const notify = (title, message, type, fixed = false) => {
  Vue.prototype.$toast.add({
    severity: type,
    summary: title,
    detail: message,
    life: fixed ? null : 3000,
  });
};

export const resetLayout = () => {
  if (document.querySelector(".content"))
    document.querySelector(".content").scrollTop = 0;
  store.layout = {
    showLoading: false,
    showModalMore: false,
    showModalBuyCards: false,
    showHeader: true,
    showLogoutModalMore: false,
    headerTitle: "",
    showFooter: false,
    footerItem: "",
    showAccountFooter: false,
    ignoreLoading: false,
  };
};

export default store;
