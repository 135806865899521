import Vue from "vue";
import Router from "vue-router";

import LoginPage from "./pages/Login.vue";
import LoginOtpPage from "./pages/LoginOtp.vue";
import HomePage from "./pages/Home.vue";
import AboutPage from "./pages/About.vue";
import TermsPage from "./pages/Terms.vue";
import PrivacyPage from "./pages/Privacy.vue";
import UnsubscribePage from "./pages/Unsubscribe.vue";
import WinnersPage from "./pages/Winners.vue";
import CardsPage from "./pages/Cards.vue";
import LeaguePage from "./pages/League.vue";
import LeagueListPage from "./pages/LeagueList.vue";
import LeadersPage from "./pages/Leaders.vue";
import DailyQuizPage from "./pages/DailyQuiz.vue";
import JoinedMatchPage from "./pages/JoinedMatch.vue";
import NewsPage from "./pages/News.vue";
import NewsDetailsPage from "./pages/NewsDetails.vue";
import ManageAccountPage from "./pages/ManageAccount.vue";
import FaqPage from "./pages/Faq.vue";
import MatchListPage from "./pages/MatchList.vue";
import MatchPage from "./pages/Match.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage,
      meta: {
        public: true
      }
    },
    {
      path: '/login-otp',
      name: 'login-otp',
      component: LoginOtpPage,
      meta: {
        public: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage,
      meta: {
        public: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage,
      meta: {
        public: true
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: TermsPage,
      meta: {
        public: true
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPage,
      meta: {
        public: true
      }
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: UnsubscribePage,
      meta: {
        public: true
      }
    },
    {
      path: '/winners',
      name: 'winners',
      component: WinnersPage,
      meta: {
        public: true
      }
    },
    {
      path: '/cards',
      name: 'cards',
      component: CardsPage,
      meta: {
        public: true
      }
    },
    {
      path: '/leagues',
      name: 'leagues',
      component: LeagueListPage,
      meta: {
        public: true
      }
    },
    {
      path: '/league',
      name: 'league',
      component: LeaguePage,
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/leaders',
      name: 'leaders',
      component: LeadersPage,
      meta: {
        public: true
      }
    },
    {
      path: '/daily-quiz',
      name: 'daily-quiz',
      component: DailyQuizPage,
      meta: {
        public: false
      }
    },
    {
      path: '/joined-match',
      name: 'joined-match',
      component: JoinedMatchPage,
      props: true,
      meta: {
        public: false
      }
    },
    {
      path: '/news',
      name: 'news',
      component: NewsPage,
      meta: {
        public: false
      }
    },
    {
      path: '/news-details',
      name: 'news-details',
      component: NewsDetailsPage,
      props: true,
      meta: {
        public: false
      }
    },
    {
      path: '/manage-account',
      name: 'manage-account',
      component: ManageAccountPage,
      meta: {
        public: false
      }
    },
    {
      path: '/faq',
      name: 'faq',
      component: FaqPage,
      meta: {
        public: false
      }
    },
    {
      path: '/match-list',
      name: 'match-list',
      component: MatchListPage,
      meta: {
        public: false
      }
    },
    {
      path: '/match',
      name: 'match',
      component: MatchPage,
      props: true,
      meta: {
        public: false
      }
    },
  ]
});