import router from "./router";
import Vue from "vue";

const utils = {
    methods: {
      scrollBanner(elementIds){
        Vue.nextTick(() => {
          elementIds.forEach(element => {
            const scrollPrizes = document.getElementById(element);
            scrollPrizes.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            scrollPrizes.scrollLeft += evt.deltaY;
          });
          });
        })
      },
        interpolate($text, $val) {
            $val.forEach((element) => {
                $text = $text.replace("#", element)
            });
            return $text
        },
        getAbsoluteHeight(el) {
            try {
                el = document.querySelector(el);
                let styles = window.getComputedStyle(el);
                let margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
                return Math.ceil(el.offsetHeight + margin) || 0;
            } catch (e) {
                return 0;
            }
        },
        getAbsoluteWidth(el) {
            try {
                el = document.querySelector(el);
                let styles = window.getComputedStyle(el);
                let margin = parseFloat(styles["marginLeft"]) + parseFloat(styles["marginRight"]);
                return Math.ceil(el.offsetWidth + margin) || 0;
            } catch (e) {
                return 0;
            }
        },
        goToRouteWithParams(route,param){
            router.push({name: route , params: { p: param }});
        },
        goToRoute(route){
            router.push(route);
        },
        goBack() {
            this.$router.back();
        },
        async resizeImage(url, options = { maxWidth: 1024, maxHeight: 768, contentType: "image/jpeg", quality: 0.8 }) {
            const calculateSize = (img) => {
              let w = img.width,
                h = img.height;
              if (w > h) {
                if (w > options.maxWidth) {
                  h = Math.round((h * options.maxWidth) / w);
                  w = options.maxWidth;
                }
              } else {
                if (h > options.maxHeight) {
                  w = Math.round((w * options.maxHeight) / h);
                  h = options.maxHeight;
                }
              }
              return [w, h];
            };
          
            return new Promise((resolve) => {
              const img = new Image();
              img.src = url;
              img.onerror = function () {
                URL.revokeObjectURL(this.src);
              };
              img.onload = function () {
                URL.revokeObjectURL(this.src);
                const [newWidth, newHeight] = calculateSize(
                  img,
                  options.maxWidth,
                  options.maxHeight
                );
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
          
                const resultUrl = canvas.toDataURL(options.contentType, options.quality),
                  result = {
                    url: resultUrl,
                    contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
                    b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
                  };
          
                canvas.toBlob(
                  (blob) => {
                    result.size = blob.size;
                    resolve(result);
                  },
                  options.contentType,
                  options.quality
                );
              };
            });
        }
    }
}

export default utils