<template>
    <div class="content-page">
      <!-- sub-menu -->
      <div class="flex flex-column align-content-center justify-content-start flex-wrap sub-menu-fixed">
        <div class="flex align-self-center align-items-center justify-content-center sub-menu">
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-left" 
          :class="{'sub-page-active':checkActive('last')}" @click="setActiveSubPage('last')">
            {{ $t("league.sub_menu.last_matches") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item" 
          :class="{'sub-page-active':checkActive('standings')}" @click="setActiveSubPage('standings')">
            {{ $t("league.sub_menu.standings") }}
          </div>
          <div class="flex align-items-center justify-content-center text sub-menu-item sub-menu-item-rigth" 
          :class="{'sub-page-active':checkActive('schedule')}" @click="setActiveSubPage('schedule')">
            {{ $t("league.sub_menu.schedule") }}
          </div>
        </div>
      </div>
      
      <div class="flex flex-column scroll-div"> 
        <div id="last-div" class="flex flex-column " v-if="this.subPageActive == 'last'">
          <div v-for="(t, i) of lastMatchesList" :key="i" class="flex align-self-center league-card">
            <div class="flex align-items-center justify-content-center logo-left" >
              <img :src="t.homeTeam.flag" alt=""/>
              <div :class="{'team-lose':t.homeScore < t.awayScore}"></div>
            </div>
            <div class="flex align-items-center justify-content-center logo-right">
              <img :src="t.awayTeam.flag" alt=""/>
              <div :class="{'team-lose':t.homeScore > t.awayScore}"></div>
            </div>
            <div class="flex flex-column justify-content-center w-full">
              <div class="text-game"> {{ t.homeTeam.name_en }} <span class="text-yellow">VS</span> {{ t.awayTeam.name_en }} </div>
              <div class="flex flex-row justify-content-center">
                <div v-if="t.winner == null" class="text-date-schedule" >  {{ $t("league.match_continues") }} </div>
                <div v-else class="text-date-schedule" > {{ $t("league.match_over") }}
                  <span v-if="t.winner == 'D'"> {{ $t("league.draw_over") }} </span> 
                  <span v-if="t.winner == 'H'"> {{ t.homeTeam.name_en }} {{ $t("league.draw_over") }} </span>
                  <span v-if="t.winner == 'A'"> {{ t.awayTeam.name_en }} {{ $t("league.draw_over") }} </span>
                </div>
              </div>
            </div>
            <div class="flex align-items-center justify-content-center league-card-scoreboard">
              {{ t.homeScore }}-{{ t.awayScore }}
            </div>
          </div>
        </div>
        
        <div id="standings-div" class="flex flex-column " v-if="subPageActive == 'standings'"> 
          <div class="flex flex-column align-self-center groups" v-for="(group, i) in groups" :key="i"> 
            <div class="align-self-start w-full pd-10">
              <span class="standings-placing">{{ group }}</span>
            </div>
            <div v-for="(s,i) in standingsList[group]" :key="i" class="flex flex-column align-self-center align-items-center justify-content-center wd-90">
              <div class="flex align-self-center standings" 
              :class="{
                'standings-odd': !(i%2==0),
                'sponsored': teamInfo && s.team.id == teamInfo.teamId
              }">
                <div class="flex align-items-center justify-content-center standings-placing">
                  {{ s.ranking }}
                </div>
                <div class="flex flex-column justify-content-center standings-team">
                  {{ s.team.name_en }}
                </div>
                <div class="flex align-items-center justify-content-center standings-points">
                  {{ s.points }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="schedule-div" class="flex flex-column " v-if="this.subPageActive == 'schedule'">
          <div v-for="(sc,i) in scheduleList" :key="i" class="flex align-self-center league-card">
            <div class="flex align-items-center justify-content-center width-20">
              <img :src="sc.homeTeam.flag" alt=""/>
            </div>
            <div class="flex flex-column justify-content-center width-60">
              <div class="text-game"> {{ sc.homeTeam.name_en }} <span class="text-yellow">VS</span> {{ sc.awayTeam.name_en }} </div>
              <div class="flex flex-row justify-content-center">
                <div class="text-date-schedule" > {{ sc.matchStart | date }}  </div>
                <div class="play-schedule">{{ $t("league.play") }}</div>
              </div>
            </div>
            <div class="flex align-items-center justify-content-center width-20">
              <img :src="sc.awayTeam.flag" alt=""/>
            </div>
          </div>
        </div>

        <div id="scroll-sentinel"></div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import service from "../service";
import moment from 'moment';

export default {
  name: "LeagueComponent",
  props: ["leagueInfo", "teamInfo"],
  data() {
    return {
      season: '',
      groups: [],
      lastMatchesList:'',
      standingsList: [],
      scheduleList: [],
      subPageAnterior: '',
      subPageActive: 'standings',
      pageSize: 10,
      pageLast: 1,
      pageSchedule: 1,
    }
  },
  methods: {
    
    setActiveSubPage(page) {
      this.subPageActive = page;
      if(this.subPageActive == 'last'){
        this.lastMatches()
      }
      if(this.subPageActive == 'standings'){
        this.leagueStandings()
      }
      if(this.subPageActive == 'schedule'){
        this.schedule()
      }
    },

    checkActive(page) {
      return this.subPageActive === page ? true : false;
    },

    async leagueStandings() {
      let response = this.leagueInfo ? 
        await service.leagues.leagueStandings(this.leagueInfo.leagueId, this.leagueInfo.year) :  
        await service.leagues.leagueStandings(this.teamInfo.leagueId, this.teamInfo.year)
      if (response && response.status == 200){
        if(response.data){
          this.season = response.data.season;
          store.layout.headerTitle = response.data.season.league.name_en;
          this.standingsList = response.data.standings;
          this.groups = Object.keys(response.data.standings);
        }
      }
    },

    async lastMatches() {
      if(this.season){
        let response = this.leagueInfo ? 
          await await service.leagues.lastMatches(this.season.id, this.pageLast, this.pageSize) : 
          await service.leagues.lastMatchesByTeam(this.teamInfo.teamId, this.pageSchedule, this.pageSize)
        if (response && response.status == 200){
          if(this.lastMatchesList.length > 0){
            response.data.result.forEach(e => {
              this.lastMatchesList.push(e)
            })
          }
          else{
            this.lastMatchesList = response.data.result
          }
        }
      }
    },

    async schedule() {
      if(this.season){
        let response = this.leagueInfo ? 
          await service.leagues.schedule(this.season.id, this.pageSchedule, this.pageSize) : 
          await service.leagues.scheduledMatchesByTeam(this.teamInfo.teamId, this.pageSchedule, this.pageSize)
        if (response && response.status == 200){
          if(this.scheduleList.length > 0){
            response.data.result.forEach(e => {
              this.scheduleList.push(e)
            })
          }
          else{
            this.scheduleList = response.data.result
          }
        }
      }
    },

    setSentinel(){
      const observer = new IntersectionObserver( async (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            if(this.subPageActive == 'last'){
              this.pageLast += 1;
              await this.lastMatches()
            }
            if(this.subPageActive == 'schedule'){
              this.pageSchedule += 1;
              await this.schedule()
            }
          }
        }
      })
      const scrollSentinel = document.querySelector("#scroll-sentinel");
      observer.observe(scrollSentinel);
    }
  },

  computed: {
    language() {
      return i18n.locale;
    },
  },

  async mounted() {
    if(this.leagueInfo || this.teamInfo){
      await this.leagueStandings()
      this.setSentinel()  
      store.layout.showHeader = true
      store.layout.showFooter = true
      store.layout.showAccountFooter = false
    }
    else {
      this.$router.push('home')
    }
  },
};
</script>

<style scoped>
.wd-90{
  width: 90%
}

.pd-10{
  padding: 10px;
}

#scroll-sentinel {
  position: relative;
  bottom: 0;
}
.scroll-div{
  overflow: auto;
}
.scroll-div::-webkit-scrollbar {
  display: none; /* Chrome */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
  min-height: var(--appHeight);
}

.sub-menu-fixed{
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 3
}

.sponsored {
  background-color: #0957b7!important;
  border-radius: 10px;
  margin: 2px 0;
}

.sponsored .standings-placing, .sponsored .standings-team, .sponsored .standings-points {
  font-weight: bold;
}
.sub-menu{
  height: 30px;
  width: 95%;
  margin-bottom: 20px;
}

.sub-menu-item{
  width: 100%;
  height: 100%;
  background-color: #282E3D;
}

.sub-menu-item-left{
  border-radius: 7px 0px;
}

.sub-menu-item-rigth{
  border-radius: 0px 7px;
}

.sub-page-active{
  background-color: #0A57B7 !important;
}
.text{
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #B0B1CA;
}

.text-yellow{
  color: #FFCD00
}

.groups{
  border: 1px solid #B0B1CA;
  border-radius: 16px;
  width: 95%; 
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.standings{
  width: 95%;
  height: 35px;
}
.standings-odd{
  background-color: #282E3D;
  border-radius: 10px;
}

.league-card{
  height: 65px;
  width: 95%;
  background-color: #282E3D;
  border-radius: 16px;
  margin-bottom: 10px;
}
.league-card img{
  width: 30px;
  height: 30px;
}

.logo-left{
  width: 50px;
  margin-left:10px
}
.logo-right{
  width: 50px;
}
.team-lose{
  position: absolute;
  background: #1D2123;
  width: 28px;
  height: 27px;
  opacity: 0.4;
  border-radius: 15px;
}
.league-card-scoreboard{
  font-weight: 400;
  font-size: 17px;
  color: #FFFFFF;
  margin-right:10px;
  width: 80px;
}

.text-game{
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
}

.text-date-schedule{
  font-size: 11px;
  text-align: center;
  color: #B0B1CA;
}
.width-12{
  width: 12%;
}

.standings-placing{
  width: 20%;
  font-weight: 400;
  font-size: 12px;
  color: #B0B1CA;
}
.standings-team{
  width: 60%;
  font-weight: 400;
  font-size: 12px;
  color: #B0B1CA;
}
.standings-points{
  width: 20%;
  font-weight: 400;
  font-size: 12px;
  color: #FFCD00;
}
.width-20{
  width: 20%;
}
.width-60{
  width: 60%;
}
.play-schedule{
  margin-left: 5px; 
  width: 25%; 
  background: #0057B7;
  border-radius: 11px;
  font-size: 11px;
  color: #FFFFFF;
}
</style>
