<template>
    <div class="content-page">
      <div class="flex flex-column content-match">
        <div class="flex align-self-center match-card">
            <div class="flex align-items-center justify-content-center match-card-icon" >
              <div class="flex flex-column align-items-center justify-content-center"> 
                <img :src="this.p.homeTeamFlag"  alt=""/>
                <span class="team-name"> {{ this.homeTeam.name }} </span>
                <span v-for="(gh, ghi) in this.homeTeam.goals" :key="ghi" class="goal-text"> 
                  {{ gh.player.name }} {{gh.time.elapsed}}'
                </span>
              </div>
            </div>
            <div class="flex flex-column justify-content-center align-items-start match-card-name">
              <div class="flex flex-column justify-content-center score-content">
                <div class="score-text">
                  {{this.match.homeScore}}-{{this.match.awayScore}}
                </div>
              </div>
            </div>
            <div class="flex align-items-center justify-content-center match-card-icon" >
              <div class="flex flex-column align-items-center justify-content-center"> 
                <img :src="this.p.awayTeamFlag"  alt=""/>
                <span class="team-name"> {{ this.awayTeam.name }} </span>
                <span v-for="(ga, gai)  in this.awayTeam.goals" :key="gai" class="goal-text"> 
                  {{ ga.player.name}} {{ ga.time.elapsed }}' 
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-column align-self-center statistics-content">
            <div class="flex justify-content-around align-items-center align-content-center pd-10"> 
              <img v-for="icon in iconsList" :key="icon" :src="require('@/assets/icons/'+icon)" alt="" class="statistics-team-logo"/>
            </div>
            <div class="flex flex-column statistics-content-around">
              <div class="flex flex-column justify-content-around align-items-center align-content-center pd-5"> 
                <div class="grid flex justify-content-around align-items-center align-content-center w-full statistics-text"> 
                  <div class="col"><img class="statistics-team-logo" :src="this.p.homeTeamFlag" alt=""/></div>
                  <div class="col"> {{ this.homeTeam.shotsOnGoal | formatStatistics }} </div>
                  <div class="col"> {{ this.homeTeam.corners | formatStatistics }} </div>
                  <div class="col"> {{ this.homeTeam.offsides | formatStatistics }} </div>
                  <div class="col"> {{ this.homeTeam.fouls | formatStatistics }} </div>
                  <div class="col"> {{ this.homeTeam.yellowCards | formatStatistics }} </div>
                  <div class="col"> {{ this.homeTeam.redCards | formatStatistics }} </div>
                </div> 
                <div class="grid flex justify-content-around align-items-center align-content-center w-full statistics-text"> 
                  <div class="col"><img class="statistics-team-logo" :src="this.p.awayTeamFlag" alt=""/></div>
                  <div class="col"> {{ this.awayTeam.shotsOnGoal | formatStatistics }} </div>
                  <div class="col"> {{ this.awayTeam.corners | formatStatistics }} </div>
                  <div class="col"> {{ this.awayTeam.offsides | formatStatistics }} </div>
                  <div class="col"> {{ this.awayTeam.fouls | formatStatistics }} </div>
                  <div class="col"> {{ this.awayTeam.yellowCards | formatStatistics }} </div>
                  <div class="col"> {{ this.awayTeam.redCards | formatStatistics }} </div>
                </div> 
              </div>
            </div>
          </div>
          <div v-if="events" class="flex flex-column align-self-center events-content">
            <div v-for="(e, index) in events" :key="index" class="flex justify-content-center align-items-center align-content-center pd-10" :class="{'statistics-around': index%2==0}"> 
              <div class="event-time" >{{ e.time.elapsed }}'</div>
              <div class="event-text" 
                  :class="{'yellow':e.detail.toLowerCase().includes('yellow'),'red':e.detail.toLowerCase().includes('red'),'goal':e.detail.toLowerCase().includes('goal')}">
                <img v-if="chooseIcon(e.detail) != undefined" :src="require('@/assets/icons/'+chooseIcon(e.detail))" alt="" /> 
                {{ e.detail }} <span v-if="e.detail.toLowerCase().includes('card')
                                          || e.detail.toLowerCase().includes('substitution')
                                          || e.detail.toLowerCase().includes('goal')"> "{{ e.player.name }} </span>
              </div>
              <div class="wd-10">
                <img :src="e.team.logo" alt="" class="statistics-team-logo"/>  
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store } from "../store";
import  utils from "../utils";
import service from "../service";

export default {
  name: "MatchListComponent",
  props: {
    p: Object
  },
  components: {
  },
  data() {
    return {
      iconsList:['t-shirt.png','shot.png','corner.png','008-referee.png','whistle.png','yellow-cards.png','red-cards.png'],
      homeTeam:'',
      awayTeam:'',
      events:'',
      match:''
    }
  },
  methods: {
    chooseIcon(value){
      if(value.toLowerCase().includes('faul')) return '008-referee.png';
      else if(value.toLowerCase().includes('corner')) return 'corner.png';
      else if(value.toLowerCase().includes('yellow')) return 'yellow-cards.png';
      else if(value.toLowerCase().includes('red')) return 'red-cards.png';
      else if(value.toLowerCase().includes('goal')) return 'goals.png';
    },
    async getMatchAsync(matchId) {
      let response = await service.match.getMatch(matchId)
      if (response && response.status == 200){
        this.match = response.data;
        this.events = response.data.events;
        if(response.data.statistics && response.data.statistics.length > 0){
          this.statistics = response.data.statistics;
          this.homeTeam = response.data.statistics[0].team;
          this.homeTeam.goals = response.data.events.filter(e => e.detail.toLowerCase().includes('goal')).filter(g => g.team.id == this.homeTeam.id)
          this.homeTeam.shotsOnGoal = response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('shots on goal'))[0].value;
          this.homeTeam.corners = response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('corner kicks'))[0].value;
          this.homeTeam.offsides = response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('offsides'))[0].value;
          this.homeTeam.fouls =  response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('fouls'))[0].value;
          this.homeTeam.yellowCards =  response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('yellow cards'))[0].value;
          this.homeTeam.redCards =  response.data.statistics[0].statistics.filter(l => l.type.toLowerCase().includes('red cards'))[0].value;

          this.awayTeam = response.data.statistics[1].team;
          this.awayTeam.goals = response.data.events.filter(e => e.detail.toLowerCase().includes('goal')).filter(g => g.team.id == this.awayTeam.id);
          this.awayTeam.shotsOnGoal = response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('shots on goal'))[0].value;
          this.awayTeam.corners = response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('corner kicks'))[0].value;
          this.awayTeam.offsides = response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('offsides'))[0].value;
          this.awayTeam.fouls = response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('fouls'))[0].value;
          this.awayTeam.yellowCards =  response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('yellow cards'))[0].value;
          this.awayTeam.redCards =  response.data.statistics[1].statistics.filter(l => l.type.toLowerCase().includes('red cards'))[0].value;
        }
      }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    }
  },
  async mounted() {
    await this.getMatchAsync(this.p.matchId);

    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t('match_list.title_2')
  },
};
</script>

<style scoped>
.wd-10{
  width: 10%;
}
.pd-5{
  padding: 5px;
}
.pd-10{
  padding: 10px;
}
.yellow{
  color: #FFCD00 !important;
}

.red{
  color: #e9131e !important;
}
.goal{
  font-weight: 700 !important;;
}
.statistics-around{
  background: #191B22;
  border-radius: 10px;
}
.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
}

.statistics-content{
  background: #1e232e;
  opacity: 1.8;
  border-radius: 10px;
  height: 100px;
  width: 95%;
  margin-bottom: 10px;
}

.statistics-content-around{
  background: #282E3D;
  border-radius: 10px;
  height: 70%;
}

.statistics-text{
  font-size: 12px;
  color: #FFFFFF;
}

.statistics-team-logo{
  width: 20px;
  height: 20px;
}

.events-content{
  background: #282E3D;
  border-radius: 10px;
  width: 95%;
  padding:10px;
  margin-top:10px
}

.content-match{
  min-height: var(--appHeight);
}

.team-name{
  font-size: 12px;
  color: #FFFFFF;
} 

.event-time{
  font-size: 15px;
  color: #FFFFFF;
  width: 10%;
}

.event-text{
  font-size: 12px;
  color: #B0B1CA;
  width: 100%;
}

.score-content{
  width: 100%; 
  height: 90%; 
  background: #191B22;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.603922);
  backdrop-filter: blur(15px);
  border-radius: 32px;
}

.score-text{
  font-size: 33px;
  text-align: center;
  color: #FFFFFF
}

.goal-text{
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  color: #FFFFFF;
}

::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #B0B1CA;
}

.match-card{
  height: 100%px;
  width: 95%;
  background-color: #282E3D;
  border-radius: 16px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.match-card-icon{
  width: 40% !important;
}

.match-card .around-img{
  width: 45px; 
  height: 45px;
  border: 1px solid #FFFFFF;
  border-radius: 16px;
}

.match-card img{
  width: 45px; 
  height: 45px;
}

.match-card-name{
  width: 20%;
}



</style>
