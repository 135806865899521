import MockAdapter from 'axios-mock-adapter'
import dailyQuiz from './daily-quiz.json'
import topMatches from './top-matches.json'
import matchesDay from './matches-day.json'

const mocks = (axios) => {
  const mock = new MockAdapter(axios, 
    { 
      onNoMatch: "passthrough"
    }
  )
  
  mock.onGet(new RegExp('/login')).reply(200, "OK")
  
  mock.onGet(new RegExp('/user-answer/nextQuestion')).reply(200, dailyQuiz.nextQuestion)
  mock.onPatch(new RegExp('/user-answer/answerQuestion')).reply(200, dailyQuiz.answerQuestion)
  mock.onPatch(new RegExp('/bet')).reply(200, "OK")
  mock.onGet(new RegExp('/match/top-matches')).reply(200, topMatches)
  mock.onGet(new RegExp('/match-day/matchesDay')).reply(200, matchesDay)

}

export default mocks;