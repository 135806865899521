<template>
  <div class="content-page">
    <div v-if="match" class="flex flex-column h-full justify-content-between">
      <div v-if="isHomePlay" class="cards-selection relative pb-4">
        <div class="absolute home-match pt-3 pb-3">
            <div class="match-teams">
              <div>
                <img :src="match.homeTeam.flag"/>
                <div>{{ match.homeTeam["name_" + language] }}</div>
              </div>

              <div>
                <img :src="match.awayTeam.flag"/>
                <div>{{ match.awayTeam["name_" + language] }}</div>
              </div>

            </div>
            <div class="join-contest">{{ $t("joined_match.join_contest") }}</div>
            <div class="mb-2">{{ $t("joined_match.super_matches_contest") }}<span class="ml-1 mr-1">{{ $t("joined_match.amount") }}</span>{{ $t("joined_match.iqd_every_week") }}</div>
        </div>
        <span class="select-card flex justify-content-left">{{ $t("joined_match.select_card") }}</span>
        <PlayCard
          type="captain"
          :amount="cards.captain"
          :label="$t('cards.captain')"
          @selectCard="onSelectCard"
          :points="cardsPrices.points.captain.correct + ' ' + $t('cards.pts')">
        </PlayCard>
        <PlayCard
          type="gold"
          :amount="cards.gold"
          :label="$t('cards.gold')"
          @selectCard="onSelectCard"
          :points="cardsPrices.points.gold.correct + ' ' + $t('cards.pts')">
        </PlayCard>
        <PlayCard
          type="daily"
          :amount="cards.daily"
          @selectCard="onSelectCard"
          :label="$t('cards.daily')"
          :points="cardsPrices.points.daily.correct + ' ' + $t('cards.pts')">
        </PlayCard>         
      </div>

      <div v-else-if="isJoinedMatch" class="play-match">
        <div class="prediction-title">
          <div>{{ $t("joined_match.question") + ' ' + current + 1 }}</div>
          <div>
            <img :src="require('@/assets/icons/'+ questions[current].logo +'-joined-match.png')"/>
            <div>{{ $t("joined_match."+questions[current].title) }}</div>
          </div>
        </div>
        <div class="predictions">
          <div :class="[(selectedChoice == 'H' ? 'selected-prediction' : ''), 'prediction', 'pair']" @click="setChoice('H')">
            <div>
              <img :src="match.homeTeam.flag"/>
              <div> {{ match.homeTeam["name_" + language] }}</div>
              <div :class="[(selectedChoice == 'H' ? 'check-prediction' : 'uncheck-prediction'), 'border-circle', 'w-2rem', 'h-2rem']"></div>
            </div>
          </div>
          
          <div :class="[(selectedChoice == 'A' ? 'selected-prediction' : ''), 'prediction', 'pair']" @click="setChoice('A')">
            <div>
              <img :src="match.awayTeam.flag"/>
              <div> {{ match.awayTeam["name_" + language] }}</div>
              <div :class="[(selectedChoice == 'A' ? 'check-prediction' : 'uncheck-prediction'), 'border-circle', 'w-2rem', 'h-2rem']">
              </div>
            </div>
          </div>

          <div v-if="current == 0" :class="[(selectedChoice == 'D' ? 'selected-prediction' : ''), 'prediction', 'single']" @click="setChoice('D')">
            <img :src="require('@/assets/icons/draw.png')"/>
            <div>{{ $t("joined_match.ends_draw") }}</div>
          </div>

          <div v-if="current > 0" :class="[(selectedChoice == 'N' ? 'selected-prediction' : ''), 'prediction', 'simple-pair']" @click="setChoice('N')">
            <div>{{ $t("joined_match.none") }}</div>
          </div>

          <div v-if="current > 0" :class="[(selectedChoice == 'B' ? 'selected-prediction' : ''), 'prediction', 'simple-pair']" @click="setChoice('B')">
            <div>{{ $t("joined_match.both") }}</div>
          </div>

          <div v-if="questionPoints" class="points-earned">
            <img width="36" height="30" :src="require('@/assets/icons/daily-quiz-icon.png')" alt=""/>
            <div class="points-description">
              <div>{{ $t("joined_match.correct_answer") + ": " + questionPoints.correct + " " + $t("general.points")}}</div>
              <div>{{ $t("joined_match.wrong_answer") + ": " + questionPoints.wrong + " " + $t("general.points") }}</div>
            </div>
          </div>

        </div>    

      </div>

      <div v-else-if="isCongratulation" class="congratulation">
        <div>
          <img :src="require('@/assets/icons/congratulation.png')"/>
        </div>
        <div>
          <div>{{ $t("joined_match.congratulations") }}</div>
          <div>{{ $t("joined_match.completed_questions") }}</div>
          <div>{{ interpolate($t("joined_match.points_correct_answers"),[cardsPrices.points[selectedCard].correct]) }}</div>
        </div>
      </div>

      <div v-if="!isHomePlay" :class="isJoinedMatch ? 'submit-next-question' : 'go-home'" @click="submitNextHome()">
        <span v-if="isJoinedMatch">
          {{ current < 3 ? $t("joined_match.next_question") : $t("joined_match.submit") }}
        </span>
        <span v-else-if="isCongratulation" @click="goToHome()">
          {{ $t("joined_match.go_home") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import PlayCard from "@/components/PlayCards.vue";
import service from "../service";
import utils from "@/utils";

export default {
  name: "JoinedMatchComponent",
  mixins: [utils],
  props: ["match"],
  components: {
    PlayCard
  },
  data() {
    return {
      isHomePlay: true,
      isCongratulation: false,
      isJoinedMatch: false,
      current: 0,
      questionPoints: false,
      selectedChoice: '',
      selectedCard: '',
      answers: [],
      questions: [
        {
          title: 'choose_winner',
          logo: 'winner',
        },
        {
          title: 'red_cards',
          logo: 'red-cards',
        },
        {
          title: 'yellow_cards',
          logo: 'yellow-cards',
        },
        {
          title: 'goals',
          logo: 'goals',
        }
      ]
    }
  },
  methods: {
    async submitNextHome(){
      if(!this.isCongratulation && this.selectedChoice == ''){
        notify(this.$t("error.note"), this.$t("error.select_answer"), "warn");
        return
      }
      else if(this.isJoinedMatch && this.current < 3){
        this.answers.push({'questionType': this.questions[this.current].logo, 'answer': this.selectedChoice })
        this.current++
      }
      else if(this.isJoinedMatch && this.current == 3){
        this.answers.push({'questionType': this.questions[this.current].logo, 'answer': this.selectedChoice })
        await service.joinedMatch.sendBetUser(this.match.id, `${this.selectedCard}-card`, this.answers)
        this.isCongratulation = true
        this.isJoinedMatch = false
        this.answers = []
      }
      else if(this.isCongratulation){
        this.$router.push('/home')
      }
      this.questionPoints = false
      this.selectedChoice = ''
    },

    async getCardsActives(){
      if(store.cardsPrices.points.daily !== 0)
        return
      let response = await service.cards.cardsActives()
      if (response && response.status == 200){
        store.cardsPrices.points.daily = response.data.filter(c => c.type == 'daily-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.gold = response.data.filter(c => c.type == 'gold-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
        store.cardsPrices.points.captain = response.data.filter(c => c.type == 'captain-card').map(c => { return { correct: c.pointsCorrect, wrong:c.pointsWrong } })[0];
      }
    },

    setChoice(choice){
      this.selectedChoice = choice
      this.questionPoints = this.getQuestionPoints()
    },

    getQuestionPoints(){
      return {
        correct: Math.floor(store.cardsPrices.points[this.selectedCard].correct / 5 * (this.current == 0 ? 2 : 1)),
        wrong: Math.floor(store.cardsPrices.points[this.selectedCard].wrong / 5 * (this.current == 0 ? 2 : 1))
      }
    },

    onSelectCard(type){
      this.selectedCard = type
      if(this.cards[type] > 0){
        this.isHomePlay = false
        this.isJoinedMatch = true
      }
      else {
        notify(this.$t('error.note'), this.$t(`cards.${type}_unavailable`), 'info')
        if(this.selectedCard !== "daily"){
          store.cardsPrices.currentType = type
          store.layout.showModalBuyCards = true
        }
      }
    },
    goToHome(){
      this.$router.push('/home')
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },

    cards(){
      return { daily: store.user.cards.daily, captain: store.user.cards.captain, gold: store.user.cards.gold } 
    },

    cardsPrices(){
      return store.cardsPrices
    }
  },

  async mounted() {
    if(typeof(this.match) != 'undefined'){
      await this.getCardsActives();

      store.layout.showHeader = true;
      store.layout.showFooter = false;
      store.layout.showAccountFooter = false;
      store.layout.headerTitle = this.$t("joined_match.title")
    }
    else {
      this.goToHome()
    }
  },
};
</script>

<style scoped>

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;
  padding: 0 10px;
  color: white;
  height: 100%;
}

.prediction-title {
  height: auto;
  width: calc(100% - 20px);
  margin: 0px 10px;
  color  :white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B0B1C8FF' stroke-width='1' stroke-dasharray='3' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 15px;  
  box-shadow: 0px 10px 15px 0px #000;
  font-family: 'Poppins';
  background-color: #191B22;
  margin-top: 20px;
}

.prediction-title > div:nth-child(1){
  font-size: 14px;
  color: #FFCD00;
  padding-top: 12px;
  font-family: 'Poppins-SemiBold';
}

.prediction-title > div:nth-child(2){
  font-size: 20px;
  color: #ffffff;
  font-family: 'Poppins-SemiBold';
  display: flex;
  justify-content: center;
  padding: 25px 10px 20px 10px;
  align-items: center;
}

.prediction-title > div:nth-child(2) img {
  margin-right: 15px;
  height: 50px;
}

.predictions {
  display: flex;
  padding: 0 10px;
  flex-wrap: wrap;
  gap: 13px;
  padding-top: 40px;
}

.prediction {
  background-color: #282e3d;
  border-radius: 15px;
  margin-top: 0px;
  font-weight: 700;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.pair, .simple-pair {
  flex-basis: calc(50% - 7px);
}

.pair > div {
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-evenly;
  align-items: center;
}

.simple-pair {
  display: flex;
  flex-direction: column;
  height: 95px;
  justify-content: space-evenly;
  align-items: center;
}

.pair img {
  width: 44px;
}

.pair > div :nth-child(2) {
  font-size: 15px;
}

.single {
  height: 95px;
  align-items: center;
}

.single img {
  width: 28px;
  margin-right: 20px;
}

.selected-prediction {
  background-color: #0057B7;  
}
.points-earned {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.points-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin-top:10px;
  color: #FFCD00;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins-SemiBold';
}

.points-description img {
  margin-right: 10px;
}

.prediction span {
  padding-left: 40px;
  align-items: center;
  display: flex;
}

.cards-match {
  margin-top: 20px;
}

.submit-next-question {
  border-radius: 12px;
  background-color: #0057B7;
  color: #ffffff;
  font-weight: 700;
  padding: 12px 0;
  width: calc(100% - 20px);
  margin: 0px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.go-home {
  border-radius: 12px;
  background-color: #181a21;
  color: #ffffff;
  font-weight: 700;
  padding: 12px 0;
  border: #ffffff solid 1px;
  width: calc(100% - 20px);
  margin: 0px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.home-match {
  height: auto;
  width: calc(100% - 20px);
  margin: 0px 10px;
  top: -50%;
  color  :white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23B0B1C8FF' stroke-width='1' stroke-dasharray='3' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 15px;  
  box-shadow: 0px 10px 15px 0px #000;
  font-family: 'Poppins-SemiBold';
  background-color: #0057B7;
}

.home-match img {
  margin-top: 10px;
}

.match-teams {
  text-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
}

.match-teams > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-teams > div img {
  width: 70px;
}

.match-teams > div > div {
  margin-top: 15px;
}

.home-match > div:nth-child(2) {
   font-size: 25px;
   margin-top: 35px;
   text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
}

.home-match div:nth-child(3) {
  font-size: 15px;
  margin-top: 0px;
  padding: 0 18px;
  font-family: 'Poppins-SemiBold';
}

.home-match div:nth-child(3) > span {
  color: #FFCD00;
}

.cards-selection {
    background-color: #282E3D;
    border-radius: 20px;
    padding-top: 35%;
    margin-top: 55%;
}

.select-card {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 0px;
}

.uncheck-prediction {
    background-color: #ADAEC6;
}

.check-prediction {
    background-color: #FFCD00;
}

.congratulation {
  box-shadow: 0px 10px 15px 0px #000;
  background-color: #0057B7;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding-top: 45px;
  padding-bottom: 30px;
  align-items: center;
  margin-top: calc(100vh/12);
}

.congratulation > div:nth-child(2) > div:nth-child(1) {
  font-family: 'Poppins-SemiBold';
  font-size: 21px;
  color: #FFCD00;
  margin-top: 35px;
}

.congratulation > div:nth-child(2) > div:nth-child(2) {
  font-family: 'Poppins-SemiBold';
  font-size: 18px;
  color: #ffffff;
  margin-top: 5px;
}

.congratulation > div:nth-child(3) {
  margin-top: 40px;
  width: 230px;
  font-size: 16px;
}
</style>
