<template>
    <div class="content-page">
      <div class="flex flex-column align-content-start justify-content-start flex-wrap content-about">
        <div class="flex bg-cover bg-no-repeat" :style="'background-image: url('+p.news.image_url+');height: 370px;'">
            <div class="content-card-large flex flex-column justify-content-end mb-30">
              <div class="flex align-self-center justify-content-center align-items-center title"> 
                {{ p.news.title }}
              </div>
            </div>
          </div>
        <div class="flex flex-column align-items-start align-content-start text" >
          <div class="flex justify-content-start mb-10"> 
            {{ p.news.description }}
          </div>
          <div class="flex justify-content-start mb-10"> 
            {{ $t('news_details.source') + p.news.source_name }}
          </div>
          <div class="flex justify-content-start mb-10"> 
          {{ $t('news_details.source_url') }} 
          <a :href="p.news.url"> 
            {{ p.news.source_url }} 
          </a> 
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";
import service from "../service";

export default {
  name: "NewsDetailsComponent",
  props: {
    p: Object
  },
  components: {
  },
  data() {
    return {
      news: ''
    }
  },
  methods: {
    
  },
  computed: {
    language() {
      store.layout.headerTitle = this.$t("news.title")
      return i18n.locale;
    }
  },
  async mounted() {

    store.layout.showHeader = true;
    store.layout.showFooter = true;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("news.title")
  },
};
</script>

<style scoped>

.content-page{
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}
.content-about{
  min-height: var(--appHeight);
}

.title{
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color:#FFCD00;
  width: 90%;
}

.text{
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left
}

.text a{
  margin-left: 10px;
  color:#B0B1CA !important;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-30{
  margin-bottom: 30px;
}

</style>
