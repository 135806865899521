import Vue from 'vue'
import App from './App.vue'
import router from "./router.js"
import i18n from './translation'
import moment from 'moment';
import Toast from 'primevue/toast';
import VueAnalytics from 'vue-analytics';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/saga-blue/theme.css'; // theme
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css'; // core css
import { resetLayout, store } from './store';

Vue.config.productionTip = false
Vue.use(VueAnalytics, { id: 'G-HLLBQC9R2X', router });
Vue.use(ToastService);
Vue.component('Toast', Toast);


store.user.token = localStorage.getItem("userToken");

 router.beforeEach((to, from, next) => {
//   if((typeof store.user.token != "undefined" &&  store.user.token != "" && store.user.token && to.name == 'login')){
//     next('/home')
//   }
//   // else if(!to.meta.public && to.name != 'login' && (typeof store.user.token == "undefined" || store.user.token == "" || !store.user.token) ){
//   //   next('/')
//   // }
//   else {
    resetLayout()
    next();
//   }
})

Vue.filter("formatStatistics", function (value) {
  if(!value)
    return 0
  else 
    return value
});

Vue.filter("formatNumber", function (value) {
  return parseInt(value).toLocaleString('en-US'); 
});

Vue.filter('dateMonthDay', function (value) {
  if (!value) return ''
  return moment(String(value.toString())).format('DD/MM')
});

Vue.filter('dateComplete', function (value) {
  if (!value) return ''
  else return moment(String(value.toString())).format('DD/MM/YYYY HH:mm')
});

Vue.filter('date', function (value) {
  if (!value) return ''
  value = value.toString()

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const inputDate = new Date(value);
  let isTomorrow =  inputDate.toDateString() === tomorrow.toDateString();
  if(isTomorrow)
    return 'TOMORROW ' + moment(String(value)).format('HH:mm')
  else
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')