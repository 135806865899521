import store from '../store'
import service from '../service'

const cards = {
  cardsActives: async () => {
    try {
      const response = await service.$http.get(`${store.API_URL}card-type/actives`);
      return response;
    } catch (e) {
      return e.response;
    }
  },  
}

export default cards