import store from '../store'
import service from '../service'

const purchase = {
    getPurchaseType: async (type) => {
        try {
            const response = await service.$http.get(`${store.API_URL}purchase-type/byCardType/${type}`);
            return response;
        } catch (e) {
            return e.response;
        } 
   },
   requestPinCode: async (lang, purchaseTypeId) => {
        try {
            const response = await service.$http.get(`${store.API_URL}user/purchaseCoins/requestPinCode/${lang}/${purchaseTypeId}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
    verifyPinCode: async (pincode, cardTypeId) => {
        try {
            const response = await service.$http.get(`${store.API_URL}user/purchaseCoins/verifyPinCode/${pincode}/${cardTypeId}`);
            return response;
        } catch (e) {
            return e.response;
        } 
    },
}

export default purchase