<template>
    <div class="content-page">
      <div class="flex flex-column align-content-around justify-content-around content-unsubscribe">
        <div class="flex align-items-start title">
          {{ $t("unsubscribe.title") }}
        </div>
        <div class="flex align-items-start sub-title-1">
          {{ $t("unsubscribe.sub_title_1") }}
        </div>
        <div class="flex align-items-start sub-title-2">
          {{ $t("unsubscribe.sub_title_2") }}
        </div>
        <div class="flex align-self-center align-items-center justify-content-center reason-button" @click="select(0)">
          <span :class="{'reason-selected': this.selectedArray[0]}">{{ $t("unsubscribe.reason_1") }}</span>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center reason-button" @click="select(1)">
          <span :class="{'reason-selected': this.selectedArray[1]}">{{ $t("unsubscribe.reason_2") }}</span>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center reason-button" @click="select(2)">
          <span :class="{'reason-selected': this.selectedArray[2]}">{{ $t("unsubscribe.reason_3") }}</span>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center reason-button" @click="select(3)">
          <span :class="{'reason-selected': this.selectedArray[3]}">{{ $t("unsubscribe.reason_4") }}</span>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center reason-button" @click="select(4)">
          <span :class="{'reason-selected': this.selectedArray[4]}">{{ $t("unsubscribe.reason_5") }}</span>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center suggestion-area">
          <textarea class="custom-input"  rows="4" cols="50" placeholder="If you have any suggestions, please write." v-model="optionalNote"></textarea>
        </div>
        <div class="flex align-self-center align-items-center justify-content-center unsubscribe-button" @click="unSubUser()">
          <span>{{ $t("unsubscribe.unsubscribe") }}</span>
        </div>
      </div>
    </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import  service from "../service";

export default {
  name: "UnsubscribeComponent",
  components: {
  },
  data() {
    return {
      selectedArray: [false,false,false,false,false],
      indexSelected: -1,
      msisdn: '',
      message: '',
      optionalNote: ''
    }
  },
  methods: {
    select(index){
      this.selectedArray = [false,false,false,false,false];
      this.selectedArray[index]=!this.selectedArray[index];
      this.indexSelected = index;
    },

    async getUserMe() {
      let response = await service.user.getUser();
      if (response && response.status == 200){
        this.msisdn = response.data.phoneNumber;
      }
    },

    logout(){
      store.layout.showLoading = false;
      localStorage.removeItem("user.token");
      store.user.token = "";
      this.$router.push("/");
    },
    
    async unSubUser() {
      if(this.indexSelected != -1){
        let response = await service.user.unsubscribe(this.msisdn,this.$t("unsubscribe.reason_"+Number(this.indexSelected+1)),this.optionalNote);
        if (response && response.status == 200){
          this.logout();
        }
      }else{
        notify(this.$t('error.note'), this.$t('unsubscribe.error_reason'), 'info')
      }
    },

  },
  computed: {
    language() {
      return i18n.locale;
    },
    selected(){
      return this.selectedArray
    }
  },
  async mounted() {
    await this.getUserMe();

    store.layout.showHeader = true;
    store.layout.showFooter = false;
    store.layout.showAccountFooter = false;
    store.layout.headerTitle = this.$t("unsubscribe.title")
  },
};
</script>

<style scoped>

.content-page {
  font-weight: 400;
  font-family: 'Poppins';
  font-style: normal;
  background-color: #191B22;;
}
.content-unsubscribe{
  /* height: calc(var(--appHeight) - 140px);  */
  min-height: var(--appHeight)
}
.about_card{
  background-image: url('../assets/terms_card.png');
  background-repeat: no-repeat;
  width:350px;
  height:140px;
}
.title{
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  padding-left: 40px;
}
.sub-title-1{
  font-weight: 700;
  color: #FFCD00;
  padding-left: 40px;
}
.sub-title-2{
  font-weight: 300;
  color: #FFFFFF;
  padding-left: 40px;
  text-align: left;
}
.reason-button{
  background-color: #282E3D;
  border-radius: 15px;
  width: 330px;
  height: 55px;
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.reason-selected{
  color: #FFCD00 !important;
}
.suggestion-area{
  border: 1px solid #707070;
  border-radius: 15px; 
  width: 330px;
  height: 90px;
}
.unsubscribe-button{
  background-color: #0057B7;
  border-radius: 15px;
  width: 330px;
  height: 55px;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}


::placeholder{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #B0B1CA;
}

.custom-input{
    border-width: 0;
    padding: 0px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #B0B1CA;
    background-color: #191B22;
    width: 85%;
    height: 80%;
} 

</style>
